import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { DocumentService } from 'src/app/services/document/document.service';
import { Router } from '@angular/router';
import { Chip } from 'src/app/models/chip';
import { LocaleDatePipe } from 'src/app/pipes/locale-date/locale-date.pipe';
import { NamespaceService } from 'src/app/services/namespace/namespace.service';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
    @ViewChild('filterChips') filterChips: ElementRef;

    @Output() select: EventEmitter<any> = new EventEmitter<any>();
    @Output() change: EventEmitter<any> = new EventEmitter<any>();

    public documents: any[] = [];
    public filteredResults: any[] = [];
    public searchTerm: string = '';
    public filter: { id: number; text: string; chips: Chip[] }[] = [];

    private unfilteredResults: any[] = [];
    private documentAccess: { group: boolean; flat: boolean; property: boolean; personal: boolean };

    constructor(
        private documentService: DocumentService,
        private namespaceService: NamespaceService,
        private router: Router,
        private localeDatePipe: LocaleDatePipe
    ) {}

    async ngOnInit(): Promise<void> {
        const config = await this.namespaceService.getTenantConfiguration();
        this.documentAccess = {
            group: config.groupDocumentAccess.includes('tenant'),
            flat: config.flatDocumentAccess.includes('tenant'),
            property: config.propertyDocumentAccess.includes('tenant'),
            personal: config.personalDocumentAccess.includes('tenant'),
        };
        this.documents = await this.documentService.combineAllDocuments();
        this.unfilteredResults = [...this.documents];
        this.filter = [
            {
                id: 0,
                text: '',
                chips: [
                    {
                        id: '0',
                        text: 'document.search-group',
                        selected: true,
                        modelType: 'group',
                        icon: 'grid',
                        hide: !this.documentAccess.group,
                    },
                    {
                        id: '1',
                        text: 'document.search-property',
                        selected: true,
                        modelType: 'property',
                        icon: 'home',
                        hide: !this.documentAccess.property,
                    },
                    {
                        id: '2',
                        text: 'document.search-flat',
                        selected: true,
                        modelType: 'flat',
                        icon: 'people',
                        hide: !this.documentAccess.flat,
                    },
                    {
                        id: '3',
                        text: 'document.search-user',
                        selected: true,
                        modelType: 'user',
                        icon: 'person',
                        hide: !this.documentAccess.personal,
                    },
                    { id: '4', text: 'document.search-last-uploaded', selected: false, icon: 'calendar-outline' },
                    { id: '5', text: 'document.search-pdf', selected: false, icon: 'document-outline' },
                    { id: '6', text: 'document.search-image', selected: false, icon: 'image-outline' },
                ],
            },
        ];
        this.filteredResults = this.sortAndFilterResults();
    }

    public getPathToDocument(document): string {
        try {
            return [document?.modelName ?? '', ...(document?.labels ?? [])].join('/');
        } catch (e) {
            return [document?.modelName].join('/') || '';
        }
    }

    async handleInput(event): Promise<void> {
        this.searchTerm = event.target.value;
        const query = event.target.value.toLowerCase();
        this.filteredResults = this.sortAndFilterResults().filter(
            (document) => document?.name?.toLowerCase().indexOf(query) > -1
        );
        this.change.emit(this.searchTerm);
    }

    async selectDocument(document): Promise<void> {
        this.select.emit(document);
        this.searchTerm = '';

        const pathToDocument = `main/documents?documentName=${encodeURIComponent(document.name)}${
            document?.labels?.join('/') ? '&labels=' + encodeURIComponent(document?.labels?.join('/')) : ''
        }${document?.model ? '&model=' + document?.model : ''}`;

        await this.router.navigateByUrl(pathToDocument, {
            state: {
                returnTo: this.router.url,
            },
        });
    }

    public getListChipsHeight(): number {
        return this.filterChips?.nativeElement?.offsetHeight;
    }

    public sortAndFilterResults(): any[] {
        const modelFilters = this.filter[0].chips.filter((chip) => !chip.selected && chip.modelType);
        const isSortByDate = this.filter[0].chips[4].selected;
        const isSortyByPdf = this.filter[0].chips[5].selected;
        const isSortyByImage = this.filter[0].chips[6].selected;

        let tempFilter = this.unfilteredResults
            .filter((result) => modelFilters.every((filter) => filter.modelType !== result.model))
            .filter(
                (result) =>
                    (this.documentAccess.group && result.model === 'group') ||
                    (this.documentAccess.flat && result.model === 'flat') ||
                    (this.documentAccess.property && result.model === 'property') ||
                    (this.documentAccess.personal && result.model === 'user')
            );

        if (isSortByDate) {
            tempFilter = tempFilter.sort((a, b) => {
                a =
                    typeof a?.uploaded !== 'object'
                        ? new Date(this.localeDatePipe.transform(a?.uploaded)).getTime() / 1000
                        : a?.uploaded?.seconds;
                b =
                    typeof b?.uploaded !== 'object'
                        ? new Date(this.localeDatePipe.transform(b?.uploaded)).getTime() / 1000
                        : b?.uploaded?.seconds;

                if (a > b) {
                    return -1;
                }
                if (a < b) {
                    return 1;
                }
                return 0;
            });
        }

        if (isSortyByPdf && isSortyByImage) {
            tempFilter = tempFilter.filter(
                (result) => result?.mimetype?.includes('image') || result?.mimetype === 'application/pdf'
            );
        } else if (isSortyByPdf) {
            tempFilter = tempFilter.filter((result) => result?.mimetype === 'application/pdf');
        } else if (isSortyByImage) {
            tempFilter = tempFilter.filter((result) => result?.mimetype?.includes('image'));
        }

        return tempFilter;
    }

    public chipClicked(event: any): void {
        this.filter[0].chips[event.chipId].selected = !this.filter[0].chips[event.chipId].selected;
        this.filteredResults = this.sortAndFilterResults();
    }
}
