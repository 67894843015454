<ion-header>
    <app-topbar
        [title]="'evote.delegate.choosePerson' | translate"
        [left]="'close'"
        (leftClicked)="close(true)"></app-topbar>
</ion-header>

<ion-content *ngIf="currentView === VIEWS.EVOTEINFO">
    <ion-card class="info-card">
        <div class="icon">
            <ion-icon color="primary" name="information-circle-outline"></ion-icon>
        </div>
        <div class="info">
            {{ 'evote.vote.delegate.evote-info' | translate }}
        </div>
    </ion-card>
</ion-content>

<ion-content *ngIf="currentView === VIEWS.DELEGATION">
    <app-basic-card #managerCard [header]="'evote.delegate.to.manager'" (expansion)="onExpand($event, 'manager')">
        <ion-list>
            <app-user-list-item
                (onClick)="selectUser(manager.id, 'manager', manager)"
                [checked]="manager?.id === delegatedTo?.id"
                [user]="manager"
                [showContactInformation]="true"></app-user-list-item>
        </ion-list>

        <br />

        <ion-button
            [disabled]="!(delegatedTo?.model === 'manager' && delegatedTo?.id)"
            expand="full"
            (click)="submitDelegation(delegatedTo, VIEWS.NOTIFYINFO)">
            {{ 'evote.vote.delegate.send' | translate }}
        </ion-button>
    </app-basic-card>

    <app-basic-card #ownerCard [header]="'evote.delegation.owner'" (expansion)="onExpand($event, 'owner')">
        <ion-searchbar
            class="swiper-no-swiping"
            debounce="500"
            (ionChange)="searchElasticOwners($event?.detail?.value)"
            (ionClear)="cancelSearch()"
            [placeholder]="'evote.delegate.searchbar-placeholder' | translate"></ion-searchbar>

        <ion-list>
            <app-user-list-item
                (onClick)="selectUser(user.id, 'owner', user)"
                [hasLines]="false"
                *ngFor="let user of elasticOwners"
                [user]="user"
                [checked]="user?.id === delegatedTo?.id"></app-user-list-item>
        </ion-list>

        <div *ngIf="coupleDetected" class="error">
            {{ 'evote.delegate.custom.error.coupleDetected' | translate }}
        </div>

        <br />

        <ion-button
            [disabled]="!(delegatedTo?.model === 'owner' && delegatedTo?.id) || coupleDetected || loading"
            expand="full"
            (click)="submitDelegation(delegatedTo, VIEWS.NOTIFYINFO)">
            {{ 'evote.vote.delegate.send' | translate }}
        </ion-button>
    </app-basic-card>

    <app-basic-card #customCard [header]="'evote.delegate.to.custom'" (expansion)="onExpand($event, 'custom')">
        <ion-list>
            <ion-item>
                <ion-label position="stacked">{{ 'evote.delegate.custom.contact-title' | translate }}</ion-label>
                <ion-input
                    placeholder="{{ 'evote.delegate.custom.firstname' | translate }}*"
                    [(ngModel)]="customData.firstname"></ion-input>
                <ion-input
                    placeholder="{{ 'evote.delegate.custom.lastname' | translate }}*"
                    [(ngModel)]="customData.lastname"></ion-input>

                <div *ngIf="customData.email && !checkMail(customData.email)" class="error">
                    {{ 'evote.delegate.custom.error.emailInvalid' | translate }}
                </div>
                <ion-input
                    placeholder="{{ 'evote.delegate.custom.email' | translate }}"
                    [(ngModel)]="customData.email"></ion-input>
            </ion-item>

            <ion-item>
                <ion-label position="stacked">{{ 'evote.delegate.custom.address-title' | translate }}</ion-label>
                <ion-input
                    placeholder="{{ 'evote.delegate.custom.street' | translate }}"
                    [(ngModel)]="customData.street"></ion-input>
                <ion-input
                    placeholder="{{ 'evote.delegate.custom.zip' | translate }}"
                    [(ngModel)]="customData.zip"></ion-input>
                <ion-input
                    placeholder="{{ 'evote.delegate.custom.city' | translate }}"
                    [(ngModel)]="customData.city"></ion-input>
                <ion-input
                    placeholder="{{ 'evote.delegate.custom.region' | translate }}"
                    [(ngModel)]="customData.region"></ion-input>
                <ion-input
                    placeholder="{{ 'evote.delegate.custom.country' | translate }}"
                    [(ngModel)]="customData.country"></ion-input>
            </ion-item>

            <br />

            <ion-item>
                <ion-label>{{ 'evote.delegate.custom.add-document' | translate }}</ion-label>
            </ion-item>

            <app-attachments-add (documentsChanged)="onImageSelected($event)"></app-attachments-add>
        </ion-list>

        <br />

        <ion-button
            [disabled]="
                !(
                    delegatedTo?.model === 'custom' &&
                    customData.firstname &&
                    customData.lastname &&
                    (!customData.email || (customData.email && checkMail(customData.email)))
                )
            "
            expand="full"
            (click)="submitDelegation(delegatedTo, VIEWS.NOTIFYINFO)">
            {{ 'evote.vote.delegate.send' | translate }}
        </ion-button>
    </app-basic-card>
</ion-content>

<ion-content *ngIf="currentView === VIEWS.NOTIFYINFO">
    <ion-card class="info-card">
        <div class="icon">
            <ion-icon color="primary" name="information-circle-outline"></ion-icon>
        </div>
        <div class="info">
            {{ 'evote.vote.delegate.notify-info' | translate }}
        </div>
    </ion-card>
</ion-content>

<ion-footer>
    <ion-toolbar *ngIf="currentView === VIEWS.EVOTEINFO">
        <ion-button expand="full" color="primary" (click)="currentView = VIEWS.DELEGATION">
            {{ 'evote.vote.delegate.continue' | translate }}
        </ion-button>
    </ion-toolbar>

    <ion-toolbar *ngIf="currentView === VIEWS.DELEGATION">
        <div
            *ngIf="assembly?.delegatedTo?.name"
            style="text-align: center; color: #999999; margin-bottom: 10px; margin-top: 10px">
            {{ 'evote.vote.delegate.current' | translate }}:
            {{ assembly?.delegatedTo?.name }}
        </div>
        <ion-button [disabled]="!assembly.delegatedTo" expand="full" color="danger" (click)="openConfirmation()">
            {{ 'evote.vote.delegate.revert' | translate }}
        </ion-button>
    </ion-toolbar>

    <ion-toolbar *ngIf="currentView === VIEWS.NOTIFYINFO">
        <ion-button expand="full" color="primary" (click)="close()">
            {{ 'evote.vote.delegate.ok' | translate }}
        </ion-button>
    </ion-toolbar>
</ion-footer>
