import '@angular/compiler';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppInjector } from './app/services/app-injector/app-injector.service';
import * as Sentry from '@sentry/browser';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { register as registerSwiperElements } from 'swiper/element/bundle';
registerSwiperElements();
defineCustomElements(window);

if (environment.production) {
    enableProdMode();
}

function initLogger() {
    if (environment['sentry']) {
        Sentry.init(environment['sentry']);
    }
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((module) => {
        AppInjector.setInjector(module.injector);
        initLogger();
    });
