<ng-container>
    <app-list-with-chip
        #filterChips
        [items]="filter"
        [disableRowTitle]="true"
        [canClose]="false"
        (chipClicked)="chipClicked($event)">
    </app-list-with-chip>

    <ion-searchbar
        placeholder="{{ 'document.search-term' | translate }}"
        [debounce]="1000"
        (ionInput)="handleInput($event)">
    </ion-searchbar>

    <ion-list class="scroll" [style.height]="'calc(100% - var(--header-height) -' + getListChipsHeight() + 'px)'">
        <ion-item *ngFor="let document of filteredResults">
            <ion-label (click)="selectDocument(document)">
                <ion-icon *ngIf="document.model === 'group'" name="grid"></ion-icon>
                <ion-icon *ngIf="document.model === 'property'" name="home"></ion-icon>
                <ion-icon *ngIf="document.model === 'flat'" name="people"></ion-icon>
                <ion-icon *ngIf="document.model === 'user'" name="person"></ion-icon>
                <span class="path">{{ getPathToDocument(document) }}</span>
                <div class="grid">
                    <span class="grid-name">{{ document.name }}</span>
                    <span class="grid-date">{{ document.uploaded | localeDate: 'dd.M.yyyy HH:mm' }}</span>
                </div>
            </ion-label>
        </ion-item>
    </ion-list>
</ng-container>
