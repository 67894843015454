<ion-content class="ion-padding">
    <ion-row class="h-100 ion-align-items-center ion-justify-content-center">
        <ion-col class="ion-no-padding ion-align-self-center">
            <ion-row *ngIf="!loading" class="logo ion-padding-vertical ion-justify-content-center">
                <ion-col *ngIf="originService.pluginReady" size="6">
                    <img [src]="nsData?.logo || logoUrl" />
                </ion-col>
            </ion-row>
            <ion-row class="title ion-justify-content-center">
                <ion-col *ngIf="originService.pluginReady">
                    <div [innerHTML]="titleKey | translate | safeHtml"></div>
                </ion-col>
                <ion-col *ngIf="!originService.pluginReady"> LOGIN </ion-col>
            </ion-row>
            <ion-row class="title ion-justify-content-center">
                <div *ngIf="nsData?.id">
                    <a href="mailTo:{{ nsData?.mail }}">{{ titleContactKey | translate }}</a>
                </div>
                <div *ngIf="!nsData?.id">
                    {{ titleContactKey | translate }}
                </div>
            </ion-row>

            <ion-row class="ion-padding-top">
                <form [formGroup]="form" class="w-100">
                    <ion-item class="ion-no-padding">
                        <ion-input
                            label=""
                            formControlName="email"
                            type="email"
                            [placeholder]="'login.email' | translate"
                            inputmode="email"></ion-input>
                    </ion-item>
                    <ion-item class="ion-no-padding" #password>
                        <ion-input
                            label=""
                            formControlName="password"
                            [placeholder]="'login.password' | translate"
                            [type]="password.showPassword ? 'text' : 'password'"
                            (keyup.enter)="onLogin()"></ion-input>
                        <ion-button fill="clear" slot="end" (click)="password.showPassword = !password.showPassword">
                            <ion-icon slot="icon-only" [name]="password.showPassword ? 'eye-off' : 'eye'"></ion-icon>
                        </ion-button>
                    </ion-item>
                </form>
            </ion-row>
            <ion-button
                class="ion-margin-vertical"
                [disabled]="form.invalid"
                expand="full"
                color="primary"
                (click)="onLogin()">
                {{ 'login.submit-button' | translate }}
            </ion-button>
            <ion-row class="ion-justify-content-center">
                <ion-button (click)="sendPasswordResetMail()" fill="clear" color="primary">
                    {{ 'login.forgot-button' | translate }}
                </ion-button>
            </ion-row>
            <ion-row *ngIf="showDownloadButtons" class="download ion-justify-content-center">
                <a [href]="badges?.playStore?.url">
                    <img [src]="badges?.playStore?.img" />
                </a>
                <a [href]="badges?.appStore?.url">
                    <img class="app-store-img" [src]="badges?.appStore?.img" />
                </a>
            </ion-row>
            <ion-row *ngIf="versionInfo">
                <div class="version-info">{{ 'login.version-hint' | translate }}: {{ versionInfo }}</div>
            </ion-row>
            <ion-row *ngIf="!environment.production && appInfo">
                <div class="version-info">{{ appInfo.id }} | Build: {{ appInfo.build }}</div>
            </ion-row>
        </ion-col>
    </ion-row>
</ion-content>
