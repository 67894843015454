<ion-header>
    <app-topbar [title]="'qrcode-scanner.header' | translate" [left]="'close'" (leftClicked)="close()"></app-topbar>
</ion-header>

<ion-content>
    <zxing-scanner
        (scanComplete)="scanCompleteHandler($event)"
        (scanError)="scanErrorHandler($event)"
        (scanComplete)="scanCompleteHandler($event)"
        [formats]="barcodeFormats"></zxing-scanner>
</ion-content>
<ion-footer>
    <ion-row>
        <ion-col>
            <ion-button expand="block" color="medium" (click)="close()">{{ 'general.cancel' | translate }}</ion-button>
        </ion-col>
    </ion-row>
</ion-footer>
