import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { MarketplaceComponent } from './marketplace.page';
const routes: Routes = [
    {
        path: '',
        component: MarketplaceComponent,
    },
];
@NgModule({
    imports: [CommonModule, FormsModule, RouterModule.forChild(routes)],
    declarations: [MarketplaceComponent],
    exports: [MarketplaceComponent],
})
export class MarketplaceModule {}
