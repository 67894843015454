import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-other-person-info',
    templateUrl: './other-person-info.component.html',
    styleUrls: ['./other-person-info.component.scss'],
})
export class OtherPersonInfoComponent implements OnInit {
    @Input() presentPerson: {
        representation: boolean;
        contact: { name: string; mobile: string };
    };
    form: UntypedFormGroup;
    mobileRegex = /[^\+0-9 \(\)\/]*/g;

    constructor(
        public modalController: ModalController,
        private fb: UntypedFormBuilder
    ) {}

    ngOnInit() {
        this.form = this.fb.group(
            {
                otherName: [this.presentPerson?.contact ? this.presentPerson.contact?.name : null],
                otherTel: [this.presentPerson?.contact ? this.presentPerson.contact?.mobile : null],
            },
            {
                validators: () => {
                    if (this.form) {
                        if (!this.valuesValid([this.otherName.value, this.otherTel.value])) {
                            return { err: true };
                        }
                    }
                    return null;
                },
            }
        );
    }

    submit(representation: boolean) {
        this.modalController.dismiss(
            {
                presentPerson: {
                    representation: representation,
                    contact: {
                        name: representation ? this.otherName.value : null,
                        mobile: representation ? this.otherTel.value : null,
                    },
                },
            },
            'submit'
        );
    }

    validateInput(formControlName: string) {
        const formControl = this.form.get(formControlName);

        if (!this.valuesValid([formControl.value])) {
            formControl.setErrors({ err: true });
        }
    }

    private valuesValid(values: string[]) {
        for (const value of values) {
            if (!value || !value.trim()) {
                return false;
            }
        }
        return true;
    }

    private get otherName() {
        return this.form.get('otherName');
    }
    private get otherTel() {
        return this.form.get('otherTel');
    }
}
