export interface AppointmentItem {
    id: string;
    text: string;
}

const DAYS = {
    MONDAY: { id: 'monday', text: 'days.monday' },
    TUESDAY: { id: 'tuesday', text: 'days.tuesday' },
    WEDNESDAY: { id: 'wednesday', text: 'days.wednesday' },
    THURSDAY: { id: 'thursday', text: 'days.thursday' },
    FRIDAY: { id: 'friday', text: 'days.friday' },
};

const TIMES = {
    MORNING: {
        id: 'morning',
        text: 'days.morning',
    },
    AFTERNOON: {
        id: 'afternoon',
        text: 'days.afternoon',
    },
    ALL_DAY: {
        id: 'anytime',
        text: 'days.anytime',
    },
};

export const APPOINTMENTS = {
    DAYS: [DAYS.MONDAY, DAYS.TUESDAY, DAYS.WEDNESDAY, DAYS.THURSDAY, DAYS.FRIDAY],
    TIMES: [TIMES.MORNING, TIMES.AFTERNOON, TIMES.ALL_DAY],
};
