<ion-item *ngIf="user" [lines]="hasLines ? 'full' : 'none'" (click)="onClickedUser(user)">
    <ion-label>
        <h2>{{ user.firstname }} {{ user.lastname }}</h2>
        <div *ngIf="showContactInformation">
            <h3>{{ user.email }}</h3>
            <p>{{ user.mobile }}</p>
        </div>
    </ion-label>
    <ion-icon [slot]="'end'" *ngIf="checked" name="checkmark-circle-outline"></ion-icon>
</ion-item>
