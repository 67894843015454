import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BrowserService } from 'src/app/services/browser/browser.service';

@Component({
    selector: 'app-contact-phone-mail',
    templateUrl: './contact-phone-mail.component.html',
    styleUrls: ['./contact-phone-mail.component.scss'],
})
export class ContactPhoneMailComponent {
    @Input() showTitle = true;
    @Input() mail: string;
    @Input() phone: string;
    @Input() ticketNo: number | string;

    constructor(
        private translate: TranslateService,
        private browser: BrowserService
    ) {}

    callUser(phoneNumber: string) {
        this.browser.callPhone(phoneNumber);
    }
    async sendMail(emailAddress: string) {
        await this.browser.sendMail(
            emailAddress,
            `${this.translate.instant('ticket-manager-mail.subject')}${this.ticketNo}`
        );
    }
}
