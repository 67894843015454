<ion-content [scrollY]="false" color="light">
    <div class="topbar-in-content">
        <app-topbar
            [left]="'close'"
            [title]="'document-search.header' | translate"
            (leftClicked)="dismiss()"></app-topbar>
    </div>
    <div class="scroll">
        <app-search (select)="documentSelected($event)"></app-search>
    </div>
</ion-content>
