import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MainPage } from './pages/main/main.page';
import { LoginPage } from './pages/login/login.page';
import { LandingPage } from './pages/landing/landing.page';
import { CreateTicketPage } from './pages/create/create-ticket.page';
import { TokenTicketPage } from './pages/main/token-ticket/token-ticket.page';
import { TokenProfilePage } from './pages/main/token-profile/token-profile.page';
import { ModuleGuard } from './guards/moduleguard.service';
import { TokenDocumentsPage } from './pages/main/token-documents/token-documents.page';
import { EVotePage } from './pages/main/e-vote/e-vote.page';
import { VoteGuard } from './guards/voteguard.service';
import { NamespaceChooserPage } from './pages/namespace-chooser/namespace-chooser.page';
import { TicketGuard } from './guards/ticket.guard.service';
import { EVote2Page } from './pages/main/e-vote2/e-vote.page';

const routes: Routes = [
    { path: '', redirectTo: 'start', pathMatch: 'full' },
    {
        path: 'start',
        loadChildren: () => import('./pages/start/start.module').then((m) => m.StartPageModule),
    },
    {
        path: 'namespace-chooser',
        component: NamespaceChooserPage,
        loadChildren: () =>
            import('./pages/namespace-chooser/namespace-chooser.module').then((m) => m.NamespaceChooserPageModule),
    },
    {
        path: 'login',
        component: LoginPage,
        loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule),
    },
    {
        path: 'landing',
        component: LandingPage,
        loadChildren: () => import('./pages/landing/landing.module').then((m) => m.LandingPageModule),
    },
    {
        path: 'main',
        component: MainPage,
        children: [
            {
                path: 'guest',
                component: TokenTicketPage,
                loadChildren: () =>
                    import('./pages/main/token-ticket/token-ticket.module').then((m) => m.TokenTicketModule),
            },
            {
                path: 'guest-profile',
                component: TokenProfilePage,
                loadChildren: () =>
                    import('./pages/main/token-profile/token-profile.module').then((m) => m.TokenProfileModule),
            },
            {
                path: 'guest-documents',
                component: TokenDocumentsPage,
                loadChildren: () =>
                    import('./pages/main/token-documents/token-documents.module').then((m) => m.TokenDocumentsModule),
            },
            {
                path: '',
                redirectTo: 'tickets',
                pathMatch: 'full',
                data: {
                    fallbackURL: 'main/blackboard',
                    requiredModule: ['tickets', 'handover'],
                },
            },
            { path: '', redirectTo: 'profile', pathMatch: 'full' },
            {
                path: 'tickets',
                canActivate: [ModuleGuard],
                data: {
                    fallbackURL: 'main/blackboard',
                    requiredModule: ['tickets', 'handover'],
                },
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import('./pages/main/tickets/tickets.module').then((m) => m.TicketsPageModule),
                    },
                    {
                        path: 'detail/:ticketId',
                        canActivate: [TicketGuard],
                        loadChildren: () =>
                            import('./pages/main/tickets/ticket-details/ticket-details.module').then(
                                (m) => m.TicketDetailsPageModule
                            ),
                    },
                ],
            },
            {
                path: 'news',
                loadChildren: () => import('./pages/main/news/news.module').then((m) => m.NewsPageModule),
            },
            {
                path: 'blackboard',
                canActivate: [ModuleGuard],
                data: {
                    requiredModule: 'communication',
                    fallbackURL: 'main/documents',
                },
                loadChildren: () =>
                    import('./pages/main/blackboard/blackboard.module').then((m) => m.BlackboardPageModule),
            },
            {
                path: 'documents',
                loadChildren: () =>
                    import('./pages/main/documents/documents.module').then((m) => m.DocumentsPageModule),
            },
            {
                path: 'profile',
                loadChildren: () => import('./pages/main/profile/profile.module').then((m) => m.ProfilePageModule),
            },
            {
                path: 'evote',
                canActivate: [VoteGuard],
                children: [
                    {
                        path: '',
                        component: EVotePage,
                        loadChildren: () => import('./pages/main/e-vote/e-vote.module').then((m) => m.EVotePageModule),
                    },
                    {
                        path: 'detail/:assemblyId',
                        children: [
                            {
                                path: '',
                                loadChildren: () =>
                                    import('./pages/main/e-vote/e-vote-details/e-vote-details.module').then(
                                        (m) => m.EVoteDetailsPageModule
                                    ),
                            },
                            {
                                path: 'checkpoint/:checkpointId',
                                loadChildren: () =>
                                    import('./pages/checkpoint/checkpoint.module').then((m) => m.CheckpointPageModule),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'evote2',
                canActivate: [VoteGuard],
                children: [
                    {
                        path: '',
                        component: EVote2Page,
                        loadChildren: () =>
                            import('./pages/main/e-vote2/e-vote.module').then((m) => m.EVote2PageModule),
                    },
                    {
                        path: 'detail/:assemblyId',
                        children: [
                            {
                                path: '',
                                loadChildren: () =>
                                    import('./pages/main/e-vote2/e-vote-details/e-vote-details.module').then(
                                        (m) => m.EVote2DetailsPageModule
                                    ),
                            },
                            {
                                path: 'checkpoint2/:checkpointId',
                                loadChildren: () =>
                                    import('./pages/checkpoint2/checkpoint.module').then(
                                        (m) => m.Checkpoint2PageModule
                                    ),
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: 'create',
        component: CreateTicketPage,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
