import { Component, OnInit } from '@angular/core';
import { BarcodeFormat } from '@zxing/browser';
import { ModalController } from '@ionic/angular';
import { Result } from '@zxing/library';

@Component({
    selector: 'app-qrcode-scanner',
    templateUrl: './qrcode-scanner.component.html',
    styleUrls: ['./qrcode-scanner.component.scss'],
})
export class QrcodeScannerComponent {
    barcodeFormats: BarcodeFormat[] = [BarcodeFormat.QR_CODE];

    constructor(private modalController: ModalController) {}

    async scanCompleteHandler($event: Result): Promise<void> {
        if ($event?.getText()) {
            await this.modalController.dismiss({ scannedText: $event.getText() });
        }
    }

    scanErrorHandler($event: any): void {
        console.error('Scan Error', $event);
    }

    async close(): Promise<void> {
        await this.modalController.dismiss();
    }
}
