import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';
import { UserService } from '../user/user.service';
import { ElasticService } from '../elastic/elastic.service';
import {
    collection,
    collectionData,
    doc,
    docData,
    Firestore,
    limit,
    orderBy,
    query,
    where,
} from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    private notificationsCollection = null;
    public currentEvoteNotifications = [];
    public currentDocumentNotifications = [];
    public evoteNotificationCount = 0;
    public documentNotificationCount = 0;

    constructor(
        private firestore: Firestore,
        private userService: UserService,
        private api: ApiService,
        private elastic: ElasticService
    ) {
        this.notificationsCollection = `ns/${this.userService.getNamespace()}/notifications`;
    }

    observeLatestNotifications(count = 10) {
        const collectionRef = collection(this.firestore, this.notificationsCollection);
        const q = query(
            collectionRef,
            where('uid', '==', this.userService.user.id),
            orderBy('createdOn', 'desc'),
            limit(count)
        );
        return collectionData(q, { idField: 'id' });
    }

    public observeNotificationById(id: string) {
        const docRef = doc(this.firestore, `${this.notificationsCollection}/${id}`);
        return docData(docRef, { idField: 'id' });
    }

    observeUnreadNotifications(count = 10) {
        if (!this.userService.user?.id) {
            return new Observable<any>(null);
        }

        const collectionRef = collection(this.firestore, this.notificationsCollection);
        const q = query(
            collectionRef,
            where('uid', '==', this.userService.user.id),
            where('read', '==', false),
            limit(count)
        );
        return collectionData(q, { idField: 'id' });
    }

    async removeNotifications(ids: string[]) {
        return this.api.post('notifications/bulk/delete', {
            notifications: ids,
        });
    }

    async removeAllNotifications() {
        return this.api.post('notifications/deleteAll');
    }

    async toggleRead(ids: string[], read: boolean) {
        return this.api.post('notifications/bulk/update', {
            notifications: ids.map((id) => {
                return {
                    id,
                    read,
                };
            }),
        });
    }

    async updateReadAll(read: boolean) {
        return this.api.post('notifications/updateReadAll', { read });
    }

    async handleNotifications() {
        const result = await this.elastic.getNotifications({ read: false }, 0, 999, true);

        this.currentEvoteNotifications = result?.docs?.filter((src) => src?.contentPlaceholder?.ASSEMBLY_ID) || [];

        this.currentDocumentNotifications =
            result?.docs?.filter((src) => src?.contentPlaceholder?.DOCUMENTS_NAME) || [];

        this.evoteNotificationCount =
            [...new Set(this.currentEvoteNotifications.map((not) => not.contentPlaceholder.ASSEMBLY_ID))].length || 0;

        this.documentNotificationCount = 0;
        for (const docNot of this.currentDocumentNotifications) {
            this.documentNotificationCount += docNot.contentPlaceholder.DOCUMENTS_NAME.split(',').length;
        }
    }
}
