import { Component, Input } from '@angular/core';
import { AppointmentItem, APPOINTMENTS } from '../../const/appointments.const';
import { Appointment } from '../../models/appointment';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-appointments',
    templateUrl: './appointments.component.html',
    styleUrls: ['./appointments.component.scss'],
})
export class AppointmentsComponent {
    @Input() selectedAppointments: Appointment[] = [];

    days: AppointmentItem[] = [];
    times: AppointmentItem[] = [];

    constructor(public modalController: ModalController) {
        this.days = APPOINTMENTS.DAYS.map((day: AppointmentItem) => ({ ...day, chips: [] }));
        this.times = [...APPOINTMENTS.TIMES];
    }

    onTimeSelected(selectedTime: AppointmentItem, selectedDay: AppointmentItem): void {
        const appointment: Appointment = this.selectedAppointments.find((a: Appointment) => a.day === selectedDay.id);

        if (appointment) {
            appointment.time = selectedTime.id;
        } else if (selectedTime.id !== 'clear') {
            // Add a new appointment
            this.selectedAppointments.push({
                day: selectedDay.id,
                time: selectedTime.id,
            });
        }
    }

    async onUseSelection(): Promise<void> {
        await this.modalController.dismiss(this.selectedAppointments);
    }

    async onNoAppointments(): Promise<void> {
        await this.modalController.dismiss();
    }

    getSelectedTime(selectedDay: AppointmentItem): AppointmentItem | null {
        const appointment = this.selectedAppointments.find((a: Appointment) => a.day === selectedDay.id);
        return appointment ? this.times.find((t: AppointmentItem) => t.id === appointment.time) || null : null;
    }

    clearSelection(selectedDay: AppointmentItem): void {
        const appointmentIndex = this.selectedAppointments.findIndex((a: Appointment) => a.day === selectedDay.id);
        if (appointmentIndex !== -1) {
            this.selectedAppointments.splice(appointmentIndex, 1);
        }
    }
}
