import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UserService } from '../../../services/user/user.service';
import { environment } from '../../../../environments/environment';
@Component({
    selector: 'app-marketplace.page',
    templateUrl: './marketplace.page.html',
    styleUrls: ['./marketplace.page.scss'],
})
export class MarketplaceComponent implements OnInit {
    marketplaceUrl: SafeResourceUrl;
    constructor(
        protected userService: UserService,
        private sanitizer: DomSanitizer
    ) {}
    ngOnInit() {
        const namespaceId = this.userService.getNamespace();
        const url = environment.webExposeUrl.replace(':namespaceId', namespaceId);
        this.marketplaceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
