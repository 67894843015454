<app-basic-card [header]="'ticket-notes.title'" (expansion)="onExpansion($event)" [expanded]="expanded">
    <ion-row *ngIf="loading" class="ion-padding ion-justify-content-center">
        <ion-spinner></ion-spinner>
    </ion-row>

    <div *ngIf="!loading">
        <ion-row
            *ngIf="
                !(ticketState === 'FINISHED' || ticketState === 'CANCELED' || ticketState === 'DECLINED') &&
                (this.extendedCommunication || (this.notes.length && this.notes[0].creator.type === 'manager'))
            "
            class="notes-create-button-wrapper">
            <ion-col>
                <ion-fab-button
                    *ngIf="ticket.flatId"
                    (click)="showCreateTicketNoteModal()"
                    color="primary"
                    class="ion-float-right"
                    size="small">
                    <ion-icon name="add"></ion-icon>
                </ion-fab-button>
            </ion-col>
        </ion-row>

        <ion-card
            *ngFor="let note of notes; index as i"
            [class.sent]="note.creator.type === 'user' && note.creator.id === currentUserId"
            [class.highlight]="note.highlight">
            <div *ngIf="note.deleted">
                <ion-card-content style="font-style: italic">
                    {{ 'comment.deleted' | translate }}
                </ion-card-content>
            </div>
            <div *ngIf="!note.deleted" style="width: 100%; height: 100%">
                <ion-card-header>
                    <ion-item lines="none">
                        <ion-label>
                            <ion-row style="display: flex; justify-content: space-between">
                                <b>
                                    {{ note.creator.name }}

                                    <span *ngIf="note.type !== 'tenant' || note.creator.type !== 'user'">
                                        <ion-icon style="transform: translate(0px, 2px)" name="arrow-forward-outline">
                                        </ion-icon>

                                        {{ 'ticket.notes.recipient-' + note.type | translate }}
                                    </span>
                                </b>
                                <div
                                    *ngIf="
                                        note.creator.type === 'user' &&
                                        note.creator.id === currentUserId &&
                                        (note.type === 'manager' || note.type === 'provider')
                                    ">
                                    <div
                                        *ngIf="
                                            managers?.length && hasCommentBeenRead(note, managers[0], note.type);
                                            else sent
                                        "
                                        style="display: flex; align-items: center">
                                        {{ 'ticket.notes.message.read' | translate }}
                                        <ion-icon name="checkmark-done-outline" class="sent-icon"></ion-icon>
                                    </div>
                                    <ng-template #sent>
                                        <div style="display: flex; align-items: center">
                                            {{ 'ticket.notes.message.sent' | translate }}
                                            <ion-icon name="checkmark-outline" class="sent-icon"></ion-icon>
                                        </div>
                                    </ng-template>
                                </div>
                            </ion-row>
                            <ion-row>
                                {{ note.createdOn | timeAgo | async }} |
                                {{ note.createdOn | localeDate }}
                                {{ note.createdOn | date: 'HH:mm' }}
                            </ion-row>
                        </ion-label>
                    </ion-item>
                </ion-card-header>

                <ion-card-content>
                    <show-more class="show-line-breaks" [text]="note.text | customTranslation"></show-more>
                    <original-text class="show-line-breaks" [text]="note.text"></original-text>
                </ion-card-content>
                <app-document-view [documents]="getDocumentsForViewByIndex(i)" [lines]="'none'"></app-document-view>
                <ion-icon
                    *ngIf="note?.creator?.id === userService?.user?.id"
                    name="trash"
                    color="danger"
                    class="delete-icon"
                    (click)="delete(note)"></ion-icon>
            </div>
        </ion-card>
    </div>
</app-basic-card>
