<ng-container *ngIf="text.length > limit">
    <span *ngIf="isClosed" [innerHTML]="text | shorten: limit : '' | safeHtml"></span>
    <span *ngIf="!isClosed" [innerHTML]="text | safeHtml"></span>
    <span class="spacer" (click)="toggleText($event)">
        <ion-text *ngIf="isClosed" [color]="buttonColor">
            {{ 'general.more' | translate }}
        </ion-text>
        <ion-text *ngIf="!isClosed" [color]="buttonColor">
            {{ 'general.less' | translate }}
        </ion-text>
    </span>
</ng-container>

<ng-container *ngIf="text.length <= limit">
    <span [innerHTML]="text | safeHtml"></span>
</ng-container>
