import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { NamespaceInfo } from '../../models/namespace-info';
import { BaseDataService } from '../../util/base-data-service';
import { ApiService } from '../api/api.service';
import { FirebaseWrapperService } from '../firebase-wrapper/firebase-wrapper.service';

@Injectable({
    providedIn: 'root',
})
export class NamespaceService implements BaseDataService {
    private namespace: NamespaceInfo;
    ticketConfig: any;
    flatTypeConfig: any;

    constructor(
        private userService: UserService,
        private firebaseService: FirebaseWrapperService,
        private api: ApiService
    ) {}

    getName() {
        return this.namespace?.name;
    }

    get() {
        return this.namespace;
    }

    async initialize() {
        [this.ticketConfig, this.flatTypeConfig] = await Promise.all([
            await this.getTicketConfiguration(),
            this.getFlatTypeConfiguration(),
        ]);

        if (this.namespace) {
            return this.namespace;
        }

        this.namespace = (await this.firebaseService.docData('ns', this.userService.getNamespace())) as NamespaceInfo;
        return this.namespace;
    }

    async getNsSplashLogo() {
        if (this.namespace) {
            return this.namespace.profilePicture || 'assets/imgs/default_splash.png';
        }

        if (!this.userService.user) {
            await this.userService.getUser();
        }
        await this.initialize();
        return this.namespace.profilePicture || 'assets/imgs/default_splash.png';
    }

    async getTicketConfiguration(): Promise<any> {
        return await this.firebaseService.docData(`ns/${this.userService.getNamespace()}/configurations`, 'tickets');
    }

    async getTenantConfiguration(): Promise<any> {
        return await this.firebaseService.docData(`ns/${this.userService.getNamespace()}/configurations`, 'tenants');
    }

    async getFlatTypeConfiguration(): Promise<any> {
        return await this.firebaseService.docData(`ns/${this.userService.getNamespace()}/configurations`, 'flatTypes');
    }

    async getDomain(): Promise<string> {
        const response = await this.api.get('namespaces/domain');
        return response?.domain || null;
    }

    terminate() {
        this.namespace = null;
    }
}
