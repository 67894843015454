import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OwnershipAssembly } from '../../../models/ownershipAssembly';
import { AssemblyStates } from '../../../models/assemblyStates.enum';
import { Subscription } from 'rxjs';
import { LoadingController, Platform } from '@ionic/angular';
import { isEmpty, map } from 'rxjs/operators';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { OwnershipAssemblyV2Service } from '../../../services/ownership-assembly/ownership-assembly-v2.service';
import { Capacitor } from '@capacitor/core';

export const refreshEvent = new EventEmitter<void>();

@Component({
    selector: 'app-e-vote',
    templateUrl: './e-vote.page.html',
    styleUrls: ['./e-vote.page.scss'],
})
export class EVote2Page implements OnInit, OnDestroy {
    @ViewChild('slider') slider;
    readonly sliderHeaderData = [
        { label: 'tickets.menu-open', badge: 0 },
        { label: 'tickets.menu-closed', badge: 0 },
    ];

    assemblies: OwnershipAssembly[] = [];
    assembliesOpen: OwnershipAssembly[] = [];
    assembliesClosed: OwnershipAssembly[] = [];
    sliderInitialSlide = 0;
    currentSlideIndex = this.sliderInitialSlide;
    load;
    assemblySubscription = new Subscription();
    isNativeApp = Capacitor.isNativePlatform();

    constructor(
        private ownershipService: OwnershipAssemblyV2Service,
        private loader: LoadingController,
        private notificationsService: NotificationsService
    ) {}

    async ngOnInit() {
        refreshEvent.subscribe(async () => {
            await this.initAssembly();
        });
        await this.initAssembly();
    }

    ionViewWillEnter() {
        if (this.notificationsService.currentEvoteNotifications?.length) {
            const ids = this.notificationsService.currentEvoteNotifications?.map((not) => not.id);
            this.notificationsService.toggleRead(ids, true);
        }
    }

    async initAssembly() {
        await this.showLoader();
        this.assemblySubscription = this.ownershipService
            .getAllOwnershipAssembliesObservable()
            .pipe(
                map((assemblies) => {
                    return assemblies.sort((a, b) => a?.startDate - b?.startDate);
                })
            )
            .subscribe(
                (ownerShipAssemblies: OwnershipAssembly[]) => {
                    this.assembliesOpen = ownerShipAssemblies.filter((assembly) => this.getOpenAssemblies(assembly));
                    this.assembliesClosed = ownerShipAssemblies.filter((assembly) =>
                        this.getClosedAssemblies(assembly)
                    );
                    this.dismissLoader();
                },
                () => {
                    this.dismissLoader();
                }
            );

        if (this.ownershipService.getAllOwnershipAssembliesObservable().pipe(isEmpty())) {
            await this.dismissLoader();
        }
    }

    onSlideChanged(slideIndex: number) {
        this.currentSlideIndex = slideIndex;
    }

    async showLoader() {
        this.load = await this.loader.create();
        await this.load.present();
    }

    async dismissLoader() {
        await this.load.dismiss();
    }

    getClosedAssemblies(assembly: OwnershipAssembly) {
        return [AssemblyStates.PROCESSED].includes(assembly.state as AssemblyStates);
    }

    getOpenAssemblies(assembly: OwnershipAssembly) {
        return [AssemblyStates.INVITED, AssemblyStates.STARTED, AssemblyStates.CLOSED].includes(
            assembly.state as AssemblyStates
        );
    }

    async togglePresenceWithQrScanner() {
        return await this.ownershipService.togglePresenceWithQrScanner();
    }

    ngOnDestroy() {
        this.assemblySubscription.unsubscribe();
    }
}
