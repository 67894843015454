import { Component, Input } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { Browser, OpenOptions } from '@capacitor/browser';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '../../services/popup/popup.service';

@Component({
    selector: 'pdf-component',
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfComponent {
    @Input() document: any;
    loader: any;

    constructor(
        public modalController: ModalController,
        private loadingController: LoadingController,
        private popupService: PopupService,
        private translate: TranslateService
    ) {}

    async download() {
        const openOption: OpenOptions = {
            url: this.document.file,
            windowName: '_system',
        };
        await Browser.open(openOption);
    }

    /**
     * Dismiss loader triggered from documents-detail
     *
     */
    async dismissLoaderFromDocumentsDetail() {
        try {
            await this.loadingController.dismiss(null, null, 'document-loading');
        } catch {
            // loader not present
        }
    }

    async loadingFailed() {
        await this.dismissLoaderFromDocumentsDetail();

        await this.popupService.showAlert('', this.translate.instant('documents.download-failed'), [
            {
                text: this.translate.instant('button.close'),
                handler: () => {
                    this.modalController.dismiss();
                },
            },
        ]);
    }
}
