<ion-content scrollY="true" class="ion-padding" color="light">
    <div
        #scrollContent
        [ngStyle]="{ minHeight: contentMinHeight + 'px' }"
        [ngClass]="{ 'minus-input': inputOptions?.visible }">
        <app-chat-bubble
            *ngFor="let message of messages; index as index"
            [wasSent]="message.wasSent"
            [messageType]="message.type"
            [ngSwitch]="message.type"
            [profilePicture]="message.profilePicture"
            [ngClass]="{ hidden: isMessageHidden(message) }">
            <app-chat-message-selection
                *ngSwitchCase="STEP_TYPE.SELECTION"
                [data]="message.options"
                (selected)="optionClicked($event)"></app-chat-message-selection>
            <app-chat-message-text *ngSwitchCase="STEP_TYPE.TEXT" [data]="message.text"></app-chat-message-text>
            <div class="message-image" *ngSwitchCase="STEP_TYPE.IMAGE">
                <ion-button class="delete-btn ion-no-padding" fill="clear" (click)="deleteImage(index)">
                    <ion-icon name="trash"></ion-icon>
                </ion-button>
                <app-chat-message-image *ngSwitchCase="STEP_TYPE.IMAGE" [data]="message.src"></app-chat-message-image>
            </div>
        </app-chat-bubble>
    </div>
</ion-content>
<ion-footer *ngIf="inputOptions?.visible">
    <ion-row class="ion-align-items-center">
        <ion-button *ngIf="inputOptions?.imageUpload && isNativeApp" fill="clear" (click)="takePhoto()">
            <ion-icon color="primary" slot="icon-only" name="camera"></ion-icon>
        </ion-button>
        <ion-button *ngIf="inputOptions?.imageUpload" fill="clear" (click)="addImageFromGallery()">
            <ion-icon color="primary" slot="icon-only" name="image"></ion-icon>
        </ion-button>
        <input
            *ngIf="!isNativeApp"
            #documentInput
            class="hidden"
            type="file"
            accept="image/*"
            multiple="false"
            (change)="onImageSelected($event)" />
        <ion-col>
            <div class="input-field-container">
                <ion-item class="ion-item-class" lines="none" *ngIf="!disableSubmit && isNoAdress()">
                    <ion-textarea
                        *ngIf="inputOptions.type === InputType.NONE"
                        #description
                        auto-grow="true"
                        rows="1"
                        [placeholder]="'ticket-create.description-placeholder' | translate"
                        [(ngModel)]="userInput"></ion-textarea>
                    <ion-input
                        label=""
                        *ngIf="inputOptions.type === InputType.Phone"
                        #phone
                        [match]="mobileRegex"
                        [placeholder]="'ticket-create.data-placeholder' | translate"
                        [type]="inputOptions.type"
                        [(ngModel)]="userInput"></ion-input>
                </ion-item>
                <ion-fab-button class="ion-margin-left" size="small" (click)="submitText()">
                    <ion-icon class="send-icon" name="send"></ion-icon>
                </ion-fab-button>
            </div>

            <app-input-google-places
                *ngIf="inputOptions.type === InputType.ALL && !disableSubmit"
                (selected)="selectedItem($event)"
                (submitText)="submitText()">
            </app-input-google-places>
            <app-input-autocomplete
                *ngIf="inputOptions.type === InputType.NS && !disableSubmit"
                (selected)="selectedItem($event)"
                (submitText)="submitText()">
            </app-input-autocomplete>
        </ion-col>
    </ion-row>
</ion-footer>
