import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FileDocument } from 'src/app/models/file-document';
import { TicketAddPicturesComponent } from '../../../modals/ticket-add-pictures/ticket-add-pictures.component';
import { TicketStatus } from '../../../models/ticket-status.enum';
import { DocumentUtilService } from 'src/app/services/document-util/document-util.service';

@Component({
    selector: 'app-ticket-pictures-card',
    templateUrl: './ticket-pictures-card.component.html',
    styleUrls: ['./ticket-pictures-card.component.scss'],
})
export class TicketPicturesCardComponent implements OnInit {
    TicketStatus = TicketStatus;
    @Output() documentsChanged: EventEmitter<{
        imgs: FileDocument[];
        pdfs: FileDocument[];
    }> = new EventEmitter<{ imgs: FileDocument[]; pdfs: FileDocument[] }>();
    @Output() imageDeleted: EventEmitter<number> = new EventEmitter<number>();
    @Input() images: any;
    @Input() pdfs: any;
    @Input() open: boolean;
    @Input() ticketStatus: string;

    slideOpts = {
        height: 200,
        centeredSlides: false,
        slidesPerView: 1.5,
        zoom: false,
    };

    expanded = false;

    constructor(
        private modalController: ModalController,
        private documentService: DocumentUtilService
    ) {}

    ngOnInit() {
        if (this.open) {
            this.onExpansion(true);
        }
        if (this.images.length === 1) {
            this.slideOpts.centeredSlides = true;
        }
    }

    colsize(index) {
        const length = this.images.length;
        if (length === 1) {
            return 12;
        } else if (length === 3) {
            if (index > 0) {
                return 6;
            } else {
                return 12;
            }
        } else if (length === 5) {
            if (index > 1) {
                return 4;
            } else {
                return 6;
            }
        } else if (length === 2 || length === 4) {
            return 6;
        } else if (length > 5) {
            return 4;
        } else {
            return 12;
        }
    }

    onExpansion(expanded: boolean) {
        this.expanded = expanded;
    }

    emitDocumentsChanged(images: any[], pdfs: any[]) {
        this.documentsChanged.emit({ pdfs: pdfs || [], imgs: images || [] });
    }

    async onOpenAddPicturesModal() {
        const modal = await this.modalController.create({
            component: TicketAddPicturesComponent,
        });

        await modal.present();
        const dismissed = await modal.onDidDismiss();

        if (dismissed.data) {
            const { images, pdfs } = dismissed.data;
            this.emitDocumentsChanged(images, pdfs);
        }
    }

    deletingImage(event: number) {
        this.imageDeleted.emit(event);
    }

    async openPdf(pdf) {
        await this.documentService.openDocument(pdf);
    }
}
