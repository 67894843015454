<ion-card (click)="onSelectCheckpoint(checkpointId)" class="accent-border">
    <ion-row class="ion-padding no-padding-top">
        <ion-col size="2">
            <span class="checkpoint-number">{{ numbering ? numbering : index + 1 }}</span>
        </ion-col>
        <ion-col size="6">
            <ion-row>
                <ion-text class="text-bold" style="width: 100%">
                    <app-ticket-text
                        [title]="null"
                        [ticketId]="null"
                        [key]="'checkpoint.' + checkpointId + '.title'"></app-ticket-text>
                </ion-text>
            </ion-row>
            <ion-row *ngIf="type">
                <ion-text>{{ 'evote.checkpoint.type.' + type | translate }}</ion-text>
            </ion-row>
            <ion-row *ngIf="closedCheckpoint && assemblyClosed">
                <ion-text color="danger" *ngIf="!closedCheckpoint.accepted && relevant">
                    <b>{{ 'evote.checkpoint.type.closed.declined' | translate }}</b>
                </ion-text>
                <ion-text color="success" *ngIf="closedCheckpoint.accepted && relevant">
                    <b>{{ 'evote.checkpoint.type.closed.accepted' | translate }}</b>
                </ion-text>
            </ion-row>
        </ion-col>
        <ion-col style="margin-left: 3px">
            <ng-container *ngIf="votable && relevant">
                <ion-button class="ion-margin-top" size="small" color="success" *ngIf="accepted === true">
                    {{ 'evote.checkpoint.yes' | translate }}
                </ion-button>
                <ion-button class="ion-margin-top" size="small" color="danger" *ngIf="accepted === false">
                    {{ 'evote.checkpoint.no' | translate }}
                </ion-button>
                <ion-button class="ion-margin-top" size="small" *ngIf="voted && accepted === null && delegated == null">
                    {{ 'evote.checkpoint.abstained' | translate }}
                </ion-button>
                <ion-button class="ion-margin-top" size="small" *ngIf="!voted && relevantCheckpointType">
                    {{ 'evote.checkpoint.notvoted' | translate }}
                </ion-button>
            </ng-container>

            <ion-col
                *ngIf="
                    (closedCheckpoint || assemblyClosed) &&
                    type !== CHECKLISTTYPES.NODECISION &&
                    type !== CHECKLISTTYPES.TBD &&
                    relevant
                ">
                <ion-icon class="ion-margin-top" color="danger" name="lock-closed-outline"></ion-icon>
            </ion-col>
            <ion-button *ngIf="!relevant" color="warning" class="ion-margin-top" size="small">
                {{ 'evote.checkpoint.irrelevant' | translate }}
            </ion-button>
        </ion-col>

        <ion-col *ngIf="latestTimestamp && latestVoterName">
            <ion-chip color="medium">
                {{ latestTimestamp | date: 'dd.MM.yyyy | HH:mm' }} {{ 'evote.checkpoint.vote-by' | translate }}
                {{ latestVoterName }}
            </ion-chip>
        </ion-col>

        <ion-col *ngIf="hasAttachments">
            <ion-icon class="ion-margin-top" color="primary" name="attach"></ion-icon>
        </ion-col>
    </ion-row>
</ion-card>
