import { CraftsmanService } from '../../../services/craftsman/craftsman.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-ticket-appointment-service-provider',
    templateUrl: './ticket-appointment-service-provider.component.html',
    styleUrls: ['./ticket-appointment-service-provider.component.scss'],
})
export class TicketAppointmentServiceProviderComponent implements OnInit {
    @Input() offers;
    @Input() ticketNo;

    public provider;
    constructor(private craftsmanService: CraftsmanService) {}

    async ngOnInit() {
        this.provider = await this.craftsmanService.getCraftsman(this.offers.providerId);
    }

    onExpand() {}
}
