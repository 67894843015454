import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import axios from 'axios';
import { BackgroundColor, InAppBrowser, ToolBarType } from '@capgo/inappbrowser';
import { WoonigWebviewModel } from '../../models/woonig-webview.model';
import { WoonigEmbeddedLinkModel } from '../../models/woonig-embedded-link.model';

@Injectable({
    providedIn: 'root',
})
export class WoonigWebviewService {
    constructor() {}

    async showWoonigWebview(userId: string, namespaceId: string, externalLink: WoonigEmbeddedLinkModel) {
        if (externalLink.needsProcessing) {
            const res = await axios.get<WoonigWebviewModel>(
                `${environment.apiBase}thirdparty/webview/${namespaceId}/${userId}/${externalLink.name}`
            );
            const viewModel = res.data;
            await InAppBrowser.openWebView({
                url: viewModel.webViewUrl,
                headers: viewModel.headers,
                showArrow: true,
                toolbarColor: '#FFFFFF',
                toolbarType: ToolBarType.NAVIGATION,
                backgroundColor: BackgroundColor.WHITE,
            });
        } else {
            await InAppBrowser.openWebView({
                url: externalLink.url,
                showArrow: true,
                toolbarColor: '#FFFFFF',
                toolbarType: ToolBarType.NAVIGATION,
                backgroundColor: BackgroundColor.WHITE,
            });
        }
    }

    async getAllEmbeddedLinks(namespaceId: string): Promise<WoonigEmbeddedLinkModel[]> {
        const res = await axios.get<WoonigEmbeddedLinkModel[]>(`${environment.apiBase}embeddedLinks/${namespaceId}`);
        return res.data;
    }
}
