<ion-toolbar [ngClass]="{ 'custom-theme': customTheme }">
    <ion-segment (ionChange)="changeSliderValue($event)" scrollable>
        <ion-segment-button
            *ngFor="let segment of headerData; index as index"
            [value]="index + ''"
            mode="md"
            [layout]="segment.noLayout ? '' : 'icon-start'"
            [ngClass]="{
                'has-badge': segment.badge,
            }">
            <ion-icon *ngIf="segment.icon" [name]="segment.icon"></ion-icon>
            <ion-label *ngIf="segment.label" class="ion-no-margin label" [class.label]="segment.noLayout === true">
                {{ segment.label | translate }}
            </ion-label>
            <ion-badge class="header-ion-badge-color" *ngIf="segment.badge">{{ segment.badge }}</ion-badge>
        </ion-segment-button>
    </ion-segment>
</ion-toolbar>
<swiper-container #swiper appSwiper [config]="slideOpts">
    <swiper-slide>
        <ion-content>
            <ng-content select="[left]"></ng-content>
        </ion-content>
    </swiper-slide>
    <swiper-slide>
        <ion-content>
            <ng-content select="[middleleft]"></ng-content>
        </ion-content>
    </swiper-slide>
    <swiper-slide *ngIf="headerData.length >= 3">
        <ion-content>
            <ng-content select="[middle]"></ng-content>
        </ion-content>
    </swiper-slide>
    <swiper-slide *ngIf="headerData.length >= 4">
        <ion-content>
            <ng-content select="[middleright]"></ng-content>
        </ion-content>
    </swiper-slide>
    <swiper-slide *ngIf="headerData.length >= 5">
        <ion-content>
            <ng-content select="[right]"></ng-content>
        </ion-content>
    </swiper-slide>
</swiper-container>
