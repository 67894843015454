import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { TicketNode } from '../../models/ticket-node';
import { BaseDataService } from '../../util/base-data-service';
import { collection, collectionData, Firestore } from '@angular/fire/firestore';
import { UserService } from '../user/user.service';
import { TextService } from '../text/text.service';
import { map, switchMap } from 'rxjs/operators';
import { TextObjAggregated } from '../../models/text-obj-aggregated';

@Injectable({
    providedIn: 'root',
})
export class TicketTreeDataService implements BaseDataService {
    private sub: Subscription;
    ticketTree$: BehaviorSubject<TicketNode[]> = new BehaviorSubject<TicketNode[]>([]);

    constructor(
        private firestore: Firestore,
        private userService: UserService,
        private textService: TextService
    ) {}

    initialize() {
        return new Promise<void>((resolve) => {
            this.sub = this.textService.texts$
                .asObservable()
                .pipe(
                    switchMap((texts) => {
                        if (texts) {
                            const collectionRef = collection(
                                this.firestore,
                                `ns/${this.userService.getNamespace()}/ticketTree`
                            );
                            return collectionData(collectionRef).pipe(
                                map((trees: TicketNode[]) => {
                                    for (const tree of trees) {
                                        this.mapNodeTexts(tree as TicketNode, texts);
                                    }
                                    return trees;
                                })
                            );
                        } else {
                            return of([]);
                        }
                    })
                )
                .subscribe((trees) => {
                    this.ticketTree$.next(trees as TicketNode[]);
                    resolve();
                    this.sub.unsubscribe();
                });
        });
    }

    private mapNodeTexts(node: TicketNode, texts: TextObjAggregated) {
        if (!node) {
            return;
        }
        if (node.descriptionId) {
            node.description = texts[node.descriptionId];
        }
        if (node.textId) {
            node.text = texts[node.textId];
        }
        if (node.children) {
            for (const childNode of node.children) {
                this.mapNodeTexts(childNode, texts);
            }
        }
    }

    terminate() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        this.ticketTree$ = null;
    }
}
