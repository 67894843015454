import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor(private http: HttpClient) {}

    head(endpoint: string, options?: any): Promise<any> {
        return lastValueFrom(this.http.head(`${environment.apiBase}${endpoint}`, options));
    }

    get(endpoint: string): Promise<any> {
        return lastValueFrom(this.http.get(`${environment.apiBase}${endpoint}`));
    }

    post(endpoint: string, data?: any): Promise<any> {
        return lastValueFrom(this.http.post(`${environment.apiBase}${endpoint}`, data));
    }

    put(endpoint: string, data?: any): Promise<any> {
        return lastValueFrom(this.http.put(`${environment.apiBase}${endpoint}`, data));
    }

    delete(endpoint: string): Promise<any> {
        return lastValueFrom(this.http.delete(`${environment.apiBase}${endpoint}`));
    }
}
