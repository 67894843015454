<app-topbar [title]="header"></app-topbar>
<ion-content>
    <app-ticket-card
        *ngIf="ticket"
        [data]="ticket"
        [isTokenLogin]="true"
        [tabsDoRedirect]="false"
        (tabClicked)="tabClicked($event)"
        (appointmentStatus)="setAppointmentStatus($event)"></app-ticket-card>

    <div #notesRef></div>
    <app-ticket-notes-card
        #notes
        *ngIf="ticket?.uid"
        [ticketState]="ticket.status"
        [ticketId]="ticket.id"
        [open]="hasUnreadNotes"
        [managers]="ticket?.propertyManagerIds || []"
        [ticket]="ticket"></app-ticket-notes-card>

    <app-ticket-appointment-card
        *ngIf="(ticket?.appointments && ticket.appointments.length) || ticket?.pendingAppointment"
        [data]="{
            appointments: ticket.appointments || [],
            presentPerson: ticket.presentedPerson,
            canEnter: ticket.canEnter,
            thirdPersonName: ticket.thirdPersonName,
            thirdPersonPhone: ticket.thirdPersonPhone,
            ticketNo: ticket.ticketNo,
            pendingAppointment: ticket.pendingAppointment,
        }"></app-ticket-appointment-card>

    <div #appointmentsRef></div>
    <app-ticket-appointments-card
        #appointments
        *ngIf="ticket?.flatId && ticket.status !== 'INIT'"
        [data]="ticket"></app-ticket-appointments-card>

    <app-ticket-appointment-service-provider
        *ngIf="ticket?.offers && wasTicketInState('CM_DATE_SET') && ticket.offers[getKeys(ticket.offers)] as offers"
        [offers]="offers"
        [ticketNo]="ticket.ticketNo">
    </app-ticket-appointment-service-provider>
    <div #imagesRef></div>
    <app-ticket-pictures-card
        *ngIf="
            ticket?.documents.imgs?.length > 0 ||
            ticket?.documents.pdfs?.length > 0 ||
            (ticket?.uid === ticket?.user?.id && ticket?.status === 'INIT')
        "
        #images
        [images]="ticket?.documents.imgs"
        [pdfs]="ticket?.documents.pdfs"
        [ticketStatus]="ticket?.status"
        (documentsChanged)="updateImages($event)"
        (imageDeleted)="deleteImage($event)">
    </app-ticket-pictures-card>

    <app-ticket-rating-card
        *ngIf="ticket?.id"
        [ticketId]="ticket.id"
        [managerName]="ticket?.manager?.firstname + ' ' + ticket?.manager?.lastname"
        [serviceProviderName]="providerName">
    </app-ticket-rating-card>

    <app-ticket-manager-card
        *ngIf="ticket?.manager"
        [data]="{
            manager: ticket.manager,
            propertyManagerIds: ticket.propertyManagerIds,
            ticketNo: ticket.ticketNo,
        }"></app-ticket-manager-card>

    <app-ticket-contact-card
        *ngIf="ticket?.contact && ticket?.flatId"
        [ticketContact]="ticket.contact"
        [ticketFrom]="ticket?.from"></app-ticket-contact-card>
</ion-content>

<ion-footer
    *ngIf="
        (ticket?.flatId &&
            !wasTicketInState('CM_DATE_SET') &&
            !(ticket?.status === 'FINISHED' || ticket?.status === 'CANCELED')) ||
        canFinish()
    ">
    <ion-toolbar>
        <ion-row>
            <ion-col
                *ngIf="
                    !(ticket?.completed && ticket?.completed.type) &&
                    !wasTicketInState('CM_DATE_SET') &&
                    !(ticket?.status === 'FINISHED' || ticket?.status === 'CANCELED')
                ">
                <ion-button color="danger" expand="block" (click)="presentStornoModal()">
                    {{ 'general.reverse' | translate }}</ion-button
                >
            </ion-col>
            <ion-col *ngIf="canFinish()">
                <ion-button color="success" expand="block" (click)="presentRatingModal()">
                    {{ 'ticket.detail-finish' | translate }}
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-toolbar>
</ion-footer>
