import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../../models/user';

@Component({
    selector: 'app-user-list-item',
    templateUrl: './user-list-item.component.html',
    styleUrls: ['./user-list-item.component.scss'],
})
export class UserListItemComponent {
    @Input() user: User;
    @Input() hasLines: boolean;
    @Output() onClick = new EventEmitter<User>();
    @Input() checked: boolean;
    @Input() showContactInformation: boolean;

    onClickedUser(user) {
        this.onClick.emit(user);
    }
}
