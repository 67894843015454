import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserService } from '../services/user/user.service';
import { LegalDocumentsService } from '../services/legal-documents/legal-documents.service';
import { NamespaceService } from '../services/namespace/namespace.service';
import { LegalDocumentsAcceptModalComponent } from '../modals/legal-documents/legal-documents-accept-modal.component';
import { ModalController } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class VoteGuard {
    constructor(
        private userService: UserService,
        private router: Router,
        private namespaceService: NamespaceService,
        private legalDocumentService: LegalDocumentsService,
        private modalController: ModalController
    ) {}

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        const user = this.userService?.user;
        const fallbackURL = route.data?.fallbackURL || 'main';

        if (!user || user.type !== 'owner') {
            if (fallbackURL) {
                this.router.navigate([fallbackURL]);
            }
            return false;
        }

        const [domain, legalDocuments]: any[] = await Promise.all([
            this.namespaceService.getDomain(),
            this.legalDocumentService.getLatestLegalDocuments(),
        ]);

        const hasAcceptedEvote =
            user?.legalDocuments[domain]?.evoteTermsOfUse?.version === legalDocuments[domain]?.evoteTermsOfUse?.version;

        if (!hasAcceptedEvote) {
            if (this.legalDocumentService.legalDocumentsModalRef) {
                await this.legalDocumentService.legalDocumentsModalRef.dismiss();
                this.legalDocumentService.legalDocumentsModalRef = null;
            }

            this.legalDocumentService.legalDocumentsModalRef = await this.modalController.create({
                component: LegalDocumentsAcceptModalComponent,
                componentProps: {
                    excludedDocuments: ['privacyPolicy', 'userTermsOfUse'],
                    showOptional: false,
                },
                backdropDismiss: false,
                keyboardClose: false,
                cssClass: 'small-modal',
            });

            await this.legalDocumentService.legalDocumentsModalRef.present();

            const modalResult = await this.legalDocumentService.legalDocumentsModalRef.onDidDismiss();
            this.legalDocumentService.legalDocumentsModalRef = null;
            const accepted = modalResult?.data?.accepted || [];

            if (modalResult?.data?.acceptedLegalDocuments) {
                await this.legalDocumentService.setLegalDocumentsAccepted(
                    modalResult.data.acceptedLegalDocuments,
                    accepted
                );
                if (accepted.includes('evoteTermsOfUse')) {
                    return true;
                }
            }

            if (fallbackURL) {
                this.router.navigate([fallbackURL]);
            }
            return false;
        }

        return true;
    }
}
