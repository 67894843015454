<div
    *ngIf="ticketText?.value || edit"
    class="ion-text-left"
    [ngClass]="{
        'ion-margin-top': margin === 0 || margin === 2,
        'ion-margin-bottom': margin === 1 || margin === 2,
    }">
    <h4 class="text-bold">
        {{ title | translate }}
    </h4>
    <ion-spinner *ngIf="loading" name="dots"></ion-spinner>
    <ng-container *ngIf="!edit && !loading">
        <show-more [text]="language === originalText?.lang ? originalText?.value : ticketText?.value"></show-more>
        <original-text *ngIf="ticketText?.lang !== originalText?.lang" [text]="combinedText"></original-text>
    </ng-container>
    <ng-container *ngIf="edit">
        <ion-textarea
            [(ngModel)]="editedText"
            (click)="$event.stopPropagation()"
            class="editDescTextarea"></ion-textarea>
    </ng-container>
</div>
<div *ngIf="!ticketText?.value && !edit && !loading">
    <ng-content></ng-content>
</div>
