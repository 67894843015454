import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
    name: 'customDate',
})
export class CustomDatePipe implements PipeTransform {
    constructor(private translate: TranslateService) {}

    transform(date: string) {
        const dateAsMoment = moment(date);
        let day: string;

        switch (dateAsMoment.day()) {
            case 1:
                day = this.translate.instant('days.monday');
                break;

            case 2:
                day = this.translate.instant('days.tuesday');
                break;

            case 3:
                day = this.translate.instant('days.wednesday');
                break;

            case 4:
                day = this.translate.instant('days.thursday');
                break;

            case 5:
                day = this.translate.instant('days.friday');
                break;

            case 6:
                day = this.translate.instant('days.saturday');
                break;

            case 7:
                day = this.translate.instant('days.sunday');
                break;

            default:
                '';
                break;
        }

        return day + ' ' + dateAsMoment.format('DD.MM.YYYY HH:mm');
    }
}
