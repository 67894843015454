import { NgModule } from '@angular/core';
import { EmbeddedLinksComponent } from './embedded-links.component';
import { ComponentsSharedModule } from '../../components/components.shared.module';
import { TopbarComponentModule } from '../../components/molecules/topbar/topbar.component.module';

@NgModule({
    declarations: [EmbeddedLinksComponent],
    exports: [EmbeddedLinksComponent],
    imports: [ComponentsSharedModule, TopbarComponentModule],
})
export class EmbeddedLinksModule {}
