import { UserService } from 'src/app/services/user/user.service';
import { Component, Input, OnDestroy, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Ticket } from '../../../models/ticket';
import { TicketsService } from '../../../services/tickets/tickets.service';
import { FlatService } from '../../../services/flat/flat.service';
import { TranslateService } from '@ngx-translate/core';
import { TicketNode } from '../../../models/ticket-node';
import { PropertyService } from '../../../services/property/property.service';
import { Property } from '../../../models/property';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { TicketStatus } from '../../../models/ticket-status.enum';
import { LoadingController } from '@ionic/angular';
import { PopupService } from 'src/app/services/popup/popup.service';
import { TicketTextComponent } from '../ticket-text/ticket-text.component';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { TicketAppointment, TicketAppointmentStatus, TicketAppointmentType } from '../../../models/ticket-appointment';
import * as moment from 'moment';
import { TextService } from 'src/app/services/text/text.service';
import { Flat } from '../../../models/flat';
import { DEFAULT_PROFILE_PICTURE } from '../../../const/app.const';

@Component({
    selector: 'app-ticket-card',
    templateUrl: './ticket-card.component.html',
    styleUrls: ['./ticket-card.component.scss'],
})
export class TicketCardComponent implements OnInit, OnDestroy {
    @ViewChild('ticketText') ticketText: TicketTextComponent;
    @Output() tabClicked: EventEmitter<string> = new EventEmitter<string>();
    @Output() appointmentStatus: EventEmitter<any> = new EventEmitter<any>();
    @Output() isEditing: EventEmitter<string | null> = new EventEmitter<string | null>();
    @Input() tabsDoRedirect = true;
    @Input() data: Ticket;
    @Input() isTokenLogin = false;
    public DEFAULT_PROFILE_PICTURE = DEFAULT_PROFILE_PICTURE;
    languageSubscription;
    displayedHashtags: TicketNode[] = [];
    managerProfilePicture: string;
    statusInfo: string;
    ticketNotesCount = 0;
    isEditingDesc = false;
    editDescText = '';
    managerAppointmentSubscription: Subscription;
    ticketNoteSubscription: Subscription;
    managerAppointmentStatus;
    TicketAppointmentStatus = TicketAppointmentStatus;
    TicketAppointmentType = TicketAppointmentType;
    activeAppointmentPreviewList: TicketAppointment[] = [];
    propertyTicketCreator: string = '';

    constructor(
        private ticketService: TicketsService,
        private flatService: FlatService,
        private translate: TranslateService,
        private propertyService: PropertyService,
        private profileService: ProfileService,
        private userService: UserService,
        private loadingController: LoadingController,
        private popupService: PopupService,
        private router: Router,
        private textService: TextService
    ) {}

    async ngOnInit() {
        if (this.data) {
            this.data.user = await this.userService.getUser();
            this.managerProfilePicture = await this.getManagerProfilePicture();
            this.statusInfo = this.ticketService.getCurrentInfoState(this.data);
            this.propertyTicketCreator = await this.getCreatorById();
            const property: Property = this.propertyService.getProperty(this.data.propertyId);
            const flat: Flat = this.data.flatId ? this.flatService.getFlat(this.data.flatId) : null;
            this.ticketNoteSubscription = this.ticketService
                .getTicketNoteObservable(this.data.id, 1)
                .subscribe(async () => {
                    this.ticketNotesCount = (await this.ticketService.countTicketNotes(this.data.id)).total;
                    this.data.hasUnreadNotes = await this.ticketService.hasUnreadNotes(this.data.id);
                });
            this.managerAppointmentSubscription = this.ticketService
                .getTicketAppointmentsObservable(this.data.id)
                .subscribe((result) => {
                    if (result?.length) {
                        for (const appointment of result) {
                            appointment.createdOn = appointment.createdOn.toDate();
                            appointment.updatedOn = appointment.updatedOn.toDate();
                            for (const option of appointment.recommendations) {
                                option.start = option.start?.toDate();
                                option.end = option.end?.toDate();
                            }
                            for (const option of appointment.accepted) {
                                option.start = option.start?.toDate();
                                option.end = option.end?.toDate();
                            }

                            if (appointment.accepted.length) {
                                appointment.hasStarted = moment(appointment.accepted[0].start).isBefore(moment());
                            }
                        }

                        const appointments = result.sort((a, b) =>
                            a.createdOn.getTime() < b.createdOn.getTime() ? 1 : -1
                        );

                        // Filters and sorts appointment preview list on ticket card
                        this.activeAppointmentPreviewList = appointments
                            .filter(
                                (f) =>
                                    f.status === TicketAppointmentStatus.ACCEPTED &&
                                    f.status !== TicketAppointmentStatus.ENDED
                            )
                            .sort((a, b) => (a.accepted[0].start.getTime() > b.accepted[0].start.getTime() ? 1 : -1));

                        // Counts tickets status of array
                        const mergedAppointments = [];
                        const administrationAppointments = appointments.filter(
                            (f) => f.type === TicketAppointmentType.ADMINISTRATION
                        );
                        const providerAppointments = appointments.filter(
                            (f) => f.type === TicketAppointmentType.PROVIDER
                        );
                        if (administrationAppointments.length) {
                            mergedAppointments.push(administrationAppointments[0]);
                        }
                        if (providerAppointments.length) {
                            mergedAppointments.push(providerAppointments[0]);
                        }

                        // Merges tickets of different tickets to one icon
                        if (mergedAppointments.filter((f) => f.status === TicketAppointmentStatus.OPEN).length) {
                            this.managerAppointmentStatus = TicketAppointmentStatus.OPEN;
                        } else if (
                            mergedAppointments.filter((f) => f.status === TicketAppointmentStatus.DECLINED).length
                        ) {
                            this.managerAppointmentStatus = TicketAppointmentStatus.DECLINED;
                        } else if (
                            mergedAppointments.filter((f) => f.status === TicketAppointmentStatus.CANCELED).length
                        ) {
                            this.managerAppointmentStatus = TicketAppointmentStatus.CANCELED;
                        } else if (
                            mergedAppointments.filter((f) => f.status === TicketAppointmentStatus.ACCEPTED).length ||
                            mergedAppointments.filter((f) => f.status === TicketAppointmentStatus.ENDED).length
                        ) {
                            this.managerAppointmentStatus = TicketAppointmentStatus.ACCEPTED;
                        } else {
                            this.managerAppointmentStatus = null;
                        }
                    } else {
                        this.managerAppointmentStatus = null;
                    }
                    this.appointmentStatus.emit({
                        id: this.data.id,
                        managerAppointmentStatus: this.managerAppointmentStatus,
                    });
                });

            this.languageSubscription = this.userService.userLanguage$.subscribe(() => {
                const tags = [];

                if (property?.name) {
                    tags.push({
                        text: `${property.name}`,
                    });
                }

                if (property?.street && property?.city) {
                    tags.push({
                        text: `${property.street}, ${property.city}`,
                    });
                }

                if (flat) {
                    tags.push({ text: `general.flat-types.${flat.type}` });

                    if (flat.name) {
                        tags.push({
                            text: flat.name,
                        });
                    }

                    if (flat.floor) {
                        tags.push({
                            text: `${flat.floor}`,
                        });
                    }
                }

                for (const tag of tags) {
                    if (!tag.text && tag.textId) {
                        tag.text = this.textService.texts$.value[tag.textId];
                    }
                }

                this.displayedHashtags = tags;
                this.displayedHashtags.push(...this.data.hashtags);
                this.alignHashtags(this.displayedHashtags);
            });

            if (this.data.statusManager[TicketStatus.CM_DATE_SET].value && this.data.status === TicketStatus.OFFERS) {
                this.data = await this.ticketService.setOffers(this.data);
            }
        }
    }

    private alignHashtags(hashtags: any) {
        for (const hashtag of hashtags) {
            if (!hashtag.text && hashtag.textId) {
                hashtag.text = this.textService.texts$.value[hashtag.textId];
            }
        }
    }

    async getManagerProfilePicture() {
        const manager = await this.profileService.getManagerProfile(this.data.propertyManagerIds[0]);
        return manager && manager.profilePicture ? manager.profilePicture : DEFAULT_PROFILE_PICTURE;
    }

    public getKeys(data: any) {
        return Object.keys(data)[0];
    }

    setDescEdit(event, value: boolean) {
        event.stopPropagation();
        this.isEditingDesc = value;
        if (value) {
            this.isEditing.emit(this.data.id);
        } else {
            this.isEditing.emit(null);
        }
    }

    async updateDescription(event) {
        this.setDescEdit(event, false);
        const loader = await this.loadingController.create();
        await loader.present();
        try {
            await this.ticketService.updateTicket({
                id: this.data.id,
                description: this.ticketText.getEditedText(),
            });
            this.data = await this.ticketService.getTicketTextsByTicketAndKey(this.data);
        } catch (e) {
            this.popupService.showToast(this.translate.instant('general.error'), true);
        }
        await loader.dismiss();
    }

    async getCreatorById(): Promise<string> {
        if (this.data.creator.type === 'manager') {
            const manager = await this.profileService.getManagerProfile(this.data.creator.id);
            return `${manager.firstname} ${manager.lastname}`;
        } else if (this.data.creator.type === 'user') {
            return `${this.data.contact.name}`;
        } else {
            return '';
        }
    }

    showTicketDetails(event, tab) {
        if (this.tabsDoRedirect) {
            event.stopPropagation();
            this.router.navigateByUrl(`main/tickets/detail/${this.data.id}`, {
                state: {
                    tab,
                },
            });
        } else {
            this.tabClicked.emit(tab);
        }
    }

    ngOnDestroy() {
        if (this.managerAppointmentSubscription) {
            this.managerAppointmentSubscription.unsubscribe();
        }
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
        if (this.ticketNoteSubscription) {
            this.ticketNoteSubscription.unsubscribe();
        }
    }
}
