import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-document-search',
    templateUrl: './document-search.component.html',
    styleUrls: ['./document-search.component.scss'],
})
export class DocumentSearchComponent {
    constructor(private modalController: ModalController) {}

    documentSelected(document) {
        this.dismiss(document);
    }

    dismiss(data?: any) {
        this.modalController.dismiss(data);
    }
}
