import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PhotoViewerComponent } from 'src/app/modals/photo-viewer/photo-viewer.component';

@Component({
    selector: 'app-image-gallery',
    templateUrl: './image-gallery.component.html',
    styleUrls: ['./image-gallery.component.scss'],
})
export class ImageGalleryComponent implements OnInit {
    @Output() imageDeleted: EventEmitter<number> = new EventEmitter<number>();
    @Input() images: any[];
    @Input() imageDeleteAllowed = true;
    expanded = false;
    colsize;

    constructor(public modalController: ModalController) {}

    ngOnInit() {
        if (this.images.length > 1) {
            this.colsize = 6;
        } else {
            this.colsize = 12;
        }
    }

    deleteImageByIndex(index: number) {
        this.imageDeleted.emit(index);

        if (this.images.length <= 3) {
            this.expanded = false;
        }
    }

    async openPhotoViewer(index: number) {
        const data = {
            images: this.images,
            index,
            imageDeleteAllowed: this.imageDeleteAllowed,
        };

        const modal = await this.modalController.create({
            component: PhotoViewerComponent,
            componentProps: data,
        });

        await modal.present();
        const dismissed = await modal.onDidDismiss();

        if (dismissed.data || dismissed.data === 0) {
            this.deleteImageByIndex(dismissed.data);
        }
    }

    loadedImage(index: number) {
        this.images[index].loaded = true;
    }
}
