import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { doc, docData, Firestore } from '@angular/fire/firestore';
import { Flat } from '../../models/flat';
import { FlatService } from '../flat/flat.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { Property } from '../../models/property';
import { BaseDataService } from '../../util/base-data-service';
import { map } from 'rxjs/operators';
import { arrayRemoveDuplicates } from '../../util/util';
import { ApiService } from '../api/api.service';
import { ObjectDisplayFormatPipe } from 'src/app/pipes/object-display-format/object-display-format';
import { BlackboardMessageCreatePermission } from '../../models/blackboard-message-create-permission';

@Injectable({
    providedIn: 'root',
})
export class PropertyService implements BaseDataService {
    private flatSub: Subscription = new Subscription();
    private propertySub: Subscription = new Subscription();
    properties$: BehaviorSubject<Property[]> = new BehaviorSubject<Property[]>(null);

    constructor(
        private userService: UserService,
        private firestore: Firestore,
        private flatService: FlatService,
        private http: HttpClient,
        private apiService: ApiService,
        private displayFormat: ObjectDisplayFormatPipe
    ) {}

    initialize() {
        this.flatSub = this.flatService.flats$.subscribe((newFlats: Flat[]) => {
            if (newFlats) {
                // we only want each property once and only need the property ids, therefore we can just filter out all
                // flats with duplicate propertyIds in this array.
                const uniquePropertyFlats: any[] = arrayRemoveDuplicates(newFlats, 'propertyId');

                const propertyObservables = uniquePropertyFlats.map((flat) =>
                    this.getObservableForProperty(flat.propertyId)
                );

                this.propertySub = combineLatest(propertyObservables).subscribe((properties: Property[]) => {
                    this.properties$.next(properties);
                });
            }
        });
    }

    async getPropertyById(id): Promise<any> {
        return await this.http.get(`${environment.apiBase}properties/${id}`).toPromise();
    }

    async getPropertiesByIds(ids: string[]) {
        return (await this.apiService.get(`properties?id=${ids.join(',')}`)) as any[];
    }

    async getManager(managerId: string): Promise<any> {
        return await this.http.get(`${environment.apiBase}managers/${managerId}`).toPromise();
    }

    getObservableForProperty(propertyId: string) {
        const propertyDocRef = doc(this.firestore, `ns/${this.userService.user.ns}/properties/${propertyId}`);
        return docData(propertyDocRef).pipe(
            map((propertyData) => {
                return propertyData as Property;
            })
        );
    }

    getProperties(): Property[] {
        return this.properties$.getValue() || [];
    }

    getMyFlatsWithProperties(): { property: Property; flats: Flat[] }[] {
        const flats = this.flatService.flats$.getValue().map((f) => this.displayFormat.formatFlat(f));
        const properties = this.properties$.getValue().map((p) => this.displayFormat.formatProperty(p));
        const ret = [];
        for (const property of properties) {
            ret.push({
                property,
                flats: flats.filter((flat) => flat.propertyId === property.id),
            });
        }
        return ret;
    }

    getProperty(propertyId) {
        return (this.properties$.getValue() || []).find((prop) => prop.id === propertyId);
    }

    isOwnerOfPropertyById(propertyId: string): boolean {
        return this.userService.user.ownedProperties.some((ownedProperty) => ownedProperty.id === propertyId);
    }

    terminate() {
        this.properties$.next(null);
        this.flatSub.unsubscribe();
        this.propertySub.unsubscribe();
    }

    getPropertyIdsWithBlackboardCreatePermission() {
        return this.properties$
            ?.getValue()
            ?.filter((property) => {
                const permission = property.blackboardCreatePermission;

                return (
                    permission === BlackboardMessageCreatePermission.ADMIN_USER_OWNER ||
                    (permission === BlackboardMessageCreatePermission.ADMIN_OWNER &&
                        this.userService.user.type === 'owner') ||
                    (permission === BlackboardMessageCreatePermission.ADMIN_USER &&
                        this.userService.user.type === 'tenant')
                );
            })
            .map((property) => property.id);
    }

    searchPropertyByAddress(address: string, ns: string) {
        return this.http.post(`${environment.apiBase}requests/searchAddress/${ns}`, {
            queryString: address,
        });
    }
}
