import { LegalDocumentsAcceptModalComponent } from './modals/legal-documents/legal-documents-accept-modal.component';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { AuthService } from './services/auth/auth.service';
import { UserService } from './services/user/user.service';
import { DataService } from './services/data/data.service';
import { LanguageService } from './services/language/language.service';
import { ThemeService } from './services/theme/theme.service';
import { Timeout } from './util/timeout';
import { Network } from '@capacitor/network';
import { PopupService } from './services/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { UpdateService } from './services/ota/update.service';
import { ApiService } from './services/api/api.service';
import { combineLatest, Subscription } from 'rxjs';
import { TextService } from './services/text/text.service';
import { PropertyService } from './services/property/property.service';
import { FlatService } from './services/flat/flat.service';
import { Title } from '@angular/platform-browser';
import { LegalDocumentsService } from './services/legal-documents/legal-documents.service';
import { BlackboardService } from './services/blackboard/blackboard.service';
import { NamespaceSelectionService } from './services/namespace-selection/namespace-selection.service';
import { OriginService } from './services/origin/origin.service';
import { NamespaceService } from './services/namespace/namespace.service';
import { GroupService } from './services/group/group.service';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { App, AppInfo } from '@capacitor/app';
import { StatusBar, Style } from '@capacitor/status-bar';
import logger from 'loglevel';
import { HtmlReadyAlertComponent } from './modals/html-ready-alert/html-ready-alert.component';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    private initialRoute: string;
    private networkStatusListener: any;
    private subscriptions: Subscription[] = [];

    constructor(
        private platform: Platform,
        private navController: NavController,
        public auth: AuthService,
        private userService: UserService,
        private dataService: DataService,
        private languageService: LanguageService,
        private themeService: ThemeService,
        private popupService: PopupService,
        private translate: TranslateService,
        private location: Location,
        private updateService: UpdateService,
        private api: ApiService,
        private textService: TextService,
        private flatService: FlatService,
        private propertyService: PropertyService,
        private titleService: Title,
        private modalController: ModalController,
        private legalDocumentsService: LegalDocumentsService,
        private blackboardService: BlackboardService,
        private namespaceSelectionService: NamespaceSelectionService,
        private originService: OriginService,
        private namespaceService: NamespaceService,
        private groupService: GroupService
    ) {}

    async ngOnInit() {
        await this.platform.ready();
        if (Capacitor.isNativePlatform()) {
            try {
                await StatusBar.setOverlaysWebView({ overlay: false });
                await StatusBar.show();
            } catch (error) {
                logger.error('Error setting status bar style:', error);
            }
            this.updateService.initialize().catch(logger.error);
        }
        await this.initializeApp();
    }

    ngAfterViewInit() {
        if (!Capacitor.isNativePlatform()) {
            this.translate.onLangChange.subscribe(() => {
                this.titleService.setTitle(this.translate.instant('TAB_TITLE'));
            });
        }
    }

    async initializeApp() {
        this.initialRoute = this.location.path();
        let appInfo: AppInfo = { name: null, id: null, build: null, version: null };
        if (Capacitor.isNativePlatform()) {
            appInfo = await App.getInfo();
        }
        await this.originService.initialize(appInfo);

        if (this.initialRoute.startsWith('/landing')) {
            await this.languageService.initialize();
            return;
        }

        if (this.initialRoute.startsWith('/contact')) {
            const browserLang = navigator.language.substr(0, 2);
            this.translate.use(browserLang);
            await this.languageService.initialize();
            return;
        }

        await this.navController.navigateRoot('start');
        await Timeout(1700); // Wait for the splash screen to be shown
        const isLoggedIn = await this.auth.getCurrentUser();
        const accessToken = this.initialRoute?.includes('?accessToken=')
            ? this.initialRoute.split('?accessToken=')[1]
            : null;

        if (accessToken) {
            const token = await this.api.post('users/authenticateByAccessToken', { accessToken: accessToken });

            await this.auth.signIn(token);
            await this.dataService.initializeEssentialAppData();
            await this.languageService.initialize();
            await this.textService.initialize();
            await this.blackboardService.initialize();
            this.flatService.initialize();
            this.propertyService.initialize();
            this.groupService.initialize();
            const user = await this.userService.getUser();
            await this.userService.setLanguage(user.language || this.translate.currentLang, true);

            if (user.language) {
                this.translate.use(user.language);
            }

            if (token?.blackboard) {
                await this.navController.navigateRoot(`/main/blackboard?accessToken=${accessToken}`);
            } else if (this.initialRoute.includes('guest')) {
                await this.navController.navigateRoot(this.initialRoute);
            } else {
                await this.navController.navigateRoot(`/main/guest?accessToken=${accessToken}`);
            }
        } else {
            try {
                if (isLoggedIn && isLoggedIn.value !== null) {
                    await new Promise((resolve) => {
                        const sub = this.auth.tokenLogin$.subscribe(async (isTokenLogin) => {
                            if (isTokenLogin) {
                                await this.auth.logout();
                                await this.goToLogin();
                            }
                            resolve(null);
                        });
                    });
                    await this.namespaceSelectionService.handleReAuth();
                    await this.namespaceSelectionService.initAfterLogin();
                    await this.goToApp(this.initialRoute);
                } else {
                    await this.goToLogin();
                }
            } catch (err) {
                const networkStatus = await Network.getStatus();
                if (Capacitor.isNativePlatform() && networkStatus.connected) {
                    await this.navController.navigateRoot('start');
                    await this.startNetworkListener();
                } else {
                    await this.goToLogin();
                }
            } finally {
                await SplashScreen.hide();
            }
        }
        await this.showLegalDocumentsModal();
    }

    private async startNetworkListener() {
        const offlineToast = await this.popupService.showToast(
            'Offline',
            true,
            { duration: -1 } // permanent
        );
        this.networkStatusListener = Network.addListener('networkStatusChange', async (status) => {
            logger.info('Network status changed', status);

            if (status.connected) {
                await Timeout(1000); // wait for firebase to connect
                await offlineToast.dismiss();

                // Remove the listener once it's no longer needed
                this.networkStatusListener.remove();

                await this.goToLogin();
            }
        });
    }

    /**
     * Check if currently logged in user has accepted the latest legal documents and displaying a popup
     *
     */
    private async showLegalDocumentsModal() {
        this.subscriptions.push(
            combineLatest([
                this.legalDocumentsService.getLatestLegalDocumentsObservable(),
                this.userService.user$.asObservable(),
                this.dataService.appDataInitialized$.asObservable(),
            ]).subscribe(async ([legalDocuments, user, appDataInitialized]) => {
                const domain = await this.namespaceService.getDomain();
                const latestLegalDocuments = legalDocuments[domain];

                if (latestLegalDocuments && user && appDataInitialized) {
                    const documentNames = ['privacyPolicy', 'userTermsOfUse'];

                    // per default all documents are required to be accepted; exclusions are possible with this array
                    const excludedDocuments = [];

                    // check if the user has accepted the latest versions of the legal documents
                    for (const documentName of documentNames) {
                        if (
                            user?.legalDocuments &&
                            user?.legalDocuments[domain] &&
                            user?.legalDocuments[domain][documentName]?.version ===
                                latestLegalDocuments[documentName]?.version
                        ) {
                            excludedDocuments.push(documentName);
                        }
                    }

                    // Check if there is at least one legal document to accept
                    if (excludedDocuments.length < documentNames.length) {
                        if (this.legalDocumentsService.legalDocumentsModalRef) {
                            // close previous dialog if there is already one opened
                            await this.legalDocumentsService.legalDocumentsModalRef.dismiss();
                            this.legalDocumentsService.legalDocumentsModalRef = null;
                        }

                        this.legalDocumentsService.legalDocumentsModalRef = await this.modalController.create({
                            component: LegalDocumentsAcceptModalComponent,
                            componentProps: {
                                excludedDocuments, // exclude given documents
                            },
                            backdropDismiss: false,
                            keyboardClose: false,
                            cssClass: 'small-modal',
                        });

                        await this.legalDocumentsService.legalDocumentsModalRef.present();

                        // get result of modal after it was closed
                        const modalResult = await this.legalDocumentsService.legalDocumentsModalRef.onDidDismiss();
                        this.legalDocumentsService.legalDocumentsModalRef = null;

                        if (modalResult?.data?.acceptedLegalDocuments) {
                            await this.legalDocumentsService.setLegalDocumentsAccepted(
                                modalResult.data.acceptedLegalDocuments,
                                modalResult?.data?.accepted || null
                            );
                        }
                    }
                }
            })
        );
    }

    private async goToLogin() {
        await this.languageService.initialize();
        await this.themeService.setTheme();
        if (!this.translate.currentLang) {
            const browserLang = navigator.language.substr(0, 2);
            this.translate.use(browserLang);
        }

        await this.navController.navigateRoot(this.initialRoute.startsWith('/login?') ? this.initialRoute : 'login');
    }

    private async goToApp(targetRoute) {
        if (!targetRoute || targetRoute === '/start' || targetRoute === '/login' || targetRoute.includes('guest')) {
            await this.navController.navigateRoot('/main');
        } else {
            await this.navController.navigateRoot(targetRoute);
        }

        try {
            const announcements = await this.namespaceService.getAnnouncements(null);

            if (
                announcements &&
                announcements.active &&
                (!announcements.endDate || announcements.endDate > new Date())
            ) {
                const modal = await this.modalController.create({
                    component: HtmlReadyAlertComponent,
                    componentProps: { translatedMessage: announcements.body, title: announcements.title, info: true },
                    cssClass: 'pop-up custom-modal',
                    backdropDismiss: false,
                });

                await modal.present();
            }
        } catch (error) {
            logger.error('ERROR', error);
            return;
        }
    }

    /**
     * Clear all subscriptions before destroying component to prevent unused open subscriptions
     *
     */
    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            if (subscription) {
                subscription.unsubscribe();
            }
        }
    }
}
