<ion-card class="accent-border">
    <ion-card-header>
        <ion-row class="ion-no-padding">
            <ion-col class="user ion-no-padding" *ngIf="data.flatId">
                <h6 *ngIf="!data.contact.type.includes('other')">
                    {{
                        data.contact.name
                            ? data.contact.name
                            : data.user?.firstname
                              ? data.user.firstname + ' ' + data.user?.lastname
                              : data.user?.lastname
                    }}
                </h6>
                <h6 *ngIf="data.contact.type.includes('other')">
                    {{ data.user?.firstname }} {{ data.user?.lastname }}
                </h6>
                <span> {{ data.createdOn | localeDate }} </span>
            </ion-col>
            <ion-col *ngIf="!data.flatId" class="user ion-no-padding">
                <div class="ion-padding-left info-container">
                    <h6>
                        {{ propertyTicketCreator }}
                    </h6>

                    <span> {{ data.createdOn | localeDate }} </span>
                    <span class="type-container">{{ 'ticket-info.property-ticket' | translate }}</span>
                </div>
            </ion-col>
            <ion-icon name="chevron-forward-outline"></ion-icon>
            <ion-col class="manager ion-no-padding">
                <div class="display-flex">
                    <h6 *ngIf="data.manager">
                        {{ data.manager.firstname }}
                        {{ data.manager.lastname }}
                    </h6>
                    <ion-avatar slot="end">
                        <img [src]="managerProfilePicture" />
                    </ion-avatar>
                </div>
            </ion-col>
        </ion-row>
    </ion-card-header>
    <ion-card-content class="separator-top">
        <ion-row class="ticket-info ion-margin-bottom">
            <ion-col class="ion-no-padding ticketno-label">
                <ion-row class="ion-align-items-start">
                    <ion-label class="text-bold ticket-no ion-text-left" color="primary"
                        >#{{ data.ticketNo }}</ion-label
                    >
                </ion-row>
            </ion-col>
            <ion-col class="ion-no-padding">
                <ion-row class="w-100 ion-justify-content-end" lines="none">
                    <ion-col size="11" class="ion-text-right ion-no-padding">
                        <ion-label
                            [color]="data.status === 'CANCELED' || data.status === 'DECLINED' ? 'danger' : 'primary'">
                            {{ 'ticket.state-' + statusInfo | translate }}
                        </ion-label>
                    </ion-col>
                    <ion-col size="1" class="state-icon">
                        <ion-icon
                            [color]="data.status === 'CANCELED' || data.status === 'DECLINED' ? 'danger' : 'primary'"
                            name="information-circle"></ion-icon>
                    </ion-col>
                </ion-row>
                <ion-col class="ion-no-padding" *ngIf="data.completed && data.completed?.comment">
                    <ion-row class="ion-justify-content-end padding-trailing">
                        <h4 class="text-bold">
                            {{ 'cancelCommentHeading' | translate }}
                        </h4>
                    </ion-row>
                    <ion-row class="ion-justify-content-end padding-trailing">
                        {{ data.completed.comment | customTranslation }}
                    </ion-row>
                </ion-col>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="isTokenLogin && data?.completed?.comment" class="finish-comment">
            <show-more
                [text]="data?.completed?.comment | customTranslation"
                [class.danger]="data.status === 'CANCELED' || data.status === 'DECLINED'"></show-more>
            <original-text
                [text]="data?.completed?.comment"
                [class.danger]="data.status === 'CANCELED' || data.status === 'DECLINED'"></original-text>
        </ion-row>

        <div *ngIf="activeAppointmentPreviewList.length" class="ion-margin-bottom">
            <ng-container *ngFor="let activeAppointment of activeAppointmentPreviewList">
                <ion-row class="ion-justify-content-end ion-align-items-center appointment-preview">
                    <ion-avatar *ngIf="activeAppointment.type === TicketAppointmentType.ADMINISTRATION">
                        <img [src]="data.manager.profilePicture || DEFAULT_PROFILE_PICTURE" />
                    </ion-avatar>
                    <ion-icon
                        *ngIf="activeAppointment.type === TicketAppointmentType.PROVIDER"
                        size="small"
                        name="construct"></ion-icon>
                    <ion-icon name="chevron-forward-outline"></ion-icon>
                    {{ activeAppointment.accepted[0]?.start | date: 'dd.MM.y, HH:mm - ' }}

                    {{ activeAppointment.accepted[0]?.end | date: 'HH:mm' }}
                </ion-row>
            </ng-container>
        </div>

        <app-ticket-text [ticketId]="data.id" key="description" [edit]="isEditingDesc" #ticketText></app-ticket-text>

        <ion-row
            *ngIf="
                ticketNotesCount ||
                data.documents?.imgs?.length ||
                (data.creator.id === data.user?.id && data.status === 'INIT') ||
                managerAppointmentStatus ||
                (data.origin && activeAppointmentPreviewList.length < 1)
            "
            class="badge-button-wrapper">
            <ion-button
                *ngIf="data.documents?.pdfs?.length"
                fill="clear"
                class="badge-button left"
                (click)="showTicketDetails($event, 'images')">
                <ion-icon name="document-attach-outline"> </ion-icon>
            </ion-button>
            <ion-button
                *ngIf="data.documents?.imgs?.length"
                fill="clear"
                class="badge-button left"
                (click)="showTicketDetails($event, 'images')">
                <ion-icon name="image"> </ion-icon>
            </ion-button>
            <ion-button
                *ngIf="ticketNotesCount"
                fill="clear"
                class="badge-button left"
                (click)="showTicketDetails($event, 'notes')">
                <ion-icon name="chatbox-ellipses"> </ion-icon>
                <ion-badge class="badge" [color]="data.hasUnreadNotes ? 'danger' : 'success'">
                    {{ data.hasUnreadNotes ? '!' : '✓' }}
                </ion-badge>
            </ion-button>
            <ion-button
                *ngIf="managerAppointmentStatus && (!isThirdParty || thirdPartyUrl)"
                fill="clear"
                class="badge-button left"
                (click)="showTicketDetails($event, 'appointments')">
                <ion-icon name="today-outline" color="primary"></ion-icon>
                <ion-badge
                    *ngIf="managerAppointmentStatus"
                    class="badge"
                    [color]="
                        [TicketAppointmentStatus.ACCEPTED, TicketAppointmentStatus.ENDED].includes(
                            managerAppointmentStatus
                        )
                            ? 'success'
                            : 'danger'
                    ">
                    <ng-container [ngSwitch]="managerAppointmentStatus">
                        <ng-container *ngSwitchCase="TicketAppointmentStatus.ACCEPTED"> ✓</ng-container>
                        <ng-container *ngSwitchCase="TicketAppointmentStatus.ENDED"> ✓</ng-container>
                        <ng-container *ngSwitchCase="TicketAppointmentStatus.CANCELED">X</ng-container>
                        <ng-container *ngSwitchDefault>!</ng-container>
                    </ng-container>
                </ion-badge>
            </ion-button>
            <ion-col
                *ngIf="data.creator.id === data.user?.id && data.status === 'INIT'"
                class="ion-justify-content-end ion-no-padding">
                <ion-button
                    *ngIf="!isEditingDesc"
                    (click)="setDescEdit($event, true)"
                    fill="clear"
                    class="badge-button right">
                    <ion-icon name="create-outline"> </ion-icon>
                </ion-button>
                <ion-button
                    *ngIf="isEditingDesc"
                    (click)="setDescEdit($event, false)"
                    fill="clear"
                    class="badge-button right">
                    <ion-icon name="close-outline" color="danger"> </ion-icon>
                </ion-button>
                <ion-button
                    *ngIf="isEditingDesc"
                    (click)="updateDescription($event)"
                    fill="clear"
                    class="badge-button right">
                    <ion-icon name="checkmark-outline" color="success"> </ion-icon>
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-card-content>
    <ion-row class="ion-padding ion-align-items-start separator-top hashtags">
        <app-hashtag *ngFor="let hashtag of displayedHashtags" [text]="hashtag.text" [active]="false"> </app-hashtag>
    </ion-row>
</ion-card>
