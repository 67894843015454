import { Injectable } from '@angular/core';
import { TextObjAggregated } from '../../models/text-obj-aggregated';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { collection, collectionData, Firestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { TextObj } from '../../models/text-obj';
import { UserService } from '../user/user.service';
import { BaseDataService } from '../../util/base-data-service';

@Injectable({
    providedIn: 'root',
})
export class TextService implements BaseDataService {
    private sub: Subscription = new Subscription();
    texts$: BehaviorSubject<TextObjAggregated> = new BehaviorSubject<TextObjAggregated>({});

    constructor(
        private firestore: Firestore,
        private userService: UserService
    ) {}

    initialize() {
        return new Promise<void>((resolve) => {
            const collectionRef = collection(this.firestore, `ns/${this.userService.getNamespace()}/ticketTreeTexts`);
            this.sub = collectionData(collectionRef, { idField: 'id' })
                .pipe(
                    map((snaps) => {
                        const mappedTexts: TextObjAggregated = {};
                        for (const snap of snaps) {
                            mappedTexts[snap.id] = snap as TextObj;
                        }
                        return mappedTexts;
                    })
                )
                .subscribe((texts: TextObjAggregated) => {
                    this.texts$.next(texts);
                    resolve();
                });
        });
    }

    get texts(): Observable<TextObjAggregated> {
        return this.texts$.asObservable();
    }

    terminate() {
        this.sub.unsubscribe();
    }
}
