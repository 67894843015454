import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { FlatService } from '../flat/flat.service';
import { collection, doc, Firestore, getDoc, getDocs, query, where } from '@angular/fire/firestore';
import { NsUtil } from '../../util/ns';
import { combineLatest, from, Observable, of } from 'rxjs';
import { defaultIfEmpty, map, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import _ from 'lodash-es';
import { OwnershipAssembly } from '../../models/ownershipAssembly';
import { Checkpoint } from '../../models/checkpoint';
import { convertFirestoreDate } from '../../util/util';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api/api.service';
import { Checklist2Service } from '../checklist/checklist2.service';
import { ElasticService } from '../elastic/elastic.service';
import { PopupService } from '../popup/popup.service';
import { Router } from '@angular/router';
import { FirebaseWrapperService } from '../firebase-wrapper/firebase-wrapper.service';
import logger from 'loglevel';
import { QrcodeScannerComponent } from 'src/app/modals/qrcode-scanner/qrcode-scanner.component';
import { Camera, PermissionStatus } from '@capacitor/camera';
@Injectable({
    providedIn: 'root',
})
export class OwnershipAssemblyV2Service {
    constructor(
        private userService: UserService,
        private flatService: FlatService,
        private firestore: Firestore,
        private firebaseService: FirebaseWrapperService,
        private checklistService: Checklist2Service,
        private ns: NsUtil,
        private elastic: ElasticService,
        private http: HttpClient,
        private alertController: AlertController,
        private translate: TranslateService,
        private api: ApiService,
        private popupService: PopupService,
        private router: Router,
        private modalController: ModalController
    ) {}

    public getAllOwnershipAssembliesObservable(): Observable<OwnershipAssembly[]> {
        const user = this.userService.user;
        if (!user.assemblyV2Observable) {
            user.assemblyV2Observable = from(this.flatService.getFlatsByIds(user.ownedFlats)).pipe(
                switchMap((flats) => {
                    const propertyIds = this.getUniquePropertyIds(flats);
                    return this.getOwnershipAssemblies(propertyIds);
                }),
                switchMap((rawAssemblies: OwnershipAssembly[]) => this.processRawAssemblies(rawAssemblies)),
                switchMap((assemblies) => this.loadVoterDataForAssemblies(assemblies, user.id)),
                switchMap((assemblies: OwnershipAssembly[]) => this.finalizeAssemblies(assemblies))
            );

            return user.assemblyV2Observable;
        }
        return user.assemblyV2Observable;
    }

    getAssemblyObservable(assemblyId: string) {
        return this.firebaseService.docDataObservable(
            `ns/${this.userService.getNamespace()}/ownershipAssembliesV2`,
            assemblyId
        );
    }

    async getRawAssembly(assemblyId: string) {
        return this.firebaseService.docData(`ns/${this.userService.getNamespace()}/ownershipAssembliesV2`, assemblyId);
    }

    async vote(
        ownershipAssemblyId: string,
        checkpointId: string,
        ownerId: string,
        accepted: boolean,
        value = null,
        delegateToId: string = null,
        delegateToModel: string = null,
        reset: boolean = null,
        customData: any = null,
        disableDelegations = false,
        representiveVote = false
    ) {
        return await this.http
            .post(
                `${environment.apiBase}ownershipAssembliesV2/${ownershipAssemblyId}/checkpoint/${checkpointId}/vote`,
                {
                    ownershipAssemblyId,
                    checkpointId,
                    voteOfId: ownerId,
                    voteOfModel: 'owner',
                    accepted,
                    value,
                    delegateToId,
                    delegateToModel,
                    reset,
                    delegateCustomData: customData || null,
                    disableDelegations,
                    representiveVote,
                }
            )
            .toPromise();
    }

    async updateVoters(ownershipAssemblyId: string, voters: any[]) {
        return this.api.post(`ownershipAssembliesV2/${ownershipAssemblyId}/voters/bulk/update`, {
            voters: voters,
        });
    }

    async voteNewDelegation(
        assembly: OwnershipAssembly,
        delegateToId: string,
        delegateToModel: string,
        reset = null,
        delegateCustomData = null
    ) {
        return await this.api.post(`ownershipAssembliesV2/${assembly.id}/delegate`, {
            createVoteDtos: assembly.checkpoints.map((checkpoint: Checkpoint) => {
                return {
                    ownershipAssemblyId: assembly.id,
                    checkpointId: checkpoint.id,
                    voteOfId: this.userService.user.id,
                    voteOfModel: 'owner',
                    accepted: null,
                    value: null,
                    delegateToId: delegateToId,
                    delegateToModel: delegateToModel,
                    reset,
                    delegateCustomData: delegateCustomData || null,
                };
            }),
        });
    }

    async loadRepresentives(assemblyId: string) {
        const delegates = await this.api.get(
            `ownershipAssembliesV2/${assemblyId}/getDelegation/${this.userService.user.id}`
        );

        const ownerIds = Object.keys(delegates);
        const tenants = await this.userService.getTenants([...new Set(ownerIds)] as string[]);

        for (const id of ownerIds) {
            delegates[id].name = this.userService.getTenantFullName(tenants.find((tenant) => tenant.id === id));
        }

        return delegates;
    }

    async getOwnerOfPropertyAssembly(id: any[], type: string, searchString = '') {
        const searchResult = await this.elastic.searchUser(
            searchString,
            30,
            0,
            {
                'ownedProperties.keyword': id,
                type: type,
            },
            {},
            [],
            { 'id.keyword': 'desc' }
        );

        const ids: any[] = [...new Set(searchResult.ids)];
        const observables = ids.map((id) => {
            return this.firebaseService.docData(`users`, id);
        });
        return !observables.length
            ? of([])
            : combineLatest(...observables).pipe(
                  map((values: any) => {
                      const saneValues = values.filter((val) => Boolean(val));
                      for (const id of searchResult.ids) {
                          if (!saneValues.some((val) => val.id === id)) {
                              logger.warn(`Warning: Found id "${id}" via search but not in DB!!!`);
                          }
                      }
                      return saneValues;
                  })
              );
    }

    async togglePresence(ownershipAssemblyId: string) {
        return await this.http
            .post(
                `${environment.apiBase}ownershipAssembliesV2/${ownershipAssemblyId}/presentOwner/${this.userService.user.id}`,
                {}
            )
            .toPromise();
    }

    async getNeverVotedModal() {
        return await this.alertController.create({
            header: this.translate.instant('evote.alert.participate.title'),
            message: this.translate.instant('evote.alert.participate.message'),
            buttons: [
                {
                    text: this.translate.instant('evote.alert.participate.cancel'),
                },
                {
                    text: this.translate.instant('evote.alert.participate.action'),
                    role: 'confirm',
                },
            ],
        });
    }

    async setUserDefaultVotes(assemblyId: string) {
        await this.updateVoters(assemblyId, [
            {
                id: this.userService.user.id,
                evote: true,
            },
        ]);
    }

    async togglePresenceWithQrScanner(): Promise<void> {
        try {
            const status: PermissionStatus = await Camera.requestPermissions();

            if (status.camera !== 'granted') {
                await this.popupService.showToast(this.translate.instant('permissions.camera_denied'), true);
                return;
            }

            const qrCodeModal = await this.modalController.create({
                component: QrcodeScannerComponent,
                cssClass: 'fullscreen',
                showBackdrop: false,
                backdropDismiss: false,
            });

            await qrCodeModal.present();
            const { data } = await qrCodeModal.onWillDismiss();

            if (data?.scannedText) {
                await this.togglePresence(data.scannedText);

                // Show a success toast
                await this.popupService.showToast(this.translate.instant('evote.presence.submitted'));

                // Navigate to the detail page with the scanned text
                await this.router.navigateByUrl(`main/evote2/detail/${data.scannedText}`);
            } else {
                // Handle case where barcode is detected but has no text
                await this.popupService.showToast(this.translate.instant('evote.presence.no_data'), true);
            }
        } catch (err) {
            logger.error('QR Scan Error:', err);
            await this.popupService.showToast(this.translate.instant('evote.presence.failed'), true);
        }
    }

    getAllowedCheckpoints(checkpoints: any, propertyIds = this.userService.user.ownedProperties): Checkpoint[] {
        return checkpoints.filter(
            (checkpoint) =>
                !checkpoint.votePrivilegedProperties?.length ||
                checkpoint.votePrivilegedProperties.some((privilegedPropertyId) =>
                    propertyIds.includes(privilegedPropertyId)
                )
        );
    }

    private getUniquePropertyIds(flats: any[]): string[] {
        return [...new Set(flats.map((flat) => flat.propertyId))];
    }

    private getOwnershipAssemblies(propertyIds: string[]) {
        const states = ['invited', 'started', 'closed', 'processed'];

        return combineLatest(
            propertyIds.map(async (id) => {
                const q = query(
                    collection(this.firestore, `ns/${this.ns.getNs()}/ownershipAssembliesV2`),
                    where('properties', 'array-contains', id),
                    where('state', 'in', states)
                );
                const querySnapshot = await getDocs(q);
                return querySnapshot.docs.map((doc) => doc.data());
            })
        ).pipe(switchMap((lists) => of(_.flattenDeep(lists))));
    }

    private processRawAssemblies(rawAssemblies: OwnershipAssembly[]) {
        if (!rawAssemblies.length) {
            return of([]);
        }

        const assemblies = rawAssemblies.reduce(
            (array, assembly) => [...array.filter((tempAssembly) => tempAssembly.id !== assembly.id), assembly],
            []
        );

        const releasedAssemblies = assemblies.filter((assembly) => assembly.checklist?.releasedRevision);
        return combineLatest(
            releasedAssemblies.map((assembly: OwnershipAssembly) =>
                this.checklistService.getChecklistObservable(assembly.id, assembly.checklist.releasedRevision).pipe(
                    switchMap((checklist: any) =>
                        this.checklistService.getCheckpointsObservable(assembly.id, checklist.checkpoints).pipe(
                            map((checkpoints: Checkpoint[]) => {
                                assembly.checkpoints = checkpoints;
                                return assembly;
                            })
                        )
                    )
                )
            )
        );
    }

    private loadVoterDataForAssemblies(assemblies: OwnershipAssembly[], userId: string) {
        return combineLatest(
            assemblies.map((assembly) => {
                const docRef = doc(
                    this.firestore,
                    `ns/${this.ns.getNs()}/ownershipAssembliesV2/${assembly.id}/voters/${userId}`
                );
                return from(getDoc(docRef)).pipe(
                    map((docSnap) => {
                        const voter = docSnap.exists() ? docSnap.data() : null;
                        if (!voter) {
                            return assembly;
                        }

                        const allowedCheckpoints = this.getAllowedCheckpoints(assembly.checkpoints);

                        assembly.voter = voter;
                        assembly.voted = assembly.checkpoints
                            .filter(
                                (checkpoint) =>
                                    checkpoint.type !== 'noDecision' &&
                                    checkpoint.relevant &&
                                    allowedCheckpoints.some(
                                        (allowedCheckpoint) => allowedCheckpoint.id === checkpoint.id
                                    )
                            )
                            .every((checkpoint) => voter.latestVotes[checkpoint.id] !== null);

                        assembly.latestVotes = voter.latestVotes;
                        assembly.delegatedTo = voter.represent;
                        return assembly;
                    }),
                    defaultIfEmpty(assembly)
                );
            })
        );
    }

    private finalizeAssemblies(assemblies: OwnershipAssembly[]) {
        const filteredAssemblies = assemblies.filter((assembly) => assembly.voter);
        convertFirestoreDate(filteredAssemblies);

        filteredAssemblies.sort((a, b) => a.startDate - b.startDate);

        return of(filteredAssemblies);
    }
}
