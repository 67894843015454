import { UserService } from 'src/app/services/user/user.service';
import { Injectable } from '@angular/core';
import { Firestore, doc, getDoc, collection, query, where, getDocs } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root',
})
export class CraftsmanService {
    constructor(
        private userService: UserService,
        private firestore: Firestore
    ) {}

    public async getCraftsman(craftsmanId: string) {
        const namespace = this.userService.getNamespace();
        const craftsmanDocRef = doc(this.firestore, `ns/${namespace}/craftbusinesses/${craftsmanId}`);
        const docSnap = await getDoc(craftsmanDocRef);

        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            throw new Error('Not found');
        }
    }

    public async getCraftsmanShowInApp(craftsmanId: string) {
        const namespace = this.userService.getNamespace();
        const q = query(
            collection(this.firestore, `ns/${namespace}/craftbusinesses`),
            where('showInApp', '==', true),
            where('id', '==', craftsmanId)
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.docs.length) {
            return querySnapshot.docs[0].data();
        } else {
            return null;
        }
    }
}
