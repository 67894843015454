import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the KeepLinesPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
    name: 'keepLines',
})
export class KeepLinesPipe implements PipeTransform {
    /**
     * Takes a value replace breaks with <br>. Substr(1) because we don't want to insert <br>s at the beginning
     */
    transform(value: string) {
        if (value) {
            let valueWithBreaks = value.substr(1).replace(/(\\r\\n)|([\r\n])/gim, '<br/>');
            valueWithBreaks = `${value[0]}${valueWithBreaks}`;
            return valueWithBreaks;
        }
        return value;
    }
}
