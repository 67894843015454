import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'userStatus',
})
export class UserStatusPipe implements PipeTransform {
    transform(status: string) {
        switch (status) {
            case 'INIT':
                return 'OPEN';
            case 'ACCEPTED':
                return 'IN_WORK';
        }
        return 'CLOSED';
    }
}
