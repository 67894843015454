import { ApiService } from '../api/api.service';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { BaseDataService } from '../../util/base-data-service';
import { BaseClass } from '../../util/base-class';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';

@Injectable({
    providedIn: 'root',
})
export class PushNotificationService extends BaseClass implements BaseDataService {
    messages$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private subscriptions: Subscription[] = [];

    constructor(
        private platform: Platform,
        private api: ApiService
    ) {
        super('PushNotificationService');
    }

    async initialize() {
        if (Capacitor.isNativePlatform()) {
            // Request permission to use push notifications
            // iOS will prompt user and return if they granted permission or not
            // Android will just grant without prompting
            PushNotifications.requestPermissions().then((result) => {
                if (result.receive === 'granted') {
                    // Register with Apple / Google to receive push via APNS/FCM
                    PushNotifications.register();
                } else {
                    console.log(result);
                }
            });

            PushNotifications.addListener('registration', (token: Token) => {
                console.log('Push registration success, token: ' + token.value);
                this.saveTokenToFirestore(token.value);
            });

            PushNotifications.addListener('registrationError', (error: any) => {
                console.log('Error on registration: ' + JSON.stringify(error));
            });

            PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
                console.log('Push received: ' + JSON.stringify(notification));
            });

            PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
                console.log('Push action performed: ' + JSON.stringify(notification));
            });
        }
    }

    private async saveTokenToFirestore(token: string) {
        if (!token) {
            return;
        }
        return this.api.post('devices', {
            token,
            web: false,
            app: 'tenant',
            platform: this.platform.platforms().join(','),
        });
    }

    private async removeTokenFromFirestore() {
        if (Capacitor.isNativePlatform()) {
            const token = await FirebaseMessaging.getToken();
            if (token) {
                return this.api.delete(`devices/${token}`);
            }
        }
    }

    terminate() {
        this.clearSubscriptions();
        this.removeTokenFromFirestore();
    }

    private clearSubscriptions() {
        if (this.subscriptions.length) {
            for (const sub of this.subscriptions) {
                sub.unsubscribe();
            }
            this.subscriptions = [];
        }
    }
}
