<div [class.wrapper]="displayDocuments.length > 3">
    <ion-list>
        <ion-item lines="none" *ngFor="let doc of displayDocuments">
            <ion-label>
                <ion-text *ngIf="title" class="text-light"> {{ title | slice: 0 : 20 }}</ion-text>
                <p class="text-light">
                    {{ doc?.name }}
                </p>
            </ion-label>
            <ion-icon *ngIf="recommended" color="primary" class="ion-margin-start" name="thumbs-up-outline"></ion-icon>
            <ion-icon (click)="openDoc(doc)" slot="end" name="cloud-download-outline"></ion-icon>
        </ion-item>
        <ion-item *ngIf="displayDocuments.length > 3"></ion-item>
    </ion-list>
    <div *ngIf="displayDocuments.length > 3" class="gradient"></div>
</div>
