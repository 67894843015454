import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components/components.shared.module';
import { QrcodeScannerComponent } from './qrcode-scanner.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { TopbarComponentModule } from '../../components/molecules/topbar/topbar.component.module';

@NgModule({
    declarations: [QrcodeScannerComponent],
    exports: [QrcodeScannerComponent],
    imports: [ComponentsSharedModule, ZXingScannerModule, TopbarComponentModule],
})
export class QrcodeScannerComponentModule {}
