<ion-card class="accent-border" *ngIf="ownershipAssembly">
    <ion-card-content class="separator-top">
        <ion-row class="ticket-info ion-margin-bottom">
            <ion-col size="6" class="ion-no-padding">
                <ion-text>
                    <h2>
                        <b> {{ ownershipAssembly?.name }}</b>
                    </h2>
                </ion-text>
            </ion-col>
            <ion-col class="ion-no-padding">
                <ion-row class="w-100 ion-justify-content-end">
                    <ion-col class="ion-text-right ion-no-padding">
                        <ion-label color="primary">
                            {{ 'evote.assembly.state.' + ownershipAssembly.state | translate }}
                        </ion-label>
                    </ion-col>
                    <ion-col size="2" class="state-icon">
                        <ion-icon color="primary" name="information-circle"></ion-icon>
                    </ion-col>
                </ion-row>
            </ion-col>
        </ion-row>
        <ion-row class="ticket-info" *ngIf="ownershipAssembly?.delegatedTo">
            <ion-col class="ion-no-padding">
                <ion-row class="ion-justify-content-start">
                    <ion-col size="7" class="ion-no-padding" style="display: flex; align-items: center">
                        <ion-button size="small">
                            {{ 'evote.checkpoint.delegated' | translate }}
                            <ion-icon name="chevron-forward-outline" size="small"></ion-icon>
                            {{ ownershipAssembly.delegatedTo.name }}
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-col>
        </ion-row>
        <ng-container *ngIf="!ownershipAssembly.delegatedTo">
            <ion-row class="ticket-info">
                <ion-col class="ion-no-padding">
                    <ion-row class="ion-justify-content-start">
                        <ion-col
                            *ngIf="
                                ownershipAssembly?.evoteRestriction === 'never' ||
                                (ownershipAssembly?.evoteRestriction === 'started' &&
                                    !['created', 'invited'].includes(ownershipAssembly.state)) ||
                                (ownershipAssembly?.evoteRestriction === 'present' && currentUserIsPresent)
                            "
                            size="7"
                            class="ion-no-padding">
                            <ion-button
                                class="button-with-radius"
                                *ngIf="ownershipAssembly?.voted"
                                color="success"
                                size="small">
                                {{ 'evote.assembly.voted' | translate }}
                            </ion-button>
                            <ion-button
                                class="button-with-radius"
                                *ngIf="ownershipAssembly && !ownershipAssembly.voted"
                                color="danger"
                                size="small">
                                {{ 'evote.assembly.not-voted' | translate }}
                            </ion-button>
                        </ion-col>
                        <ion-col
                            *ngIf="
                                ownershipAssembly?.evoteRestriction === 'started' &&
                                ['created', 'invited'].includes(ownershipAssembly.state)
                            "
                            size="7"
                            class="ion-no-padding">
                            <ion-button color="danger" size="small">
                                {{ 'evote.assembly.evoteRestriction.started' | translate }}
                            </ion-button>
                        </ion-col>
                        <ion-col
                            *ngIf="ownershipAssembly?.evoteRestriction === 'always'"
                            size="7"
                            class="ion-no-padding">
                            <ion-button color="light" size="small">
                                {{ 'evote.assembly.evoteRestriction.full-restriction' | translate }}
                            </ion-button>
                        </ion-col>
                        <ion-col
                            *ngIf="ownershipAssembly?.evoteRestriction === 'present' && !currentUserIsPresent"
                            size="7"
                            class="ion-no-padding">
                            <ion-button color="danger" size="small">
                                {{ 'evote.assembly.evoteRestriction.present' | translate }}
                            </ion-button>
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>
        </ng-container>
        <ng-container *ngIf="relevantProperty">
            <ion-row class="ion-margin-top">
                <ion-col size="2">
                    <ion-icon class="header-icon" color="primary" name="home"></ion-icon>
                </ion-col>
                <ion-col size="10" class="ion-no-padding">
                    <span class="title">
                        {{ relevantProperty.name }}
                    </span>
                    <p>
                        {{ relevantProperty.street }},
                        {{ relevantProperty.zipcode }}
                        {{ relevantProperty.city }}
                    </p>
                    <p *ngIf="properties.length - 1 > 0">
                        > {{ properties.length - 1 }}
                        {{ 'evote-assembly.further-properties' | translate }}
                    </p>
                </ion-col>
            </ion-row>
        </ng-container>
        <ion-row>
            <ion-col size="2">
                <ion-icon class="header-icon" name="time-outline"></ion-icon>
            </ion-col>
            <ion-col size="10" class="ion-no-padding">
                <span class="title"
                    >{{ ownershipAssembly.startDate | date: 'dd.MM.yyyy' }} |
                    {{ ownershipAssembly.startDate | date: 'HH:mm' }} -
                    {{ ownershipAssembly.endDate | date: 'HH:mm' }}</span
                >
                <p *ngIf="daysUntilEvent > 0">
                    <i>{{ daysUntilEvent | number: '1.0-0' }} {{ 'evote.assembly.days-left' | translate }}</i>
                </p>
                <p *ngIf="daysUntilEvent == 0">
                    <i> {{ 'evote.assembly.date.today' | translate }} </i>
                </p>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="!isDetailedView && locationAvailable">
            <ion-col size="2">
                <ion-icon class="header-icon" name="location-outline"></ion-icon>
            </ion-col>
            <ion-col size="10" class="ion-no-padding">
                <p class="title">
                    {{ ownershipAssembly.locationName }}
                </p>
                <ion-label> {{ ownershipAssembly.locationStreet }}</ion-label>
                <ion-label> {{ ownershipAssembly.locationZipcode }}</ion-label>
                <ion-label> {{ ownershipAssembly.locationCity }}</ion-label>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="!isDetailedView && ownershipAssembly.digitalUrl">
            <ion-col size="2">
                <ion-icon class="header-icon" name="globe-outline"></ion-icon>
            </ion-col>
            <ion-col size="10">
                <p>
                    <a (click)="$event.stopPropagation()" href="{{ ownershipAssembly.digitalUrl }}">
                        {{ ownershipAssembly.digitalUrl }}
                    </a>
                </p>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="2">
                <ion-icon class="header-icon" name="list-outline"></ion-icon>
            </ion-col>
            <ion-col size="10" class="ion-no-padding">
                <span *ngIf="ownershipAssembly.checklist.releasedVersion > 0">
                    <ion-label class="title">
                        {{ 'evote.assembly.version' | translate }}
                        {{ ownershipAssembly.checklist.releasedVersion }}
                    </ion-label>
                    <ion-label>
                        {{ currentChecklist?.releaseDate | date: 'dd.MM.yyyy' }}
                        |
                        {{ currentChecklist?.releaseDate | date: 'HH:mm' }}
                    </ion-label>
                    <ng-container *ngIf="changedCheckpoints?.length">
                        <p>
                            <ion-label class="title">
                                {{ 'evote.assembly.changedCheckpoints' | translate }}
                            </ion-label>
                            <span *ngFor="let changedCheckpoint of changedCheckpoints; index as i">
                                {{ changedCheckpoint.name }}{{ i + 1 < changedCheckpoints.length ? ', ' : '' }}
                            </span>
                        </p>
                    </ng-container>
                </span>

                <p
                    class="title"
                    *ngIf="ownershipAssembly.checklistRev <= 0 && ownershipAssembly.state !== ASSEMBLYSTATES.STARTED">
                    {{ 'evote.assembly.not-published' | translate }}
                </p>

                <p
                    class="title"
                    *ngIf="ownershipAssembly.checklistRev === 0 && ownershipAssembly.state === ASSEMBLYSTATES.STARTED">
                    {{ 'evote.assembly.not-published-external' | translate }}
                </p>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="currentUserIsPresent">
            <ion-col size="2">
                <ion-icon color="success" class="header-icon" name="person-outline"></ion-icon>
            </ion-col>
            <ion-col size="10" class="ion-no-padding">
                <ion-label class="title">{{ 'evote.assembly.present-status-yes' | translate }}</ion-label>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="delegateIds?.length" (click)="delegatesExpanded = !delegatesExpanded; $event.stopPropagation()">
            <ion-col size="2">
                <ion-icon class="header-icon" name="people-circle-outline"></ion-icon>
            </ion-col>
            <ion-col size="9" class="ion-no-padding">
                <span>
                    <ion-label class="title">
                        {{ 'evote.assembly.all-representatives' | translate }}
                        ({{ delegateIds.length }})
                    </ion-label>
                </span>
            </ion-col>
            <ion-col size="1" class="title chevron">
                <ion-icon *ngIf="!delegatesExpanded" name="chevron-down-outline"></ion-icon>
                <ion-icon *ngIf="delegatesExpanded" name="chevron-up-outline"></ion-icon>
            </ion-col>
        </ion-row>

        <div *ngIf="loadingRepresentatives" style="display: flex; justify-content: center">
            <ion-spinner></ion-spinner>
        </div>

        <div *ngIf="delegateIds?.length && delegatesExpanded && !loadingRepresentatives">
            <ion-row class="ion-padding-start" *ngFor="let id of delegateIds">
                <ion-col size="2">
                    <ion-avatar style="height: 30px; width: 30px; margin-left: 5px">
                        <img src="../../../../assets/imgs/profile_picture_default.png" />
                    </ion-avatar>
                </ion-col>
                <ion-col size="9" class="title">
                    <ion-label> {{ delegates[id].name }}</ion-label>
                </ion-col>

                <ion-col size="1" class="chevron" (click)="rejectRepresentive($event, id)">
                    <ion-icon
                        name="thumbs-down-outline"
                        color="danger"
                        style="font-size: var(--font-size-h3) !important"></ion-icon>
                </ion-col>
            </ion-row>
        </div>

        <ion-row *ngIf="ownershipAssembly.finalProtocol">
            <ion-col size="2">
                <ion-icon class="header-icon" name="document-text-outline"></ion-icon>
            </ion-col>
            <ion-col size="10" class="ion-no-padding">
                <span>
                    <ion-label class="title is-link" (click)="openPdf()">
                        {{ 'evote.assembly.protocol-published' | translate }}
                    </ion-label>
                    <ion-label>
                        {{ ownershipAssembly.finalProtocol?.uploaded | date: 'dd.MM.yyyy | HH:mm' }}
                    </ion-label>
                </span>
            </ion-col>
        </ion-row>
    </ion-card-content>
</ion-card>
