<ion-header>
    <app-topbar
        [title]="'evote.delegate.representation.authority' | translate"
        [left]="'close'"
        (leftClicked)="modalController.dismiss()"></app-topbar>
</ion-header>
<ion-content>
    <form [formGroup]="delegationForm">
        <ion-list>
            <ion-list-header>
                {{ 'evote.delegate.undersigned.condominium.owner' | translate }}
            </ion-list-header>
            <ion-item>
                <ion-input
                    label=""
                    formControlName="firstnameOwner"
                    placeholder="{{ 'evote.delegate.owner.firstname' | translate }}"></ion-input>
            </ion-item>
            <ion-item>
                <ion-input
                    label=""
                    formControlName="lastnameOwner"
                    placeholder="{{ 'evote.delegate.owner.lastname' | translate }}"></ion-input>
            </ion-item>
            <ion-item>
                <ion-input
                    label=""
                    formControlName="addressOwner"
                    placeholder="{{ 'evote.delegate.owner.address' | translate }}"></ion-input>
            </ion-item>
            <ion-item>
                <ion-input
                    label=""
                    formControlName="nameOfSTWEOwner"
                    placeholder="{{ 'evote.delegate.name.stwe' | translate }}"></ion-input>
            </ion-item>
            <ion-list-header>
                {{ 'evote.delegate.hereby.authorized' | translate }}
            </ion-list-header>
            <ion-item>
                <ion-input
                    label=""
                    formControlName="firstnameAuthorized"
                    placeholder="{{ 'evote.delegate.authorized.firstname' | translate }}"></ion-input>
            </ion-item>
            <ion-item>
                <ion-input
                    label=""
                    formControlName="lastnameAuthorized"
                    placeholder="{{ 'evote.delegate.authorized.lastname' | translate }}"></ion-input>
            </ion-item>
            <ion-item>
                <ion-input
                    label=""
                    formControlName="addressAuthorized"
                    placeholder="{{ 'evote.delegate.authorized.address' | translate }}"></ion-input>
            </ion-item>
            <ion-list-header>
                {{ 'evote.delegate.condominium.meeting' | translate }}
            </ion-list-header>
            <ion-item>
                <ion-input
                    label=""
                    formControlName="assemblyName"
                    placeholder="{{ 'evote.delegate.assembly.name' | translate }}"></ion-input>
            </ion-item>
            <ion-item>
                <ion-input label="" formControlName="date" type="date"></ion-input>
            </ion-item>
            <ion-item>
                <ion-input
                    label=""
                    formControlName="comment"
                    placeholder="{{ 'evote.delegate.assembly.comment' | translate }}"></ion-input>
            </ion-item>
            <ion-button class="ion-margin" (click)="send()" expand="block">{{
                'evote.delegate.send' | translate
            }}</ion-button>
        </ion-list>
    </form>
</ion-content>
