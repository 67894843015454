import { Component, Input, OnInit } from '@angular/core';
import { PropertyService } from 'src/app/services/property/property.service';
import { DEFAULT_PROFILE_PICTURE } from '../../../const/app.const';

@Component({
    selector: 'app-ticket-manager-card',
    templateUrl: './ticket-manager-card.component.html',
    styleUrls: ['./ticket-manager-card.component.scss'],
})
export class TicketManagerCardComponent implements OnInit {
    @Input() data;
    public DEFAULT_PROFILE_PICTURE = DEFAULT_PROFILE_PICTURE;
    managers = [];

    constructor(private propertyService: PropertyService) {}

    onExpand() {}

    async ngOnInit() {
        this.managers = [this.data.manager];
        if (this.data.manager.vacation) {
            const deputies = await Promise.all(
                this.data.propertyManagerIds
                    .filter((id) => id != this.data.manager.uid)
                    .map((managerId) => this.propertyService.getManager(managerId))
            );
            this.managers = [...this.managers, ...deputies];
        }
    }
}
