import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';

@Component({
    selector: 'app-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit, AfterViewInit {
    @ViewChild('canvas') canvas: ElementRef;

    @Input() steps: Array<{
        label: string;
        active: boolean;
        complete: boolean;
    }>;
    stepCount;
    currentStep = 0;

    chart: any;

    ngOnInit() {
        this.stepCount = this.steps.length;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.createChart();
        }, 500);
    }

    next() {
        this.steps[this.currentStep].active = false;
        this.steps[this.currentStep].complete = true;
        this.currentStep++;

        if (this.currentStep < this.stepCount) {
            this.steps[this.currentStep].active = true;
            this.updateChart();
        }
    }

    previous() {
        if (this.currentStep > 0) {
            this.steps[this.currentStep].active = false;
            this.currentStep--;
            this.steps[this.currentStep].complete = false;
            this.steps[this.currentStep].active = true;
            this.updateChart();
        }
    }

    updateChart() {
        this.chart.data.datasets[0].data = [this.currentStep + 1, this.steps.length - this.currentStep - 1];
        this.chart.update();
    }

    createChart() {
        if (this.chart) {
            this.chart.destroy();
        }

        this.chart = new Chart(this.canvas.nativeElement, {
            type: 'doughnut',
            data: {
                datasets: [
                    {
                        data: [this.currentStep + 1, this.steps.length - this.currentStep - 1],
                        backgroundColor: ['#10dc60', '#E0E0E0FF'],
                    },
                ],
            },
            options: {
                cutout: 35,
                events: [],
                elements: {
                    arc: {
                        borderWidth: 0,
                    },
                },
            },
        });
    }
}
