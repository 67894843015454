import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UserService } from '../user/user.service';
import { SettingsService } from '../settings/settings.service';
import { ElasticConfig } from '../../models/elastic-config';

@Injectable({
    providedIn: 'root',
})
export class ElasticService {
    private elasticInfo: ElasticConfig;

    constructor(
        private userService: UserService,
        private httpClient: HttpClient,
        private settings: SettingsService
    ) {}

    public searchTicketNotes(searchObj: any) {
        return this.httpClient
            .post(`${environment.apiBase}elasticsearch/searchTicketNotes`, {
                ...searchObj,
            })
            .toPromise()
            .then(this.prettifyResult);
    }

    public searchBlackboardComments(searchObj: any) {
        return this.httpClient
            .post(`${environment.apiBase}elasticsearch/searchBlackboardComments`, {
                ...searchObj,
            })
            .toPromise()
            .then(this.prettifyResult);
    }

    private prettifyResult(res: any) {
        return { hits: res.hits.hits, total: res.hits.total.value };
    }

    public mapResult(res: any) {
        if (res.hits && res.hits.hits) {
            return res.hits.hits.map((hit) => hit._source);
        }
        return [];
    }

    private search(searchObj: any, index?: string, defaultElasticSettings?) {
        const elasticSettings = this.elasticInfo || defaultElasticSettings;
        if ((elasticSettings != null && this.userService.getNamespace() != null) || defaultElasticSettings) {
            const url = `${elasticSettings.url}${index}/_search`;
            const header = new HttpHeaders();
            return this.httpClient
                .post(url, searchObj, {
                    headers: header
                        .append('authorization', elasticSettings.auth)
                        .append('content-type', 'application/json'),
                })
                .toPromise();
        } else {
            console.warn('Missing information in elastic search');
            return Promise.resolve([]);
        }
    }

    private getEnforcingQuery(searchString: any, fields: string[], size?: number, from?: number): any {
        let searchValue: any = `"*${searchString}*"`;
        if (!isNaN(searchString)) {
            searchValue = Number(searchString);
        }

        const searchQueryString = `{
        "size": ${size ? size : 25},
        "from": ${from ? from : 0},
        "query": {
            "bool": {
                "must": {

                        "query_string": {
                            "query": ${searchValue},
                            "fields": [],
                            "boost": 1.5
                        }

                }
            }
        }
    }`;
        const searchObj: any = JSON.parse(searchQueryString);
        searchObj.query.bool.must.query_string.fields = fields;
        return searchObj;
    }

    async initialize() {
        return this.settings.getElasticConfig().then((elasticConfig) => {
            this.elasticInfo = elasticConfig;
        });
    }

    public async getTickets(data: any) {
        const result: any = await this.httpClient
            .post(`${environment.apiBase}elasticsearch/searchTenantTickets`, data)
            .toPromise();

        return result.hits.hits.map((hit) => hit._id);
    }

    public async getTicketsCount(filter: any) {
        filter = { ...filter, hideTicketFromTenant: false };
        const response: any = await this.httpClient
            .post(`${environment.apiBase}elasticsearch/searchTickets`, {
                filter,
                source: false,
                size: 0,
            })
            .toPromise();

        return response.hits.total.value;
    }

    public async getNotifications(filter?: any, from?: any, size = 10, source = false) {
        const response: any = await this.httpClient
            .post(`${environment.apiBase}elasticsearch/searchNotifications`, {
                filter: { ...filter, 'uid.keyword': this.userService.user.id },
                source: source,
                sort: { createdOn: 'desc' },
                from,
                size,
            })
            .toPromise();

        return {
            hits: response.hits.hits.map((hit) => hit._id),
            docs: response.hits.hits.map((s) => s._source),
            total: response.hits.total.value,
        };
    }

    public async getNotificationsCount(filter?: any) {
        const response: any = await this.httpClient
            .post(`${environment.apiBase}elasticsearch/searchNotifications`, {
                filter: { ...filter, 'uid.keyword': this.userService.user.id },
                source: false,
                size: 0,
            })
            .toPromise();

        return response.hits.total.value;
    }

    async searchBlackboard(
        searchString: string,
        size = 10,
        from = 0,
        orderBy = {},
        filter = {},
        exclude = {},
        includes = [],
        source = false,
        orFilter = false
    ) {
        try {
            const response: any = await this.httpClient
                .post(`${environment.apiBase}elasticsearch/searchBlackboard`, {
                    searchString: searchString,
                    size: size,
                    from: from,
                    source,
                    sort: orderBy,
                    filter,
                    exclude,
                    includes,
                    orFilter,
                })
                .toPromise();

            const result: any = {
                total: response.hits.total.value,
                ids: response.hits.hits.map((hit) => hit._id),
            };
            if (source) {
                result.source = response.hits.hits.map((hit) => hit._source);
            }
            return result;
        } catch (e) {
            return {
                total: 0,
                ids: [],
            };
        }
    }

    async searchUser(
        searchString: string,
        size = 10,
        from = 0,
        filter = {},
        excludes = {},
        includes = [],
        orderBy = {},
        orFilter = false,
        targetNamespace?: string
    ): Promise<any> {
        try {
            const response: any = await this.httpClient
                .post(`${environment.apiBase}elasticsearch/searchUser`, {
                    searchString: searchString,
                    size: size,
                    from: from,
                    source: true,
                    sort: orderBy,
                    filter,
                    includes,
                    excludes,
                    orFilter,
                })
                .toPromise();

            return {
                total: response.hits.total.value,
                ids: response.hits.hits.map((hit) => hit._id),
                source: response.hits.hits.map((hit) => hit._source),
            };
        } catch (e) {
            return {
                total: 0,
                ids: [],
            };
        }
    }
}
