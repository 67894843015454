import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-ticket-contact-card',
    templateUrl: './ticket-contact-card.component.html',
    styleUrls: ['./ticket-contact-card.component.scss'],
})
export class TicketContactCardComponent {
    @Input() ticketContact: any;
    @Input() ticketFrom: any;
    @Input() ticketNo: number | string;

    constructor() {}

    onExpand() {}
}
