import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components/components.shared.module';
import { TopbarComponentModule } from '../../components/molecules/topbar/topbar.component.module';
import { HtmlReadyAlertComponent } from './html-ready-alert.component';

@NgModule({
    declarations: [HtmlReadyAlertComponent],
    exports: [HtmlReadyAlertComponent],
    imports: [ComponentsSharedModule, TopbarComponentModule],
})
export class HtmlReadyAlertComponentModule {}
