import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController, ModalController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BaseClass } from '../../util/base-class';
import { AuthService } from '../../services/auth/auth.service';
import { PopupService } from '../../services/popup/popup.service';
import { LanguageService } from '../../services/language/language.service';
import { UserService } from '../../services/user/user.service';
import { ChangePasswordComponent } from '../../modals/change-password/change-password.component';
import { ApiService } from 'src/app/services/api/api.service';
import { environment } from 'src/environments/environment';
import { OriginService } from 'src/app/services/origin/origin.service';
import { NamespaceSelectionService } from '../../services/namespace-selection/namespace-selection.service';
import { LogoutService } from '../../services/logout/logout.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { CustomEmailValidator } from '../../validators/custom-email-validator';
import { Router } from '@angular/router';
import { App, AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss'],
})
export class LoginPage extends BaseClass implements OnInit {
    loading = true;
    nsData;
    form: UntypedFormGroup;
    currentLanguageName: string;
    showDownloadButtons: boolean = false;
    logoUrl = '';
    public badges;
    titleKey: string;
    titleContactKey: string;
    public versionInfo: string = '';
    public appInfo: AppInfo;

    constructor(
        private loadingController: LoadingController,
        private authProvider: AuthService,
        private fb: UntypedFormBuilder,
        private popupService: PopupService,
        private translate: TranslateService,
        private languageService: LanguageService,
        private modalCtrl: ModalController,
        private userService: UserService,
        private navController: NavController,
        private api: ApiService,
        public alertController: AlertController,
        public originService: OriginService,
        private namespaceSelectionService: NamespaceSelectionService,
        private logoutService: LogoutService,
        private themeService: ThemeService,
        private router: Router
    ) {
        super('LoginComponent');
    }

    async ngOnInit() {
        if (Capacitor.isNativePlatform()) {
            App.getInfo().then((info) => {
                this.appInfo = info;
                this.versionInfo = info.version;
            });
        }
        this.logoUrl = `${environment.apiBase}namespaces/image/logo.png?X-App-Origin=${this.originService.origin}`;
        if (this.originService.whiteLabelKey) {
            this.titleKey = `auth.login.title.tenant.${this.originService.whiteLabelKey}`;
            this.titleContactKey = `auth.login.title.contact.${this.originService.whiteLabelKey}`;
        } else {
            this.titleKey = 'auth.login.title.tenant';
            this.titleContactKey = 'auth.login.title.contact';
        }
        this.form = this.fb.group({
            email: [
                '',
                [
                    Validators.required,
                    (formControl: UntypedFormControl) => {
                        if (formControl.value.startsWith('bmt:')) {
                            return true;
                        } else {
                            return CustomEmailValidator.emailFormat(formControl);
                        }
                    },
                ],
            ],
            password: ['', [Validators.required]],
        });

        this.currentLanguageName = this.languageService.getFullLanguageName(this.translate.currentLang);

        if (!Capacitor.isNativePlatform()) {
            this.badges = await this.userService.getBadges();
            this.showDownloadButtons = true;
        }

        const namespace = this.router.parseUrl(this.router.url).queryParams['ns'];
        if (namespace) {
            try {
                this.nsData = await this.api.get(`namespaces/info/${namespace}?language=${this.translate.currentLang}`);
                await this.themeService.setTheme(this.nsData.theme);

                this.nsData.id = namespace;
            } catch (error) {
                console.error(`ns-${namespace} doesn't exist`);
                await this.showNamespaceNotFoundAlert();
                await this.navController.navigateRoot('login');
            }
        }

        this.loading = false;
    }

    async onLogin() {
        // init and start loader
        const loading = await this.loadingController.create();
        await loading.present();

        try {
            // try to login and then setRoot to TabsPage
            const namespaceTokens = await this.authProvider.authenticate(this.email.value, this.password.value);

            if (namespaceTokens.length > 1) {
                await this.navController.navigateRoot('namespace-chooser');
            } else {
                if (this.authProvider.nsSubscription) {
                    await this.logoutService.doLogout(true);
                }

                await this.authProvider.signIn(namespaceTokens[0], true);
                await loading.dismiss();
                await this.navController.navigateRoot('start');
                await this.namespaceSelectionService.initAfterLogin();
                await this.navController.navigateRoot('main');
            }

            await loading.dismiss();
        } catch (error) {
            console.log(error);
            await loading.dismiss();
            // if we got an error, present error alert
            this.logger.error(error);
            await this.popupService.showAlert(
                this.translate.instant('login.error'),
                this.translate.instant('login.error-text'),
                [this.translate.instant('login.ok')]
            );
        }
    }

    get email() {
        return this.form.get('email');
    }

    private get password() {
        return this.form.get('password');
    }

    async sendPasswordResetMail() {
        const modal = await this.modalCtrl.create({
            component: ChangePasswordComponent,
            componentProps: {
                mail: this.email.value,
            },
        });
        await modal.present();
    }

    private async showNamespaceNotFoundAlert() {
        return await this.popupService.showAlert('', this.translate.instant('landing.error.ns-not-found'), [
            {
                text: this.translate.instant('ok'),
            },
        ]);
    }

    protected readonly environment = environment;
}
