import { Injectable } from '@angular/core';
import { collection, doc, docData, Firestore, getDoc } from '@angular/fire/firestore';
import { NsUtil } from '../../util/ns';
import { TicketsService } from '../tickets/tickets.service';
import { UserService } from '../user/user.service';
import { combineLatest, Observable } from 'rxjs';
import { Checkpoint } from '../../models/checkpoint';
import { convertFirestoreDate } from '../../util/util';
import { map } from 'rxjs/operators';
import { FirebaseWrapperService } from '../firebase-wrapper/firebase-wrapper.service';

export const CHECKLISTTYPES = {
    NODECISION: 'noDecision',
    SIMPLEMORE: 'simpleMore',
    QUALIFIEDMORE: 'qualifiedMore',
    UNANIMITY: 'unanimity',
    TBD: 'tbd',
};

@Injectable({
    providedIn: 'root',
})
export class ChecklistService {
    constructor(
        private ns: NsUtil,
        private ticketsService: TicketsService,
        private userService: UserService,
        private firebaseWrapper: FirebaseWrapperService
    ) {}

    public getCheckpointObservable(assemblyId: string, checkpointId: string): Observable<any> {
        const path = `ns/${this.ns.getNs()}/ownershipAssemblies/${assemblyId}/checkpoints`;
        return this.firebaseWrapper.docDataObservable(path, checkpointId);
    }

    public async buildCheckpoint(assembldyId: string, checkpoint: Checkpoint, variants = true) {
        await Promise.all(
            ['title', 'description', 'meetingNotes', 'meetingInternalNotes'].map(async (field) => {
                checkpoint[field] = await this.ticketsService.getTicketTextsByKeyAndLanguage(
                    `checkpoint.${checkpoint.id}.${field}`,
                    this.userService.user.language
                );
            })
        );
        if (variants) {
            checkpoint.variants = await this.buildVariants(assembldyId, checkpoint);
        }
        checkpoint.isBuilt = true;
        return checkpoint;
    }

    private async buildVariants(assembldyId: string, checkpoint: Checkpoint) {
        const variants = await Promise.all(
            (checkpoint.variants || []).map((variant) => {
                if (variant?.isBuilt) {
                    return this.getVariantById(assembldyId, variant.id);
                } else {
                    return this.getVariantById(assembldyId, variant);
                }
            })
        );
        return await Promise.all([
            ...variants.map(async (variant: any) => {
                variant.isBuilt = true;
                variant.active = true;
                variant.recommended = (checkpoint.recommendedVariants || []).includes(variant.id);
                variant.label = await this.ticketsService.getTicketTextsByKeyAndLanguage(
                    `variant.${variant.id}.label`,
                    this.userService.user.language
                );
                return variant;
            }),
            this.getNewVariant(),
        ]);
    }

    public getNewVariant() {
        return {
            label: '',
            active: false,
            recommended: false,
            documents: { imgs: [], pdfs: [] },
        };
    }

    private async getVariantById(assemblyId: string, variantId: string) {
        const path = `ns/${this.ns.getNs()}/ownershipAssemblies/${assemblyId}/variants`;
        return await this.firebaseWrapper.docData(path, variantId);
    }

    public getCheckpointsObservable(assemblyId: string, checkpointIds: string[]) {
        return combineLatest(checkpointIds.map((id) => this.getCheckpointObservable(assemblyId, id)));
    }

    async getChecklistById(assemblyId: string, checklistId: string) {
        const path = `ns/${this.ns.getNs()}/ownershipAssemblies/${assemblyId}/checklist`;
        const docSnap = await this.firebaseWrapper.docSnap(path, checklistId);
        if (docSnap.exists()) {
            const model = docSnap.data();
            convertFirestoreDate(model);
            return model;
        } else {
            return null;
        }
    }

    getChecklistObservable(assemblyId: string, checklistId: string): Observable<any> {
        const path = `ns/${this.ns.getNs()}/ownershipAssemblies/${assemblyId}/checklist`;
        const docRef = this.firebaseWrapper.docReference(path, checklistId);
        return docData(docRef).pipe(
            map((assembly) => {
                convertFirestoreDate(assembly);
                return assembly;
            })
        );
    }
}
