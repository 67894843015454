import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-ticket-appointment-card',
    templateUrl: './ticket-appointment-card.component.html',
    styleUrls: ['./ticket-appointment-card.component.scss'],
})
export class TicketAppointmentCardComponent {
    @Input() data;
    constructor() {}

    onExpand() {}
}
