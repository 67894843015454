import { Injectable } from '@angular/core';
import { Platform, ModalController, AlertController } from '@ionic/angular';
import * as LiveUpdates from '@capacitor/live-updates';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '../logger/logger.service';
import { AuthService } from '../auth/auth.service';
import { ApiService } from '../api/api.service';
import { ForceUpdateModalComponent } from 'src/app/modals/force-update/force-update-modal.component';
import { App, AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { HtmlReadyAlertComponent } from '../../modals/html-ready-alert/html-ready-alert.component';

@Injectable({
    providedIn: 'root',
})
export class UpdateService {
    private logger: LoggerService;
    private isOldNsMaintenance = false;
    private isOldMaintenance = false;
    private forceUpdateModalRef: HTMLIonModalElement;

    constructor(
        private platform: Platform,
        private auth: AuthService,
        private translate: TranslateService,
        private api: ApiService,
        private modalController: ModalController,
        private alertController: AlertController
    ) {
        this.logger = new LoggerService('UpdateService');
    }

    public async initialize() {
        await this.platform.ready();
        // #TODO need to wait because of the network plugin is not ready (Bug?)
        await new Promise((resolve) => setTimeout(resolve, 1000));
        // Check if there's a pending OTA update and force update on app start up
        if (localStorage.getItem('shouldReloadApp') === 'true') {
            const otaEnabled = await this.checkUpdateSettingsOnServer();
            if (otaEnabled) {
                await this.applyPendingOTAUpdate(true);
            }
        } else {
            await this.checkForUpdates();

            // Listen for namespace maintenance
            this.auth.nsMaintenance$.subscribe(async (isNsMaintenance) => {
                if (!isNsMaintenance && this.isOldNsMaintenance) {
                    await this.checkForUpdates();
                }
                this.isOldNsMaintenance = isNsMaintenance;
            });

            // Listen for global maintenance
            this.auth.maintenance$.subscribe(async (isMaintenance) => {
                if (!isMaintenance && this.isOldMaintenance) {
                    await this.checkForUpdates();
                }
                this.isOldMaintenance = isMaintenance;
            });

            // if user resumes the app check for OTA updates or check for updates
            await App.addListener('resume', async () => {
                if (localStorage.getItem('shouldReloadApp') === 'true') {
                    await this.applyPendingOTAUpdate();
                } else {
                    await this.checkForUpdates();
                }
            });
        }
    }

    /**
     * Apply any pending OTA updates that were downloaded previously
     */
    private async applyPendingOTAUpdate(force = false) {
        try {
            if (force || (await this.askUserForActivatePendingOTAUpdate())) {
                await LiveUpdates.reload();
                localStorage.removeItem('shouldReloadApp');
            }
        } catch (error) {
            this.logger.error('Error applying pending update:', error);
        }
    }

    /**
     * Checks for live updates if server settings permit
     * returns true if update available
     */
    private async checkForUpdates() {
        try {
            const otaEnabled = await this.checkUpdateSettingsOnServer();
            if (otaEnabled) {
                const result = await LiveUpdates.sync();
                localStorage.setItem('shouldReloadApp', JSON.stringify(result.activeApplicationPathChanged));
                if (result.activeApplicationPathChanged) {
                    await this.applyPendingOTAUpdate();
                }
            }
        } catch (error) {
            this.logger.error('Error during live update sync', error);
        }
    }

    /**
     * Checks if OTA update is enabled => returns true
     * Checks min App-Version => show modal and returns false
     * else returns false
     */
    async checkUpdateSettingsOnServer(): Promise<boolean> {
        try {
            if (this.forceUpdateModalRef) {
                await this.forceUpdateModalRef.dismiss();
                this.forceUpdateModalRef = null;
            }
            if (Capacitor.isNativePlatform()) {
                const updateSettings = await this.api.get('updateInformation');
                if (updateSettings) {
                    const appInfo: AppInfo = await App.getInfo();
                    const minVersionGlobal = updateSettings.forceUpdate[appInfo.id] || '0.0.0';
                    const minVersionPlatform =
                        updateSettings.forceUpdate[Capacitor.getPlatform()][appInfo.id] || '0.0.0';
                    const minVersion = minVersionGlobal > minVersionPlatform ? minVersionGlobal : minVersionPlatform;

                    // this.logger.log('Update Information:', {
                    //     appInfo,
                    //     minVersionGlobal,
                    //     minVersionPlatform,
                    //     minVersion,
                    //     appVersion: appInfo.version,
                    //     updateSettings,
                    //     otaActive: updateSettings.otaActive,
                    //     isUpdateRequired: minVersion > appInfo.version,
                    // });
                    if (minVersion > appInfo.version) {
                        // App needs to be updated via store, show hint
                        this.forceUpdateModalRef = await this.modalController.create({
                            component: ForceUpdateModalComponent,
                            cssClass: 'fullscreen',
                            showBackdrop: false,
                            backdropDismiss: false,
                        });

                        this.forceUpdateModalRef.present();
                        return false;
                    } else {
                        return Boolean(updateSettings.otaActive);
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } catch (e) {
            this.logger.error(e);
            return false;
        }
    }

    async askUserForActivatePendingOTAUpdate() {
        return new Promise<boolean>(async (resolve) => {
            const translatedMessage = this.translate.instant('ota-update.message');
            const title = this.translate.instant('ota-update.title');

            const modal = await this.modalController.create({
                component: HtmlReadyAlertComponent,
                componentProps: { translatedMessage: translatedMessage, title: title },
                cssClass: 'pop-up custom-modal',
                backdropDismiss: false,
            });

            modal.onDidDismiss().then((result) => {
                resolve(result.data);
            });

            await modal.present();
        });
    }

    /**
     * This function processes OTA update.
     */
    // private async checkAndInstallUpdates() {
    //     if (Capacitor.isNativePlatform()) {
    //         try {
    //             const storeLink = this.getStoreLink();
    //             // Check for new live update
    //             const result = await LiveUpdates.sync();
    //             if (result.activeApplicationPathChanged) {
    //                 await this.waitForTranslation('general.update.loading');
    //                 const loading = await this.loadingController.create({
    //                     message: this.translate.instant('general.update.loading', {
    //                         percentage: String(0),
    //                         STORE_LINK: storeLink,
    //                     }),
    //                 });
    //
    //                 await loading.present();
    //                 await LiveUpdates.sync((percentDone) => {
    //                     loading.message = this.translate.instant('general.update.loading', {
    //                         percentage: String(percentDone),
    //                         STORE_LINK: storeLink,
    //                     });
    //                 });
    //                 await LiveUpdates.reload();
    //             }
    //         } catch (err) {
    //             this.logger.error('Check for App update failed', err);
    //         }
    //     }
    // }

    // private getStoreLink() {
    //     try {
    //         return this.platform.is('android')
    //             ? `<a href="${environment.store_link.android}" target="_system">Play Store</a>`
    //             : `<a href="${environment.store_link.ios}">App Store</a>`;
    //     } catch (e) {
    //         return '';
    //     }
    // }
}
