import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '../user/user.service';
import { ApiService } from '../api/api.service';
import { FirebaseWrapperService } from '../firebase-wrapper/firebase-wrapper.service';

@Injectable({
    providedIn: 'root',
})
export class PropertyManagerService {
    constructor(
        private firebaseService: FirebaseWrapperService,
        private userService: UserService,
        private apiService: ApiService
    ) {}

    getManagerObservable(uid: string): Observable<any> {
        return this.firebaseService.docDataObservable(`ns/${this.userService.user.ns}/propertyManagers`, uid);
    }

    async getManager(id: string): Promise<any> {
        return this.apiService.get(`managers/${id}`);
    }
}
