import { Component, OnInit, ViewChild, Input, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '../../services/popup/popup.service';
import { Swiper } from 'swiper';
import { Browser, OpenOptions } from '@capacitor/browser';
import { SwiperOptions } from 'swiper/types';

@Component({
    selector: 'app-photo-viewer',
    templateUrl: './photo-viewer.component.html',
    styleUrls: ['./photo-viewer.component.scss'],
})
export class PhotoViewerComponent implements OnInit {
    @ViewChild('topBar', { read: ElementRef }) topBar: ElementRef;
    @ViewChild('swiperContainer', { static: true }) swiperContainer: ElementRef;

    @ViewChild('swiper', { static: false }) swiperRef: ElementRef | undefined;

    swiper?: Swiper;

    @Input() images = [];
    @Input() index = 0;
    @Input() imageDeleteAllowed = true;

    leftIcon: string;

    config: SwiperOptions = {
        allowTouchMove: true,
        allowSlidePrev: true,
        allowSlideNext: true,
    };

    constructor(
        public modalController: ModalController,
        private popupService: PopupService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.leftIcon = this.imageDeleteAllowed ? 'trash' : 'save-outline';
    }

    ngAfterViewInit() {
        this.swiperReady();
    }

    async leftFunction() {
        if (this.imageDeleteAllowed) {
            await this.deleteImage();
        } else {
            await this.download();
        }
    }

    async download() {
        const openOption: OpenOptions = {
            url: this.images[this.index]?.file,
            windowName: '_system',
        };
        await Browser.open(openOption);
    }

    async deleteImage() {
        const result = await this.popupService.showConfirmation(
            this.translate.instant('ticket-pictures.confirm-delete.title'),
            this.translate.instant('ticket-pictures.confirm-delete.message')
        );

        if (result) {
            await this.modalController.dismiss(this.index);
        }
    }

    toggleSwiper(isZoomed: boolean) {
        this.swiper.allowTouchMove = !isZoomed;
        this.swiper.update();
    }

    swiperReady() {
        if (this.swiperRef && this.swiperRef.nativeElement.swiper) {
            this.swiper = this.swiperRef.nativeElement.swiper;

            this.swiper.on('slideChange', () => {
                this.onSlideChange();
            });
        } else {
            console.warn('Swiper could not be initialized.');
        }
    }

    onSlideChange() {
        this.index = this.swiper.activeIndex;

        console.log(this.index);
    }
}
