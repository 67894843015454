<ion-item lines="none" (click)="onClickFileInput()">
    <ion-label>
        {{ label | translate }}
    </ion-label>
    <ion-button class="swiper-no-swiping" fill="clear" slot="end">
        <ion-icon class="add-document-icon" slot="icon-only" name="add"></ion-icon>
    </ion-button>
</ion-item>
<ion-item lines="none" *ngFor="let img of documents.imgs; index as i">
    <ion-avatar slot="start" (click)="showPhotoPreview(i)">
        <ion-img [src]="img.file | filePreview | async"></ion-img>
    </ion-avatar>
    <ion-input *ngIf="editable" (change)="img.name = $any($event).target.value" [value]="img.name"></ion-input>
    <ion-label *ngIf="!editable">{{ img.name }}</ion-label>

    <ion-button class="swiper-no-swiping" (click)="onDeleteImage(i)" color="danger" fill="clear" slot="end">
        <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
</ion-item>
<ion-item lines="none" *ngFor="let pdf of documents.pdfs; index as i" (click)="openDocument(pdf)">
    <ion-avatar slot="start">
        <ion-img src="/assets/imgs/pdf-icon.svg"></ion-img>
    </ion-avatar>
    <ion-input *ngIf="editable" (change)="pdf.name = $any($event).target.value" [value]="pdf.name"></ion-input>
    <ion-label *ngIf="!editable">{{ pdf.name }}</ion-label>
    <ion-button class="swiper-no-swiping" (click)="onDeletePdf(i)" color="danger" fill="clear" slot="end">
        <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
</ion-item>
