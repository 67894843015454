import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { BaseClass } from '../../util/base-class';
import { Manager } from '../../models/manager';
import { DEFAULT_PROFILE_PICTURE } from '../../const/app.const';
import { FirebaseWrapperService } from '../firebase-wrapper/firebase-wrapper.service';

@Injectable({
    providedIn: 'root',
})
export class ProfileService extends BaseClass {
    managers: { string?: Manager } = {};
    constructor(
        private firebaseService: FirebaseWrapperService,
        private userService: UserService
    ) {
        super('ProfileService');
    }

    async getManagerProfile(managerId: string): Promise<Manager> {
        if (this.managers[managerId]) {
            return this.managers[managerId];
        }
        const docSnap = await this.firebaseService.docSnap(
            `ns/${this.userService.user.ns}/propertyManagers`,
            managerId
        );

        if (docSnap.exists()) {
            const manager = docSnap.data() as Manager;
            if (!manager.profilePicture) {
                manager.profilePicture = DEFAULT_PROFILE_PICTURE;
            }
            this.managers[managerId] = manager;
            return manager;
        }
        return null;
    }
}
