import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { Subscription } from 'rxjs';
import { collection, collectionData, Firestore, getDocs, limit, query, where } from '@angular/fire/firestore';

export enum TicketTextMargin {
    TOP,
    BOTTOM,
    BOTH,
}

@Component({
    selector: 'app-ticket-text',
    templateUrl: './ticket-text.component.html',
    styleUrls: ['./ticket-text.component.scss'],
})
export class TicketTextComponent implements OnInit, OnDestroy {
    @Input() ticketId: string;
    @Input() key: string;
    @Input() edit: boolean = false;
    @Input() title = 'ticket.description-title';
    @Input() margin: TicketTextMargin = null;

    public loading = true;

    private language$: Subscription;
    private ticketText$: Subscription = new Subscription();
    public language;
    public ticketText;
    public originalText;
    public combinedText;
    public editedText;

    constructor(
        private userService: UserService,
        private firestore: Firestore
    ) {}

    ngOnInit() {
        this.language$ = this.userService.userLanguage$.subscribe((lang) => {
            this.language = lang;
            this.ticketText$.unsubscribe();
            this.ticketText$ = this.getTextAsObservable().subscribe(async (subscription) => {
                if (subscription?.length) {
                    this.ticketText = subscription[0];
                    this.originalText = await this.getOriginalText();
                    this.editedText =
                        this.language === this.originalText.lang ? this.originalText.value : this.ticketText.value;
                    this.combinedText = {
                        original: {
                            language: this.originalText.lang,
                            value: this.originalText.value,
                        },
                        [this.ticketText.lang]: this.ticketText.value,
                    };
                }
                this.loading = false;
            });
        });
    }

    ngOnDestroy() {
        this.language$.unsubscribe();
        this.ticketText$.unsubscribe();
    }

    public getEditedText() {
        return this.editedText;
    }

    private getTextAsObservable() {
        return collectionData(this.getQuery());
    }

    private async getOriginalText() {
        const querySnapshot = await getDocs(this.getQuery(true));
        return querySnapshot.docs[0].data();
    }

    private getQuery(original = false) {
        const collectionRef = collection(this.firestore, `ns/${this.userService.getNamespace()}/ticketTexts`);
        let q = query(collectionRef, where('ticketId', '==', this.ticketId), where('key', '==', this.key));

        q = original ? query(q, where('type', '==', 'original')) : query(q, where('lang', '==', this.language));

        return query(q, limit(1));
    }
}
