import { Injectable } from '@angular/core';
import { Firestore, doc, docData } from '@angular/fire/firestore';
import {
    Auth,
    signInWithCustomToken,
    browserLocalPersistence,
    inMemoryPersistence,
    authState,
    signOut,
} from '@angular/fire/auth';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, firstValueFrom, from, Observable, of, Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { UserService } from '../user/user.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    user$: Observable<any>;
    tokenLogin$ = new BehaviorSubject<boolean | null>(null);
    restrictedTokenLogin$ = new BehaviorSubject<boolean>(false);
    tokenLoginTicketId$ = new BehaviorSubject<string | null>(null);
    maintenance$ = new BehaviorSubject<boolean>(false);
    nsMaintenance$ = new BehaviorSubject<boolean>(false);
    nsSubscription: Subscription | null = null;
    namespaceTokens$ = new BehaviorSubject<string[]>([]);

    constructor(
        private auth: Auth,
        private firestore: Firestore,
        private translate: TranslateService,
        private http: HttpClient,
        private userService: UserService
    ) {
        this.user$ = authState(this.auth).pipe(
            switchMap((user) => {
                if (user) {
                    this.handleUserToken(user);
                    const userDocRef = doc(this.firestore, `users/${user.uid}`);
                    return docData(userDocRef);
                } else {
                    return of(null);
                }
            })
        );

        const maintenanceDocRef = doc(this.firestore, 'public/maintenance');
        docData(maintenanceDocRef).subscribe((maintenance: { active: boolean }) => {
            this.maintenance$.next(Boolean(maintenance && maintenance.active));
        });

        this.user$.subscribe((user) => {
            this.registerNamespaceMaintenance(user);
        });
    }

    private handleUserToken(user: any) {
        from(user.getIdTokenResult()).subscribe((idTokenResult: any) => {
            const isTokenLogin = Boolean(idTokenResult?.claims?.tokenLogin);
            this.tokenLogin$.next(isTokenLogin);

            if (isTokenLogin) {
                this.restrictedTokenLogin$.next(Boolean(idTokenResult?.claims?.restricted));
            }
        });
    }

    private registerNamespaceMaintenance(user: any) {
        if (this.nsSubscription) {
            this.nsSubscription.unsubscribe();
        }

        if (user) {
            const nsDocRef = doc(this.firestore, `ns/${user.ns}`);
            this.nsSubscription = docData(nsDocRef).subscribe((namespace: any) => {
                this.nsMaintenance$.next(Boolean(namespace && namespace.maintenance));
            });
        }
    }

    getCurrentUser(): Promise<any> {
        const user = firstValueFrom(this.user$.pipe(take(1)));
        return user;
    }

    async authenticate(email: string, password: string, token = null): Promise<any> {
        const authResponse: any = await firstValueFrom(
            this.http.post(`${environment.apiBase}users/authenticate`, { email, password, token })
        );

        this.namespaceTokens$.next(authResponse.tokens);
        return this.namespaceTokens$.value;
    }

    async signIn(token: { token: string; ticket?: { id: string } } | null = null, reauth: boolean = false) {
        if (token?.ticket) {
            this.tokenLoginTicketId$.next(token.ticket.id || null);
        }

        const persistence = token && !reauth ? inMemoryPersistence : browserLocalPersistence;
        await this.auth.setPersistence(persistence);
        const firebaseUserObject = await signInWithCustomToken(this.auth, token.token);

        if (!firebaseUserObject.user) {
            throw new Error('No user found after sign-in.');
        }

        const idTokenResult = await firebaseUserObject.user.getIdTokenResult();
        const modules = (idTokenResult.claims.modules as string[]) || [];
        this.userService.moduleSubscription.next(modules);

        if (idTokenResult.claims.pm) {
            const errorObj = {
                code: 'auth/user-no-credentials',
                message: this.translate.instant('login.error-no-credentials'),
            };
            throw errorObj;
        }

        return firebaseUserObject;
    }

    logout(keepTokens = false): Promise<void> {
        if (!keepTokens) {
            this.namespaceTokens$.next([]);
        }

        this.tokenLogin$.next(null);
        this.tokenLoginTicketId$.next(null);
        return signOut(this.auth);
    }

    sendPasswordResetMail(email: string): Promise<any> {
        return this.http.post(`${environment.apiBase}users/resetPassword/${email}`, {}).toPromise();
    }
}
