import { Component, Input } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

@Component({
    selector: 'app-html-alert-modal',
    templateUrl: './html-ready-alert.component.html',
    styleUrls: ['./html-ready-alert.component.scss'],
})
export class HtmlReadyAlertComponent {
    @Input() translatedMessage: string;
    @Input() title: string;

    constructor(private modalController: ModalController) {}

    confirm(result: boolean) {
        this.modalController.dismiss(result);
    }
}
