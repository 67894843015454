import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CHECKLISTTYPES } from '../../../services/checklist/checklist.service';

@Component({
    selector: 'app-checklist-item',
    templateUrl: './checklist-item.component.html',
    styleUrls: ['./checklist-item.component.scss'],
})
export class ChecklistItemComponent {
    @Input() checkpointId: any;
    @Input() accepted: any;
    @Input() voted: boolean;
    @Input() votable: boolean = true;
    @Input() delegated: any;
    @Input() index: number;
    @Input() numbering: string = null;
    @Input() type: string;
    @Input() isClosed: boolean;
    @Input() closedCheckpoint: any;
    @Input() relevantCheckpointType: any;
    @Input() assemblyClosed: boolean;
    @Input() relevant: boolean = true;
    @Input() latestVoterName: string;
    @Input() latestTimestamp: Date;
    @Input() hasAttachments: boolean;

    @Output() onClicked = new EventEmitter();
    CHECKLISTTYPES = CHECKLISTTYPES;

    onSelectCheckpoint(checkpoint: any) {
        this.onClicked.emit(checkpoint);
    }
}
