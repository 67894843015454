import { Component, Input } from '@angular/core';
import { Document } from '../../../models/document';
import { DocumentUtilService } from 'src/app/services/document-util/document-util.service';

@Component({
    selector: 'app-document-view',
    templateUrl: './document-view.component.html',
    styleUrls: ['./document-view.component.scss'],
})
export class DocumentViewComponent {
    @Input() documents: Document[];
    @Input() header: string;
    @Input() lines = 'full';
    @Input() noPadding = false;
    progress = 0;

    constructor(private documentUtilService: DocumentUtilService) {}

    async openDocument(document: Document) {
        await this.documentUtilService.openDocument(document);
    }
}
