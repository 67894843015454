import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'app-zoomable-image',
    templateUrl: './zoomable-image.component.html',
    styleUrls: ['./zoomable-image.component.scss'],
})
export class ZoomableImageComponent implements OnInit {
    @Input() image: any;
    @Output() imageZoomed: EventEmitter<boolean> = new EventEmitter();
    @ViewChild('zoomContainer', { static: true }) zoomContainer: ElementRef;

    scale = 1;
    baseScale = 1;
    transform = 'scale(1)';
    initialDistance = 0;
    translateX = 0;
    translateY = 0;
    lastTranslateX = 0;
    lastTranslateY = 0;
    isDragging = false;
    maxTranslateX = 0;
    maxTranslateY = 0;
    topBarHeight = 0;

    ngOnInit(): void {}

    ngAfterViewInit() {
        if (this.zoomContainer) {
            this.setupTouchEvents();
            this.updateMaxTranslateValues();
        } else {
            console.error('zoomContainer is undefined');
        }
    }

    private setupTouchEvents() {
        const element = this.zoomContainer.nativeElement;

        element.addEventListener('touchstart', (event: TouchEvent) => this.onTouchStart(event));
        element.addEventListener('touchmove', (event: TouchEvent) => this.onTouchMove(event));
        element.addEventListener('touchend', () => this.onTouchEnd());
    }

    private onTouchStart(event: TouchEvent): void {
        if (event.touches.length === 2) {
            this.startPinchZoom(event);
        } else if (event.touches.length === 1 && this.scale > 1) {
            this.startDragging(event);
        }
    }

    private startPinchZoom(event: TouchEvent): void {
        this.initialDistance = this.getDistance(event.touches[0], event.touches[1]);
        this.baseScale = this.scale;
        event.preventDefault();
        this.emitZoomedState();
        this.updateMaxTranslateValues();
    }

    private startDragging(event: TouchEvent): void {
        this.isDragging = true;
        this.lastTranslateX = event.touches[0].clientX - this.translateX;
        this.lastTranslateY = event.touches[0].clientY - this.translateY;
    }

    private onTouchMove(event: TouchEvent): void {
        if (event.touches.length === 2) {
            this.handlePinchZoom(event);
        } else if (event.touches.length === 1 && this.isDragging && this.scale > 1) {
            this.handleDragging(event);
        }
    }

    private handlePinchZoom(event: TouchEvent): void {
        const currentDistance = this.getDistance(event.touches[0], event.touches[1]);
        const scaleChange = currentDistance / this.initialDistance;
        this.scale = this.clamp(this.baseScale * scaleChange, 1, 4);
        this.updateMaxTranslateValues();
        this.updateTransform();
        event.preventDefault();
    }

    private handleDragging(event: TouchEvent): void {
        const touch = event.touches[0];
        this.translateX = this.clamp(touch.clientX - this.lastTranslateX, -this.maxTranslateX, this.maxTranslateX);
        this.translateY = this.clamp(touch.clientY - this.lastTranslateY, -this.maxTranslateY, this.maxTranslateY);

        this.updateTransform();
        event.preventDefault();
    }

    private onTouchEnd(): void {
        if (this.scale > 1) {
            this.emitZoomedState();
            this.isDragging = false;
            this.baseScale = this.scale;
        }
    }

    private updateMaxTranslateValues() {
        const containerRect = this.zoomContainer.nativeElement.getBoundingClientRect();
        const imgRect = this.zoomContainer.nativeElement.firstElementChild.getBoundingClientRect();

        this.maxTranslateX = Math.max(0, (imgRect.width - containerRect.width) / 2);
        this.maxTranslateY = Math.max(0, (imgRect.height - containerRect.height) / 2) - this.topBarHeight;

        if (imgRect.width <= containerRect.width) this.maxTranslateX = 0;
        if (imgRect.height <= containerRect.height) this.maxTranslateY = 0 - this.topBarHeight;
    }

    private clamp(value: number, min: number, max: number): number {
        return Math.min(Math.max(value, min), max);
    }

    private updateTransform(): void {
        if (this.scale === 1) {
            this.resetTranslation();
        }
        this.emitZoomedState();
        this.zoomContainer.nativeElement.firstElementChild.style.transform = `translate(${this.translateX}px, ${this.translateY}px) scale(${this.scale})`;
    }

    private resetTranslation(): void {
        this.translateX = 0;
        this.translateY = 0;
    }

    private getDistance(touch1: Touch, touch2: Touch): number {
        const dx = touch2.clientX - touch1.clientX;
        const dy = touch2.clientY - touch1.clientY;
        return Math.sqrt(dx * dx + dy * dy);
    }

    private emitZoomedState(): void {
        this.imageZoomed.emit(this.scale > 1);
    }
}
