import { Component, ViewChild, Input, Output, EventEmitter, AfterViewInit, ElementRef } from '@angular/core';
import { IonSegment } from '@ionic/angular';
import { Swiper } from 'swiper';
import { SwiperContainer } from 'swiper/element';

@Component({
    selector: 'app-segment-slider',
    templateUrl: './segment-slider.component.html',
    styleUrls: ['./segment-slider.component.scss'],
})
export class SegmentSliderComponent implements AfterViewInit {
    private readonly INITIAL_SLIDE = 0;

    @Input() headerData;
    @Input() customTheme: boolean;
    @Output() slideChanged: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild(IonSegment, { static: false }) segment: IonSegment;
    @ViewChild('swiper', { static: false }) swiperRef: ElementRef<SwiperContainer>;

    slideOpts = {
        initialSlide: this.INITIAL_SLIDE,
        speed: 300,
        threshold: 50,
    };

    @Input() set initialSlide(initSlide: number) {
        if (initSlide !== this.INITIAL_SLIDE) {
            this.slideOpts.initialSlide = initSlide;
            this.slideChanged.emit(this.slideOpts.initialSlide);
        }
    }

    ngAfterViewInit() {
        this.segment.value = String(this.INITIAL_SLIDE);
        const swiperEl = this.swiperRef.nativeElement as SwiperContainer & { swiper: Swiper };
        swiperEl.addEventListener('slidechange', () => this.onSlideChange());
        swiperEl.addEventListener('swiperslidechangetransitionend', () => this.onSlideChange());
    }

    async slideTo(index: number) {
        const swiperEl = this.swiperRef.nativeElement as SwiperContainer & { swiper: Swiper };
        swiperEl.swiper.slideTo(index);
        await this.onSlideChange();
    }

    async changeSliderValue(event: any) {
        const swiperEl = this.swiperRef.nativeElement as SwiperContainer & { swiper: Swiper };
        swiperEl.swiper.slideTo(Number(event.detail.value));
    }

    async onSlideChange() {
        const swiperEl = this.swiperRef.nativeElement as SwiperContainer & { swiper: Swiper };
        const currentSlideIndex = swiperEl.swiper.activeIndex;
        this.segment.value = currentSlideIndex.toString();
        this.slideChanged.emit(currentSlideIndex);
    }
}
