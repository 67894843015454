<ion-header>
    <app-topbar
        #topBar
        [title]="'ticket-pictures.title' | translate"
        [left]="leftIcon"
        (leftClicked)="leftFunction()"
        [right]="'close'"
        (rightClicked)="modalController.dismiss()"></app-topbar>
</ion-header>

<ion-content>
    <ion-content scrollY="false">
        <swiper-container #swiper (afterinit)="swiperReady()" [pagination]="true" [zoom]="false">
            <swiper-slide *ngFor="let image of images">
                <app-zoomable-image [image]="image" (imageZoomed)="toggleSwiper($event)"></app-zoomable-image>
            </swiper-slide>
        </swiper-container>
    </ion-content>
</ion-content>
