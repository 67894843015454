import { Injectable } from '@angular/core';
import { EmailComposer, OpenOptions as EmailOpenOptions } from 'capacitor-email-composer';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { OpenOptions as BrowserOpenOptions } from '@capacitor/browser';

@Injectable({
    providedIn: 'root',
})
export class BrowserService {
    constructor() {}

    callPhone(phoneNumber: string) {
        if (Capacitor.isNativePlatform()) {
            // this.iab.create(`tel:${phoneNumber}`);
        } else {
            window.location.href = `tel:${phoneNumber}`;
        }
    }

    async sendMail(emailAddress: string, subject = '', message = '') {
        if (Capacitor.isNativePlatform()) {
            const emailObj: EmailOpenOptions = {
                to: [emailAddress],
                subject,
                body: message,
                isHtml: false,
            };

            try {
                await EmailComposer.open(emailObj);
            } catch (err) {
                console.error('Error launching dialer', err);
            }
        } else {
            window.location.href = `mailto:${emailAddress}`;
        }
    }

    open(url: string, target = '_system') {
        const openOption: BrowserOpenOptions = {
            url: url,
            windowName: target,
        };
        return Browser.open(openOption);
    }
}
