import { Component, Input, OnChanges } from '@angular/core';
import { DocumentUtilService } from '../../../services/document-util/document-util.service';

@Component({
    selector: 'app-show-attachments',
    templateUrl: './show-attachments.component.html',
    styleUrls: ['./show-attachments.component.scss'],
})
export class ShowAttachmentsComponent implements OnChanges {
    @Input() documents: any;
    @Input() recommended = false;
    @Input() title;

    displayDocuments = [];

    constructor(private documentUtil: DocumentUtilService) {}

    ngOnChanges() {
        const imgs = this.documents?.imgs || [];
        const pdfs = this.documents?.pdfs || [];
        this.displayDocuments = [...imgs, ...pdfs];
    }

    openDoc(doc: any) {
        this.documentUtil.openDocument(doc);
    }
}
