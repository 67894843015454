import { Component, Input, OnInit } from '@angular/core';
import { WoonigWebviewService } from '../../services/woonig-webview/woonig-webview.service';
import { ModalController } from '@ionic/angular';
import { WoonigEmbeddedLinkModel } from '../../models/woonig-embedded-link.model';

@Component({
    selector: 'app-embedded-links',
    templateUrl: './embedded-links.component.html',
    styleUrls: ['./embedded-links.component.scss'],
})
export class EmbeddedLinksComponent implements OnInit {
    @Input() userId;
    @Input() namespaceId;
    embeddedLinks: WoonigEmbeddedLinkModel[];

    constructor(
        private webViewService: WoonigWebviewService,
        private modalCtrl: ModalController
    ) {}

    async ngOnInit() {
        this.embeddedLinks = await this.webViewService.getAllEmbeddedLinks(this.namespaceId);
    }

    async openLink(name: string) {
        await this.webViewService.showWoonigWebview(
            this.userId,
            this.namespaceId,
            this.embeddedLinks.find((e) => e.name === name)
        );
    }

    async close(data?: any) {
        await this.modalCtrl.dismiss(data);
    }
}
