import { TicketsService } from '../../services/tickets/tickets.service';
import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { RatingService } from '../../services/rating/rating.service';
import { PopupService } from '../../services/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseClass } from '../../util/base-class';

@Component({
    selector: 'app-ticket-rating',
    templateUrl: './ticket-rating.component.html',
    styleUrls: ['./ticket-rating.component.scss'],
})
export class TicketRatingComponent extends BaseClass implements OnInit {
    providerRating: any;
    managerRating: any;
    ticketId: string;
    ticketDescription: string;
    isUpdate = false;

    comment = {
        managerRating: null,
        providerRating: null,
    };

    constructor(
        public modalController: ModalController,
        private ratingService: RatingService,
        private popupService: PopupService,
        private translate: TranslateService,
        private ticketService: TicketsService,
        private loadingController: LoadingController
    ) {
        super('TicketRatingComponent');
    }

    async ngOnInit() {
        for (const field of [
            { type: 'string', name: 'comment' },
            { type: 'number', name: 'rating' },
        ]) {
            if (this.managerRating && !this.managerRating[field.name]) {
                this.managerRating[field.name] = field.type === 'number' ? 5 : '';
            }
            if (this.providerRating && !this.providerRating[field.name]) {
                this.providerRating[field.name] = field.type === 'number' ? 5 : '';
            }
        }

        this.ticketDescription = (
            await this.ticketService.getTicketTextsByTicketAndKey({ id: this.ticketId }, 'description')
        ).description;
    }

    async finishRatings() {
        if (this.isUpdate) {
            await this.updateRatings();
        } else {
            await this.createRatings();
        }
    }

    async createRatings() {
        const loading = await this.loadingController.create();
        await loading.present();

        const ratingServiceProvider = {
            ticketId: this.ticketId,
            providerId: this.providerRating.providerId,
            rating: this.providerRating.rating,
            comment: this.comment.providerRating,
            ticketDescription: this.ticketDescription,
        };

        const ratingManager = {
            ticketId: this.ticketId,
            managerId: this.managerRating.managerId,
            rating: this.managerRating.rating,
            comment: this.comment.managerRating,
        };

        try {
            if (ratingServiceProvider.providerId) {
                await this.ratingService.createServiceProviderRating(ratingServiceProvider);
            }
            await this.ratingService.createRatingManager(ratingManager);
            await loading.dismiss();
            await this.modalController.dismiss(true);
        } catch (err) {
            await loading.dismiss();

            await this.popupService.showToast(this.translate.instant('rating.error'), true);

            this.logger.error('createRatings failed', err);
        }
    }

    async updateRatings() {
        const loading = await this.loadingController.create();
        await loading.present();

        try {
            let updatedRating = null;

            if (this.managerRating && this.managerRating.id) {
                updatedRating = await this.ratingService.updateManagerRating(
                    this.managerRating.id,
                    this.managerRating.managerId,
                    this.ticketId,
                    this.managerRating.rating,
                    this.comment.managerRating || this.managerRating.comment
                );
            }
            if (this.providerRating && this.providerRating.id) {
                updatedRating = await this.ratingService.updateServiceProviderRating(
                    this.providerRating.id,
                    this.providerRating.providerId,
                    this.ticketId,
                    this.providerRating.rating,
                    this.comment.providerRating || this.providerRating.comment
                );
            }

            await loading.dismiss();
            await this.modalController.dismiss(updatedRating);
        } catch (err) {
            await loading.dismiss();

            await this.popupService.showToast(this.translate.instant('rating.error'), true);

            this.logger.error('createRatings failed', err);
        }
    }
}
