import { Injectable } from '@angular/core';
import { collection, Firestore, getDocs, query, where } from '@angular/fire/firestore';
import { UserService } from '../user/user.service';
import { ApiService } from '../api/api.service';
import { TicketsService } from '../tickets/tickets.service';
import { convertFirestoreDate } from '../../util/util';

@Injectable({
    providedIn: 'root',
})
export class RatingService {
    constructor(
        private firestore: Firestore,
        private userService: UserService,
        private apiService: ApiService,
        private ticketService: TicketsService
    ) {}

    async createServiceProviderRating(rating: {
        ticketId: string;
        providerId: string;
        rating: number;
        comment: string;
        ticketDescription: string;
    }) {
        return await this.apiService.post(`providers/${rating.providerId}/ratings`, {
            providerId: rating.providerId,
            ticketId: rating.ticketId,
            rating: rating.rating,
            comment: rating.comment && rating.comment.trim() ? rating.comment.trim() : null,
            ticketDescription: rating.ticketDescription || null,
        });
    }

    async createRatingManager(rating: { ticketId: string; managerId: string; rating: number; comment: string }) {
        return await this.apiService.post(`managers/${rating.managerId}/ratings`, {
            ticketId: rating.ticketId,
            managerId: rating.managerId,
            rating: rating.rating,
            comment: rating.comment && rating.comment.trim() ? rating.comment.trim() : null,
        });
    }

    async updateServiceProviderRating(
        id: string,
        providerId: string,
        ticketId: string,
        updatedRating: number,
        updatedComment: any
    ) {
        return await this.apiService.put(`providers/${providerId}/ratings`, {
            id,
            ticketId,
            providerId,
            rating: updatedRating,
            comment: updatedComment && updatedComment.trim() ? updatedComment.trim() : null,
        });
    }

    async updateManagerRating(
        id: string,
        managerId: string,
        ticketId: string,
        updatedRating: number,
        updatedComment: string
    ) {
        return await this.apiService.put(`managers/${managerId}/ratings`, {
            id,
            ticketId,
            rating: updatedRating,
            comment: updatedComment && updatedComment.trim() ? updatedComment.trim() : null,
        });
    }

    async getRatingsByTypeAndTicketId(type: 'manager' | 'provider', ticketId: string) {
        const path = type === 'manager' ? 'ticketRatings' : 'craftratings';

        const collectionRef = collection(this.firestore, `ns/${this.userService.user.ns}/${path}`);
        let q = query(collectionRef, where('ticketId', '==', ticketId));

        if (type === 'provider') {
            q = query(q, where('creator.id', '==', this.userService.user.id));
        }

        const querySnapshot = await getDocs(q);
        const ratings = querySnapshot.docs.map((doc) => doc.data());

        return await Promise.all(
            ratings.map(async (rating) => {
                const keyPrefix = type === 'manager' ? `managerRating` : `providerRating`;

                for (const comment of await this.getTexts(`${keyPrefix}.${rating['id']}.comment`, ticketId)) {
                    comment.key = comment.key.split('.')[2];
                    this.ticketService.applyTicketText(rating, comment);
                }

                convertFirestoreDate(rating);

                return rating;
            })
        );
    }

    private getTexts(key: string, ticketId: string): Promise<any[]> {
        return new Promise((resolve) => {
            const sub = this.ticketService.getTicketTextsObservableByKey(key, ticketId).subscribe((result) => {
                sub.unsubscribe();
                resolve(result || []);
            });
        });
    }
}
