import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-vote-representation-authority',
    templateUrl: './vote-representation-authority.component.html',
    styleUrls: ['./vote-representation-authority.component.scss'],
})
export class VoteRepresentationAuthorityComponent implements OnInit {
    delegationForm: UntypedFormGroup;

    constructor(
        public modalController: ModalController,
        private formBuilder: UntypedFormBuilder
    ) {}

    ngOnInit() {
        this.delegationForm = this.formBuilder.group({
            firstnameOwner: ['', Validators.required],
            lastnameOwner: ['', Validators.required],
            addressOwner: ['', Validators.required],
            nameOfSTWEOwner: ['', Validators.required],
            firstnameAuthorized: ['', Validators.required],
            lastnameAuthorized: ['', Validators.required],
            addressAuthorized: ['', Validators.required],
            assemblyName: ['', Validators.required],
            date: ['', Validators.required],
            comment: [''],
        });
    }

    send() {
        this.modalController.dismiss({ dismissChild: true });
    }
}
