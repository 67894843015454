import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TicketsService } from 'src/app/services/tickets/tickets.service';

@Component({
    selector: 'app-ticket-add-pictures',
    templateUrl: './ticket-add-pictures.component.html',
    styleUrls: ['./ticket-add-pictures.component.scss'],
})
export class TicketAddPicturesComponent {
    updatedImages = [];
    updatedPdfs = [];

    constructor(
        public modalController: ModalController,
        private ticketService: TicketsService
    ) {}

    async onImageSelected(event: any) {
        if (event.imgs?.length || event.pdfs?.length) {
            event = await this.ticketService.prepareDocuments(event);
            const images = event.imgs;
            const pdfs = event.pdfs;
            this.updatedImages = images.map((image: any) => {
                image.original = image.file;
                return image;
            });
            this.updatedPdfs = pdfs.map((pdf: any) => {
                pdf.original = pdf.file;
                return pdf;
            });
        }
    }

    saveImage() {
        this.modalController.dismiss({
            images: this.updatedImages,
            pdfs: this.updatedPdfs,
        });
    }
}
