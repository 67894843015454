<ion-header>
    <app-topbar [title]="'links.my-links' | translate" [left]="'close'" (leftClicked)="close()"></app-topbar>
</ion-header>

<ion-content>
    <ion-list *ngFor="let embeddedLink of embeddedLinks" class="ion-margin-top" mode="ios" lines="full">
        <ion-item button detail="true" (click)="openLink(embeddedLink.name)">
            <ion-label>{{ embeddedLink.name }}</ion-label>
        </ion-item>
    </ion-list>
</ion-content>
