import { ElasticService } from '../elastic/elastic.service';
import { TicketStatus, TicketMainStates } from '../../models/ticket-status.enum';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { BaseClass } from '../../util/base-class';
import { UserService } from '../user/user.service';
import { BehaviorSubject, Observable, combineLatest, of, Subscription } from 'rxjs';
import { Ticket } from '../../models/ticket';
import { TextObjAggregated } from '../../models/text-obj-aggregated';
import { TextService } from '../text/text.service';
import { BaseDataService } from '../../util/base-data-service';
import { TicketNode } from '../../models/ticket-node';
import { TicketTemplate } from '../../models/ticket-template';
import { PropertyService } from '../property/property.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from '../api/api.service';
import { DocumentsRequests } from '../../util/documents-requests';
import { convertFirestoreDate } from 'src/app/util/util';
import { ObjectDisplayFormatPipe } from 'src/app/pipes/object-display-format/object-display-format';
import {
    collection,
    collectionData,
    doc,
    docData,
    documentId,
    Firestore,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    where,
} from '@angular/fire/firestore';
import { FirebaseWrapperService } from '../firebase-wrapper/firebase-wrapper.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class TicketsService extends BaseClass implements BaseDataService {
    private sub: Subscription = new Subscription();
    texts: TextObjAggregated;
    tabs = {
        OPEN: 0,
        IN_WORK: 0,
        FINISHED: 0,
    };
    initialTickets$: BehaviorSubject<Ticket[]> = new BehaviorSubject<Ticket[]>(null);
    acceptedTickets$: BehaviorSubject<Ticket[]> = new BehaviorSubject<Ticket[]>(null);
    offerTickets$: BehaviorSubject<Ticket[]> = new BehaviorSubject<Ticket[]>(null);
    inWorkTickets$: BehaviorSubject<Ticket[]> = new BehaviorSubject<Ticket[]>(null);
    finishedTickets$: BehaviorSubject<Ticket[]> = new BehaviorSubject<Ticket[]>(null);
    declinedTickets$: BehaviorSubject<Ticket[]> = new BehaviorSubject<Ticket[]>(null);
    canceledTickets$: BehaviorSubject<Ticket[]> = new BehaviorSubject<Ticket[]>(null);
    ticketsLoaded$ = new BehaviorSubject(null);
    ticketsCount$: BehaviorSubject<any> = new BehaviorSubject<any>(this.tabs);
    refreshTickets$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    refreshSub: Subscription;
    langSub: Subscription;
    ticketRefreshUnsubscriber = [];

    constructor(
        private userService: UserService,
        private firestore: Firestore,
        private firebaseService: FirebaseWrapperService,
        private textService: TextService,
        private http: HttpClient,
        private propertyService: PropertyService,
        private apiService: ApiService,
        private documentsRequests: DocumentsRequests,
        private elasticService: ElasticService,
        private displayFormat: ObjectDisplayFormatPipe
    ) {
        super('TicketsService');
    }

    initialize() {
        this.initTicketRefresh();
        this.texts = this.textService.texts$.getValue();
        this.loadTickets();
    }

    loadTickets(
        includeFinishedTickets = false,
        from = {
            OPEN: 0,
            IN_WORK: 0,
            FINISHED: 0,
        }
    ) {
        const queryStates = {
            OPEN: {
                INIT: this.initialTickets$,
            },
            IN_WORK: {
                ACCEPTED: this.acceptedTickets$,
                IN_WORK: this.inWorkTickets$,
                OFFERS: this.offerTickets$,
            },
            FINISHED: {
                FINISHED: this.finishedTickets$,
                DECLINED: this.declinedTickets$,
                CANCELED: this.canceledTickets$,
            },
        };
        if (!includeFinishedTickets) {
            delete queryStates['FINISHED'];
        }

        if (this.refreshSub) {
            this.refreshSub.unsubscribe();
        }
        this.refreshSub = this.refreshTickets$.subscribe(async () => {
            const observables = await Promise.all(
                Object.keys(queryStates).map((state) => this.getObservableQueryOnTickets(state, from[state]))
            );
            if (this.sub) {
                this.sub.unsubscribe();
            }
            this.sub = combineLatest(observables).subscribe(async (tickets) => {
                if (this.langSub) {
                    this.langSub.unsubscribe();
                }
                const states = Object.keys(queryStates);

                for (const index in states) {
                    const relevantStates = Object.keys(queryStates[states[index]]);

                    for (const ticket of tickets[index]) {
                        if (!relevantStates.includes(ticket.status)) {
                            const subs = queryStates[states[index]];

                            for (const subject of Object.keys(subs)) {
                                const behaviorSubject = subs[subject] as BehaviorSubject<Ticket[]>;
                                const filteredSubject = behaviorSubject.value.filter(
                                    (entry: any) => entry.id !== ticket.id
                                );

                                if (filteredSubject.length !== behaviorSubject.value.length) {
                                    behaviorSubject.next(filteredSubject);
                                }
                            }

                            tickets[index].splice(tickets[index].indexOf(ticket), 1);
                        }
                    }
                }

                this.langSub = this.userService.userLanguage$.subscribe(async (lang) => {
                    if (lang) {
                        const ticketBundle = {};

                        for (const state of Object.keys(TicketMainStates)) {
                            ticketBundle[state] = [];
                        }

                        for (const group of tickets) {
                            for (const ticket of group) {
                                ticketBundle[ticket.status].push(ticket);
                            }
                        }

                        const loadMoreTickets = !!(from && (from.OPEN || from.IN_WORK || from.FINISHED));

                        await this.setupTickets(ticketBundle, includeFinishedTickets, loadMoreTickets);
                        this.ticketsLoaded$.next(ticketBundle);
                    }
                });
                await this.countTickets();
            });
        });
    }

    /**
     * Siehe WR10-2891 setTimeout sollte entfernt werden
     */
    initTicketRefresh() {
        const ticketsHashCollection = collection(this.firestore, `ns/${this.userService.getNamespace()}/ticketsHash`);

        const userQuery = query(
            ticketsHashCollection,
            where('uid', '==', this.userService.user.id),
            orderBy('createdOn', 'desc'),
            limit(1)
        );

        this.ticketRefreshUnsubscriber.push(
            onSnapshot(userQuery, () => {
                setTimeout(() => this.refreshTickets$.next(null), 1000);
            })
        );

        this.ticketRefreshUnsubscriber.push(
            ...this.userService.user.properties.map((propertyId) => {
                const propertyQuery = query(
                    ticketsHashCollection,
                    where('propertyId', '==', propertyId),
                    where('flatId', '==', null),
                    orderBy('createdOn', 'desc'),
                    limit(1)
                );

                return onSnapshot(propertyQuery, () => {
                    setTimeout(() => this.refreshTickets$.next(null), 1000);
                });
            })
        );
    }

    async setupTickets(tickets, includeFinishedTickets = false, loadMore = false) {
        const allTickets = [];
        const managerPromises = [];
        const propertyPromises = [];
        const managerCache = [];
        const propertyCache = [];
        for (const status of Object.keys(tickets)) {
            for (const ticket of tickets[status]) {
                allTickets.push(ticket);
                if (!managerCache.includes(ticket.propertyManagerIds[0])) {
                    managerCache.push(ticket.propertyManagerIds[0]);
                    managerPromises.push(this.propertyService.getManager(ticket.propertyManagerIds[0]));
                }
                if (!propertyCache.includes(ticket.propertyId)) {
                    propertyCache.push(ticket.propertyId);
                    propertyPromises.push(this.propertyService.getPropertyById(ticket.propertyId));
                }
            }
        }

        const managerResult = await Promise.all(managerPromises);
        const propertyResult = await Promise.all(propertyPromises);

        for (const status of Object.keys(tickets)) {
            this.addTexts(tickets[status], this.texts);
            for (let ticket of tickets[status]) {
                ticket = allTickets.find((result) => result.id === ticket.id);
                ticket.manager = managerResult.find((result) => result.id === ticket.propertyManagerIds[0]);

                const property = propertyResult.find((result) => result.id === ticket.propertyId);
                if (property) {
                    ticket.property = this.displayFormat.formatProperty(property);
                }

                convertFirestoreDate(ticket);
            }
        }

        this.mergeTickets(this.initialTickets$, tickets.INIT, loadMore);
        this.mergeTickets(this.acceptedTickets$, tickets.ACCEPTED, loadMore);
        this.mergeTickets(this.offerTickets$, tickets.OFFERS, loadMore);
        this.mergeTickets(this.inWorkTickets$, tickets.IN_WORK, loadMore);
        if (includeFinishedTickets) {
            this.mergeTickets(this.finishedTickets$, tickets.FINISHED, loadMore);
            this.mergeTickets(this.declinedTickets$, tickets.DECLINED, loadMore);
            this.mergeTickets(this.canceledTickets$, tickets.CANCELED, loadMore);
        }
    }

    private mergeTickets(oldTickets: BehaviorSubject<any>, newTickets: any[], loadMore = false) {
        const ticketsToDeploy = [];
        const filteredOldTickets = loadMore
            ? oldTickets.value || []
            : (oldTickets.value || []).filter((oldTicket) =>
                  newTickets.some((newTicket) => newTicket.id === oldTicket.id)
              );

        for (const newTicket of [...(newTickets || []), ...(filteredOldTickets || [])]) {
            if (!ticketsToDeploy.some((ticket) => ticket.id === newTicket.id)) {
                ticketsToDeploy.push(newTicket);
            }
        }
        oldTickets.next(ticketsToDeploy);
    }

    getCurrentInfoState(ticket: Ticket): any {
        const statusUser = [];
        for (const state of Object.keys(ticket.statusUser)) {
            const status: any = ticket.statusUser[state];
            status.status = state;
            statusUser.push(status);
        }
        return statusUser
            .filter((state) => state.value)
            .sort((a: any, b: any) => {
                return b.position - a.position;
            })[0].status;
    }

    private async getObservableQueryOnTickets(status: string, from = 0): Promise<Observable<any[]>> {
        const ticketIds = await this.elasticService.getTickets({
            from: from,
            uid: this.userService.user.id,
            status: status,
            propertyIds: this.userService.user.properties.map((propertyId) => propertyId),
            sort: { ticketNo: 'desc' },
        });

        const observables: any[] = ticketIds.map((ticketId) =>
            this.firebaseService.docDataObservable(`ns/${this.userService.getNamespace()}/ticketsHash`, ticketId)
        );

        return observables.length ? combineLatest(observables) : of([]);
    }

    addTexts(tickets: any[], texts: TextObjAggregated = this.texts) {
        for (const ticket of tickets) {
            for (const tag of ticket.hashtags) {
                if (tag.descriptionId) {
                    tag.description = texts[tag.descriptionId];
                }
                if (tag.textId) {
                    tag.text = texts[tag.textId];
                }
            }
        }
    }

    async createTicketRequest(ticket, reCaptchaToken?: string) {
        const generatedTicket: TicketTemplate = this.doGenerateTicket(
            ticket.flatId ? 'flat' : 'property',
            ticket.description,
            ticket.hashtags,
            ticket.appointments,
            ticket.contact,
            ticket.flatId,
            ticket.propertyId,
            ticket.ns,
            ticket.pendingAppointment,
            ticket.contact.email,
            ticket.contact.address,
            ticket.objectType,
            ticket.name,
            ticket.originalLanguage
        );

        const createdRequest: any = await this.http
            .post(
                `${environment.apiBase}requests/contactForm/${this.userService.getNamespace()}`,
                {
                    channel: 'CONTACT',
                    entryDate: new Date(),
                    from: generatedTicket.email,
                    address: generatedTicket.contact.location,
                    hashtags: generatedTicket.hashtags,
                    text: generatedTicket.description,
                    objectType: generatedTicket.objectType,
                    name: generatedTicket.name,
                    originalLanguage: generatedTicket.originalLanguage,
                    property: generatedTicket.propertyId,
                },
                reCaptchaToken
                    ? {
                          headers: new HttpHeaders({
                              'X-reCaptcha': reCaptchaToken,
                          }),
                      }
                    : {}
            )
            .toPromise();

        if (ticket.pictures && ticket.pictures.length) {
            await this.uploadRequestImages(createdRequest.id, this.userService.getNamespace(), ticket.pictures);
        }
    }

    async createTicket(ticket) {
        const generatedTicket: TicketTemplate = this.doGenerateTicket(
            ticket.flatId ? 'flat' : 'property',
            ticket.description,
            ticket.hashtags,
            ticket.appointments,
            ticket.contact,
            ticket.flatId,
            ticket.propertyId,
            ticket.pendingAppointment
        );

        const createdTicket: any = await this.http.post(`${environment.apiBase}tickets`, generatedTicket).toPromise();

        if (ticket.pictures && ticket.pictures.length) {
            await this.uploadTicketImages(createdTicket.id, ticket.pictures);
        }
    }

    async deleteTicketImage(ticketId: string, index: number) {
        return this.apiService.delete(`tickets/${ticketId}/documents/imgs/${index}`);
    }

    private async getFormdataForImages(pictures: any[]) {
        const formData = new FormData();
        await Promise.all(
            pictures.map(async (file) => {
                const fileToAppend = file.original ? file.original : await (await fetch(file.file)).blob();
                formData.append(file.name, fileToAppend, file.name);
            })
        );

        return formData;
    }

    async uploadRequestImages(requestId: string, namespaceId: string, pictures: any[]) {
        const formData = await this.getFormdataForImages(pictures);

        return this.http
            .post(`${environment.apiBase}requests/${requestId}/documents/${namespaceId}`, formData)
            .toPromise();
    }

    async uploadTicketImages(ticketId: string, pictures: any[]) {
        const formData = await this.getFormdataForImages(pictures);

        return this.http.post(`${environment.apiBase}tickets/${ticketId}/documents`, formData).toPromise();
    }

    findSimilarTickets(propertyId: string, text: string, tagIds: string[]): Promise<any> {
        return this.apiService.post('tickets/similar', {
            text,
            propertyId,
            tagIds,
        });
    }

    async prepareTickets(tickets: Ticket[]): Promise<Ticket[]> {
        const ticketsWithOffers = await Promise.all(tickets.map((ticket) => this.setOffers(ticket)));
        return Promise.all(ticketsWithOffers.map((ticket) => this.getTicketTextsByTicketAndKey(ticket)));
    }

    async setOffers(ticket: any) {
        const offers: any = await this.http.get(`${environment.apiBase}offers/${ticket.id}`).toPromise();

        for (const offer of offers) {
            ticket.offers[offer.providerId] = offer;
        }

        return ticket;
    }

    async getTicketTextsByTicketAndKey(ticket: any, key?: string) {
        const texts: any = await this.apiService.get(`tickets/${ticket.id}/texts${key ? '/' + key : ''}`);

        for (const text of texts) {
            if (text) {
                ticket = this.applyTicketText(ticket, text);
            }
        }

        return ticket;
    }

    getTicketObservable(ticketId: string) {
        const collectionRef = collection(this.firestore, `ns/${this.userService.getNamespace()}/ticketsHash`);
        const filteredQuery = query(
            collectionRef,
            where('hideTicketFromTenant', '==', false),
            where(documentId(), '==', ticketId)
        );

        return collectionData(filteredQuery).pipe(
            map((results) => results[0]) // Return the first (and only) document
        );
    }

    getTicketTextsObservableByKey(key: string, ticketId?: string) {
        const collectionRef = collection(this.firestore, `ns/${this.userService.getNamespace()}/ticketTexts`);
        let q = query(collectionRef, where('key', '==', key));
        if (ticketId) {
            q = query(q, where('ticketId', '==', ticketId));
        }
        return collectionData(q);
    }

    public applyTicketText(object: any, text: any, index = 0) {
        const layers = text.key.split('.');

        if (!object) {
            object = {};
        }

        if (index === layers.length) {
            if (text.type === 'original') {
                object.original = {
                    language: text.lang,
                    value: text.value,
                };
                object[text.lang] = text.value;
            } else {
                if (!object[text.lang]) {
                    object[text.lang] = text.value;
                }
            }
        } else {
            object[layers[index]] = this.applyTicketText(object[layers[index]], text, index + 1);
        }

        return object;
    }

    private async getTextsByTicketNote(ticketNote: any) {
        const texts: any[] = await this.apiService.get(
            `tickets/${ticketNote.ticketId}/texts/note.${ticketNote.type}.${ticketNote.id}`
        );

        for (const text of texts) {
            if (text) {
                ticketNote = this.applyTextToTicketNote(ticketNote, text);
            }
        }
        return ticketNote;
    }

    private applyTextToTicketNote(ticketNote: any, text: any) {
        if (!ticketNote) {
            ticketNote = {};
        }

        if (!ticketNote.text || typeof ticketNote.text !== 'object') {
            ticketNote.text = {};
        }

        if (text.type === 'original') {
            ticketNote.text.original = {
                language: text.lang,
                value: text.value,
            };
            ticketNote.text[text.lang] = text.value;
        } else {
            if (!ticketNote.text[text.lang]) {
                ticketNote.text[text.lang] = text.value;
            }
        }

        return ticketNote;
    }

    terminate() {
        const subjects = [
            this.initialTickets$,
            this.acceptedTickets$,
            this.inWorkTickets$,
            this.offerTickets$,
            this.finishedTickets$,
            this.canceledTickets$,
            this.declinedTickets$,
        ];

        for (const subject of subjects) {
            subject.next([]);
        }

        const subscriptions = [this.sub, this.refreshSub, this.langSub];

        for (const subscription of subscriptions) {
            if (subscription) {
                subscription.unsubscribe();
            }
        }

        for (const unsubscriber of this.ticketRefreshUnsubscriber) {
            unsubscriber();
        }

        this.ticketRefreshUnsubscriber = [];
    }

    private doGenerateTicket(
        target: string,
        description: string,
        hashtags: TicketNode[] = null,
        appointments: any = null,
        contact: any,
        flatId: string,
        propertyId: string,
        ns?: string,
        pendingAppointment?: boolean,
        email?: string,
        address?: string,
        objectType?: string,
        name?: string,
        originalLanguage?: string
    ): TicketTemplate {
        const internalTags = [];
        for (const tag of hashtags) {
            internalTags.push({ id: tag.id, textId: tag.textId });
        }

        return {
            target,
            appointments,
            creator: {
                id: this.userService.user?.id,
                type: 'user',
            },
            flatId: flatId || null,
            email: email || null,
            hashtags: internalTags,
            description,
            contact,
            propertyId: propertyId || null,
            ns: this.userService.getNamespace() || null,
            status: TicketStatus.INIT,
            uid: this.userService.user?.id || null,
            pendingAppointment: pendingAppointment || null,
            objectType: objectType || null,
            name: name || null,
            originalLanguage: originalLanguage || null,
            hideTicketFromTenant: false,
        };
    }

    finishTicket(ticketId: string, comment?: string) {
        const update = {
            id: ticketId,
            status: TicketStatus.FINISHED,
            completedComment: comment || null,
        };
        return this.updateTicket(update);
    }

    cancelTicket(ticketId: string, comment: string) {
        const update = {
            id: ticketId,
            status: TicketStatus.CANCELED,
            completedComment: comment || null,
        };

        return this.updateTicket(update);
    }

    updateTicket(update: any): Promise<any> {
        return this.http.put(`${environment.apiBase}tickets/${update.id}`, update).toPromise();
    }

    public async getTicketNotes(ticketId: string, type: string): Promise<any[]> {
        const notes: any[] = await this.apiService.get(`tickets/${ticketId}/notes?type=${type}`);
        const promises = [];

        notes.sort((a, b) => {
            a = new Date(a.createdOn);
            b = new Date(b.createdOn);
            return b - a;
        });

        for (const note of notes) {
            promises.push(this.getTextsByTicketNote(note));
        }

        return (await Promise.all(promises)).filter((note) => note);
    }

    public async addTicketNote(note: any, documents: any) {
        const ticketNote = await this.apiService.post(`tickets/${note.ticketId}/notes`, note);
        if (documents) {
            documents = await this.prepareDocuments(documents);

            if (documents.imgs.length || documents.pdfs.length) {
                await this.documentsRequests.upload(`tickets/${ticketNote.ticketId}/notes`, ticketNote.id, [
                    ...documents.imgs,
                    ...documents.pdfs,
                ]);
            }
        }
    }

    public getTicketNoteObservable(ticketId: string, limitValue?: number): Observable<any[]> {
        const namespace = this.userService.getNamespace();
        const collectionRef = collection(this.firestore, `ns/${namespace}/ticketNotes`);
        let q = query(collectionRef, where('ticketId', '==', ticketId), where('type', '==', 'tenant'));
        if (limitValue) {
            q = query(q, orderBy('createdOn', 'desc'), limit(limitValue));
        }
        return collectionData(q, { idField: 'id' });
    }

    public async countTickets() {
        const states = {
            INIT: 0,
            ACCEPTED: 0,
            IN_WORK: 0,
            OFFERS: 0,
            FINISHED: 0,
            DECLINED: 0,
            CANCELED: 0,
        };

        await Promise.all(
            Object.keys(states).map(async (state) => {
                states[state] = (
                    await Promise.all([
                        ...this.userService.user.flats.map((flatId) => {
                            return this.elasticService.getTicketsCount({
                                'flatId.keyword': flatId,
                                'status.keyword': state,
                                'uid.keyword': this.userService.user.id,
                            });
                        }),
                        ...this.userService.user.properties.map((propertyId) => {
                            return this.elasticService.getTicketsCount({
                                'flatId.keyword': null,
                                'status.keyword': state,
                                'propertyId.keyword': propertyId,
                            });
                        }),
                    ])
                ).reduce((a, b) => a + b);
            })
        );

        this.tabs.OPEN = states.INIT;
        this.tabs.IN_WORK = states.IN_WORK + states.OFFERS + states.ACCEPTED;
        this.tabs.FINISHED = states.FINISHED + states.DECLINED + states.CANCELED;
        this.ticketsCount$.next(this.tabs);
    }

    public async countTicketNotes(ticketId: string) {
        return this.elasticService.searchTicketNotes({
            size: 0,
            filter: {
                'ticketId.keyword': ticketId,
                'type.keyword': 'tenant',
                'creator.type.keyword': 'manager',
            },
        });
    }

    public async hasUnreadNotes(ticketId: string) {
        const result = await this.elasticService.searchTicketNotes({
            size: 0,
            filter: {
                'ticketId.keyword': ticketId,
                'creator.type.keyword': ['provider', 'manager'],
                'type.keyword': 'tenant',
            },
            excludes: {
                'readBy.keyword': this.userService.user.id,
            },
        });

        return !!result.total;
    }

    async getTicket(ticketId: string): Promise<Ticket> {
        return (await this.firebaseService.docData(
            `ns/${this.userService.getNamespace()}/ticketsHash`,
            ticketId
        )) as Ticket;
    }

    async prepareDocuments(documents) {
        const imgs = documents.imgs.map((img) => this.downloadCheckIfFileOrReturnBlob(img));
        const pdfs = documents.pdfs.map((pdf) => this.downloadCheckIfFileOrReturnBlob(pdf));
        return { imgs: await Promise.all(imgs), pdfs: await Promise.all(pdfs) };
    }

    async downloadCheckIfFileOrReturnBlob(file) {
        if (file && typeof file.file === 'string') {
            const blob = await (
                await fetch(file.file, {
                    method: 'GET',
                })
            ).blob();

            return { name: file.name, file: blob };
        }
        return file;
    }

    public getTicketAppointmentsObservable(ticketId: string): Observable<any[]> {
        const namespace = this.userService.getNamespace();
        const appointmentsRef = collection(this.firestore, `ns/${namespace}/appointments`);
        return collectionData(query(appointmentsRef, where('ticketId', '==', ticketId)));
    }

    public async getTicketTextsByKeyAndLanguage(key: string, language: string) {
        const collectionRef = collection(this.firestore, `ns/${this.userService.getNamespace()}/ticketTexts`);
        const q = query(collectionRef, where('key', '==', key), where('lang', '==', language));

        const snapshot = await getDocs(q);
        const texts = snapshot.docs.map((doc) => doc.data());

        return (texts.find((text) => text['type'] === 'original') || texts[0])['value'] || '';
    }

    public async deleteComment(ticketId: string, noteId: any) {
        await this.apiService.delete(`tickets/${ticketId}/notes/${noteId}`);
    }
}
