<ion-card class="accent-border simple-card">
    <ion-card-header>
        <ion-card-subtitle>
            {{ header | translate }}
        </ion-card-subtitle>
        <ion-button size="small" fill="clear" class="button-right">
            <ion-icon slot="icon-only" [icon]="iconName" mode="ios"></ion-icon>
        </ion-button>
    </ion-card-header>
    <ion-card-content>
        <ng-content></ng-content>
    </ion-card-content>
</ion-card>
