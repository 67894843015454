import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'erpFields',
})
export class ErpFieldsPipe implements PipeTransform {
    constructor(private translate: TranslateService) {}

    transform(field: string[]) {
        return field?.length ? field.join(', ') : '-';
    }
}
