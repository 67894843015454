import { Injectable } from '@angular/core';
import { DataService } from '../data/data.service';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../user/user.service';
import { ApiService } from '../api/api.service';
import { SplashScreen } from '@capacitor/splash-screen';

@Injectable({
    providedIn: 'root',
})
export class NamespaceSelectionService {
    constructor(
        private authService: AuthService,
        private dataService: DataService,
        private userService: UserService,
        private apiService: ApiService
    ) {}

    async handleReAuth() {
        const oldTokenResult = await this.userService.firebaseUser.getIdTokenResult();
        const namespaceTokens = await this.authService.authenticate(null, null, oldTokenResult.token);
        const previousSelectedNamespaceId = oldTokenResult?.claims?.ns || null;
        const tokenForSelectedNamespace = namespaceTokens.find(
            (namespaceToken: any) => namespaceToken.namespaceId === previousSelectedNamespaceId
        );
        await this.authService.signIn(tokenForSelectedNamespace, true);
    }

    async initAfterLogin() {
        await this.dataService.initializeEssentialAppData();
        await SplashScreen.hide();
        await this.dataService.initializeAppData();
    }

    async getNamespaceTokens() {
        if (this.userService.firebaseUser) {
            return (await this.apiService.post('users/namespaceTokens')).tokens;
        } else {
            return this.authService.namespaceTokens$.value;
        }
    }
}
