import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserService } from '../services/user/user.service';
@Injectable({
    providedIn: 'root',
})
export class ModuleGuard {
    constructor(
        private userService: UserService,
        private router: Router
    ) {}

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        let permitted = false;
        const fallbackURL = route.data?.fallbackURL || null;
        const availableModules = this.userService?.moduleSubscription?.value || [];

        if (
            !route.hasOwnProperty('data') ||
            !route.data?.hasOwnProperty('requiredModule') ||
            typeof route.data.requiredModule === 'string'
                ? availableModules.includes(route.data.requiredModule)
                : availableModules.some((req) => route.data.requiredModule.includes(req))
        ) {
            permitted = true;
        }

        if (!permitted && fallbackURL) {
            this.router.navigate([fallbackURL]);
        }

        return permitted;
    }
}
