import { DocumentsRequests } from './util/documents-requests';
import { CUSTOM_ELEMENTS_SCHEMA, inject, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MainPageModule } from './pages/main/main.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { ModalsModule } from './modals/modals.module';
import { ApiTranslationLoaderService } from './services/translation/api-translation-loader.service';
import { LoginPageModule } from './pages/login/login.module';
import { StartPageModule } from './pages/start/start.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CreateTicketPageModule } from './pages/create/create-ticket.page.module';
import { MaintenanceComponentModule } from './components/templates/maintenance/maintenance.component.module';
import { TokenInterceptor } from './services/http-interceptor/token-interceptor';
import { ContactFormPageModule } from './pages/contact-form/contact-form.module';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { VoiceRecordModule } from './pages/voice-record/voice-record.module';
import { ForceUpdateModalComponentModule } from './modals/force-update/force-update-modal.component.module';
import { PdfComponentModule } from './modals/pdf-viewer/pdf-viewer.component.module';
import { provideFirebaseApp, initializeApp, FirebaseApp, getApp } from '@angular/fire/app';
import { provideAuth, getAuth, initializeAuth, browserLocalPersistence } from '@angular/fire/auth';
import {
    provideFirestore,
    getFirestore,
    persistentLocalCache,
    persistentSingleTabManager,
} from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { Capacitor } from '@capacitor/core';
import { HtmlReadyAlertComponentModule } from './modals/html-ready-alert/html-ready-alert.component.module';
import { EmbeddedLinksModule } from './modals/embedded-links/embedded-links.module';
export function ApiTranslationLoaderFactory(injector: Injector) {
    return new ApiTranslationLoaderService(injector);
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        IonicModule.forRoot(),
        StartPageModule,
        MainPageModule,
        LoginPageModule,
        ContactFormPageModule,
        VoiceRecordModule,
        CreateTicketPageModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => {
            if (Capacitor.isNativePlatform()) {
                return initializeAuth(getApp(), {
                    persistence: browserLocalPersistence,
                });
            } else {
                return getAuth();
            }
        }),
        provideFirestore(() => {
            const firestore = getFirestore();
            persistentLocalCache({ tabManager: persistentSingleTabManager({}) });
            return firestore;
        }),
        provideStorage(() => getStorage()),
        provideFunctions(() => getFunctions(inject(FirebaseApp), environment.region)),
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: ApiTranslationLoaderFactory,
                deps: [Injector],
            },
        }),
        ModalsModule,
        BrowserModule,
        BrowserAnimationsModule,
        MaintenanceComponentModule,
        RecaptchaV3Module,
        ForceUpdateModalComponentModule,
        HtmlReadyAlertComponentModule,
        EmbeddedLinksModule,
        PdfComponentModule,
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        DocumentsRequests,
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.reCaptcha.webKey,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
