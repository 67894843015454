import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { NamespaceInfo } from '../../models/namespace-info';
import { FirebaseWrapperService } from '../firebase-wrapper/firebase-wrapper.service';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    constructor(
        private userService: UserService,
        private firebaseService: FirebaseWrapperService
    ) {}

    async getElasticConfig() {
        const namespace = (await this.firebaseService.docData('ns', this.userService.getNamespace())) as NamespaceInfo;
        return namespace.elastic;
    }
}
