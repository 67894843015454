import { Component, OnInit, ViewChild } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { OwnershipAssembly } from '../../models/ownershipAssembly';
import { User } from '../../models/user';
import { OwnershipAssemblyService } from '../../services/ownership-assembly/ownership-assembly.service';
import { combineLatest } from 'rxjs';
import { VoteRepresentationAuthorityComponent } from '../vote-representation-authority/vote-representation-authority.component';
import { PropertyManagerService } from '../../services/propertyManager/property-manager.service';
import { Checkpoint } from '../../models/checkpoint';
import { UserService } from '../../services/user/user.service';
import { PopupService } from '../../services/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { BasicCardComponent } from 'src/app/components/molecules/basic-card/basic-card.component';
import { ValidationService } from 'src/app/services/validation/validation.service';
import { FlatService } from 'src/app/services/flat/flat.service';
import { isEqual } from 'lodash-es';

@Component({
    selector: 'app-vote-delegation-modal',
    templateUrl: './vote-delegation-modal.component.html',
    styleUrls: ['./vote-delegation-modal.component.scss'],
})
export class VoteDelegationModalComponent implements OnInit {
    @ViewChild('managerCard') managerCard: BasicCardComponent;
    @ViewChild('ownerCard') ownerCard: BasicCardComponent;
    @ViewChild('customCard') customCard: BasicCardComponent;

    VIEWS = {
        EVOTEINFO: 'evote_info',
        DELEGATION: 'delegation',
        NOTIFYINFO: 'notify_info',
    };

    assembly: OwnershipAssembly;
    showVoteOverwriteInfo: any;
    currentView = this.VIEWS.DELEGATION;
    currentDelegation;
    owner: User[];
    manager: User;
    load;
    checkpoints: Checkpoint[];
    delegatedTo: { id: string; model: string; customData?: any };
    customData = {
        firstname: '',
        lastname: '',
        email: '',
        street: '',
        city: '',
        zip: '',
        region: '',
        country: '',
        files: [],
    };

    coupleDetected = false;
    ownedFlatIds = [];
    loading = false;

    elasticOwners = [];

    constructor(
        public modalController: ModalController,
        private ownershipService: OwnershipAssemblyService,
        private propertyManagerService: PropertyManagerService,
        private loadingController: LoadingController,
        public userService: UserService,
        private popupService: PopupService,
        private translate: TranslateService,
        private validation: ValidationService,
        private flatService: FlatService
    ) {}

    async ngOnInit() {
        if (this.showVoteOverwriteInfo) {
            this.currentView = this.VIEWS.EVOTEINFO;
        }

        if (this.assembly?.delegatedTo) {
            this.delegatedTo = this.assembly.delegatedTo;
            if (this.assembly.delegatedTo.model === 'custom') {
                this.customData = this.assembly.delegatedTo.customData;
            }
        }

        const flats = await this.flatService.getFlatsByIds(this.userService.user.ownedFlats);
        const ownedFlats = flats.filter((flat) => this.assembly.properties.includes(flat.propertyId));
        this.ownedFlatIds = ownedFlats.map((flat) => flat.id).sort();

        await this.showLoader();
        await Promise.all([this.getUser(), this.searchElasticOwners()]);
    }

    async selectUser(id: string, model: string) {
        this.loading = true;
        this.delegatedTo = { id, model };
        if (model === 'owner' && id) {
            const owner = await this.userService.getTenant(id);
            const flats = await this.flatService.getFlatsByIds(owner.ownedFlats);
            const ownedFlats = flats.filter((flat) => this.assembly.properties.includes(flat.propertyId));
            const ownedFlatIds = ownedFlats.map((flat) => flat.id).sort();

            this.coupleDetected = isEqual(this.ownedFlatIds, ownedFlatIds.sort());
        } else {
            this.coupleDetected = false;
        }
        this.loading = false;
    }

    checkMail(mail: string) {
        return this.validation.isValidEmail(mail);
    }

    async onExpand(value: boolean, model: 'manager' | 'owner' | 'custom') {
        if (value) {
            switch (model) {
                case 'manager':
                    if (this.manager?.id === this.assembly?.delegatedTo?.id) {
                        await this.selectUser(this.manager.id, 'manager');
                    } else {
                        await this.selectUser(null, 'manager');
                    }
                    this.ownerCard.expanded = false;
                    this.customCard.expanded = false;
                    break;
                case 'owner':
                    const currentOwner = this.owner.find((owner) => owner.id === this.assembly?.delegatedTo?.id);
                    await this.selectUser(currentOwner?.id || null, 'owner');
                    this.managerCard.expanded = false;
                    this.customCard.expanded = false;
                    break;
                case 'custom':
                    await this.selectUser(null, 'custom');
                    this.managerCard.expanded = false;
                    this.ownerCard.expanded = false;
                    break;
            }
        }
    }

    async close() {
        if (this.currentView === this.VIEWS.NOTIFYINFO) {
            await this.modalController.dismiss(this.delegatedTo);
        } else {
            this.modalController.dismiss(false);
        }
    }

    submitDelegation(delegatedTo: any, view?: string) {
        if (this.delegatedTo.model === 'custom') {
            this.delegatedTo.customData = this.customData;
        }
        this.delegatedTo = delegatedTo;
        if (view) {
            this.currentView = view;
        }
    }

    onImageSelected(event: any) {
        this.customData.files = [...event.pdfs, ...event.imgs];
    }

    async getUser() {
        const owner = await this.ownershipService.getOwnerOfPropertyAssembly(this.assembly.properties, 'owner');
        const manager = this.propertyManagerService.getManagerObservable(this.assembly.leadingManager);
        combineLatest([owner, manager]).subscribe(async ([owners, manager]: any) => {
            this.owner = owners.filter((owner) => owner.id !== this.userService.user.id);
            this.manager = manager;
            await this.dismissLoader();
        });
    }

    async openWebformModal() {
        const modal = await this.modalController.create({
            component: VoteRepresentationAuthorityComponent,
        });
        modal.onDidDismiss().then((result) => {
            if (result.data?.dismissChild) {
                this.modalController.dismiss();
            }
        });
        return await modal.present();
    }

    async showLoader() {
        if (!this.load) {
            this.load = await this.loadingController.create();
            await this.load.present();
        }
    }

    async dismissLoader() {
        await this.load.dismiss();
    }

    async openConfirmation() {
        const result = await this.popupService.showConfirmation(
            this.translate.instant('evote.delegation.revert.confirm'),
            this.translate.instant('evote.delegation.revert.confirm.details')
        );
        if (result) {
            this.modalController.dismiss(null);
        }
    }

    async searchElasticOwners(searchString = '') {
        const owner = await this.ownershipService.getOwnerOfPropertyAssembly(
            this.assembly.properties,
            'owner',
            searchString
        );

        const sub = owner.subscribe(async (owners) => {
            sub?.unsubscribe();
            this.elasticOwners = owners
                .filter((owner) => owner.id !== this.userService.user.id)
                .sort((a, b) => {
                    if (a.lastname < b.lastname) return -1;
                    if (a.lastname > b.lastname) return 1;
                    return 0;
                });
        });
    }

    cancelSearch() {
        this.searchElasticOwners();
    }
}
