import { NamespaceService } from '../../../services/namespace/namespace.service';
import { UserService } from '../../../services/user/user.service';
import { TicketNoteComponent } from '../../../modals/ticket-note/ticket-note.component';
import { TicketsService } from '../../../services/tickets/tickets.service';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { PopupService } from 'src/app/services/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { OffersService } from 'src/app/services/offers/offers.service';

@Component({
    selector: 'app-ticket-notes-card',
    templateUrl: './ticket-notes-card.component.html',
    styleUrls: ['./ticket-notes-card.component.scss'],
})
export class TicketNotesCardComponent implements OnInit {
    @Input() ticketState: string;
    @Input() ticketId: string;
    @Input() open: boolean;
    @Input() managers: string[];
    @Input() ticket: any;

    notes: any[] = [];
    expanded = false;
    loading = false;
    providerIds: string[];
    extendedCommunication = false;
    notesCount: number;
    currentUserId: string;

    constructor(
        private ticketService: TicketsService,
        private modalController: ModalController,
        public userService: UserService,
        private namespaceService: NamespaceService,
        private api: ApiService,
        private popupService: PopupService,
        private translate: TranslateService,
        private offersService: OffersService
    ) {}

    async ngOnInit() {
        const count = await this.ticketService.countTicketNotes(this.ticketId);
        this.notesCount = count.total;

        if (this.open) {
            await this.onExpansion(true);
        }

        const config = await this.namespaceService.getTicketConfiguration();
        this.extendedCommunication = config.extendedCommunication;

        this.providerIds = (await this.offersService.getOffers(this.ticketId)).map((offer) => offer.providerId);
        this.currentUserId = this.userService.user.id;
    }

    async loadTicketNotes() {
        const createdByUserFilter = (note) => note.creator.type === 'user';

        const tenantNotes = await this.ticketService.getTicketNotes(this.ticketId, 'tenant');

        const managerNotes = (await this.ticketService.getTicketNotes(this.ticketId, 'manager')).filter(
            createdByUserFilter
        );

        const providerNotes = (await this.ticketService.getTicketNotes(this.ticketId, 'provider')).filter(
            createdByUserFilter
        );

        this.notes = [...tenantNotes, ...managerNotes, ...providerNotes];

        this.notes.sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime());
    }

    async onExpansion(expanded) {
        this.expanded = expanded;

        if (expanded) {
            this.loading = true;
            await this.loadTicketNotes();

            await Promise.all(
                this.notes.map(async (note) => {
                    if (!note.readBy) {
                        note.readBy = [];
                    }

                    if (!note.readBy.includes(this.userService.user.id)) {
                        note.highlight = true;
                        note.readBy.push(this.userService.user.id);
                    }

                    await this.api.put(`tickets/${note.ticketId}/notes`, note);
                })
            );

            this.loading = false;
        } else {
            this.notes = [];
        }
    }

    hasCommentBeenRead(note: any, managerId?: string, type?: string) {
        const readBy = note.readBy;
        let readUids = [];

        switch (type) {
            case 'manager':
                readUids = [managerId];
                break;

            case 'provider':
                readUids = this.providerIds;
                break;
        }

        return readBy.some((uid) => readUids?.includes(uid));
    }

    async delete(note) {
        const result = await this.popupService.showConfirmation(
            this.translate.instant('ticket-notes.confirm-delete.title'),
            this.translate.instant('ticket-notes.confirm-delete.message')
        );

        if (result) {
            this.loading = true;
            await this.ticketService.deleteComment(note.ticketId, note.id);
            await this.loadTicketNotes();
            this.loading = false;
        }
    }

    async showCreateTicketNoteModal() {
        const ticketModal = await this.modalController.create({
            component: TicketNoteComponent,
            componentProps: {
                note: { type: 'tenant' },
                mode: 'ticket',
                status: this.ticketState,
                ticket: this.ticket,
            },
        });

        await ticketModal.present();

        const { data } = await ticketModal.onWillDismiss();

        if (data?.note) {
            this.loading = true;

            const noteObj = {
                ticketId: this.ticketId,
                type: data.note.type,
                text: data.note.description,
                createdOn: new Date(),
                creator: {
                    type: 'user',
                    id: this.userService.user.id,
                    name: this.userService.getUserFullName(this.userService.user),
                },
            };

            await this.ticketService.addTicketNote(noteObj, data.note.documents);

            await this.loadTicketNotes();
            this.loading = false;
        }
    }

    getDocumentsForViewByIndex(index: number) {
        const docs = [...this.notes[index].documents.pdfs, ...this.notes[index].documents.imgs];
        return docs.length ? docs : [];
    }
}
