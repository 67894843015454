<ion-header>
    <app-topbar
        [title]="'appointments.header' | translate"
        [left]="'close'"
        (leftClicked)="modalController.dismiss()"></app-topbar>
</ion-header>
<ion-content color="light">
    <ion-list lines="full">
        <ion-item *ngFor="let day of days">
            <ion-select
                [value]="getSelectedTime(day)"
                label="{{ day.text | translate }}"
                placeholder="{{ 'ticket-create.appointments.times.selection-headline' | translate }}"
                (ionChange)="onTimeSelected($event.detail.value, day)"
                interface="action-sheet">
                <ion-select-option *ngFor="let time of times" [value]="time">
                    {{ time.text | translate }}
                </ion-select-option>
            </ion-select>
            <ion-button fill="clear" slot="end" aria-label="Clear selection" (click)="clearSelection(day)">
                <ion-icon slot="icon-only" name="close" aria-hidden="true"></ion-icon>
            </ion-button>
        </ion-item>
    </ion-list>
</ion-content>
<ion-footer>
    <ion-toolbar>
        <ion-row>
            <ion-col class="total-center">
                <ion-button (click)="onUseSelection()" [disabled]="selectedAppointments?.length === 0" expand="block">{{
                    'appointments.button-submit' | translate
                }}</ion-button>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col class="total-center">
                <ion-button (click)="onNoAppointments()" expand="block">{{ 'general.cancel' | translate }}</ion-button>
            </ion-col>
        </ion-row>
    </ion-toolbar>
</ion-footer>
