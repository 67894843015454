<ion-header
    ><app-topbar
        [title]="'change-password.title' | translate"
        [left]="'close'"
        (leftClicked)="modalController.dismiss()"></app-topbar
></ion-header>
<ion-content>
    <ion-row class="ion-padding">
        <form class="w-100" [formGroup]="form">
            <ion-item class="ion-no-padding">
                <ion-input
                    label=""
                    formControlName="email"
                    type="email"
                    [placeholder]="'change-password.email' | translate"></ion-input>
            </ion-item>
            <ion-button expand="full" color="primary" [disabled]="email.invalid" (click)="updateUserPassword()">
                {{ 'change-password.submit' | translate }}
            </ion-button>
        </form>
    </ion-row>
</ion-content>
