import { Component, OnInit, OnDestroy } from '@angular/core';
import { TicketsService } from '../../services/tickets/tickets.service';
import { BlackboardService } from '../../services/blackboard/blackboard.service';
import { PopupService } from '../../services/popup/popup.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { combineLatest, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Location } from '@angular/common';
import { UserService } from 'src/app/services/user/user.service';
import { take } from 'rxjs/operators';
import { PushNotificationService } from '../../services/push-notification/push-notification.service';

@Component({
    selector: 'app-main',
    templateUrl: './main.page.html',
    styleUrls: ['./main.page.scss'],
})
export class MainPage implements OnInit, OnDestroy {
    ticketsCount;
    notifications = 0;
    messages = 0;
    subscriptions = [];
    tokenLoginSubscription: Subscription;
    isTokenLogin = false;
    isRestrictedTokenLogin = true;
    token;

    showTicketTab = true;

    constructor(
        private ticketsService: TicketsService,
        private blackboardService: BlackboardService,
        private pushNotificationService: PushNotificationService,
        private popupService: PopupService,
        public notificationsService: NotificationsService,
        private auth: AuthService,
        private location: Location,
        public userService: UserService
    ) {}

    ngOnInit() {
        this.tokenLoginSubscription = combineLatest([this.auth.tokenLogin$, this.auth.restrictedTokenLogin$]).subscribe(
            async ([isTokenLogin, restricted]) => {
                const ticketId = await this.auth.tokenLoginTicketId$.pipe(take(1)).toPromise();
                this.showTicketTab = !!ticketId;
                this.unsubscribeAll();

                this.isTokenLogin = isTokenLogin;
                this.isRestrictedTokenLogin = Boolean(isTokenLogin && restricted);

                if (!this.isTokenLogin) {
                    this.token = null;

                    this.subscriptions.push(
                        this.ticketsService.ticketsCount$.subscribe((count) => {
                            this.ticketsCount = count;
                        })
                    );

                    this.subscriptions.push(
                        this.blackboardService.newMessageCount$.subscribe((count) => {
                            this.messages = count;
                        })
                    );

                    this.subscriptions.push(
                        this.notificationsService.observeUnreadNotifications().subscribe(async (notifications) => {
                            this.notifications = notifications.length;
                            await this.notificationsService.handleNotifications();
                        })
                    );

                    this.subscriptions.push(
                        this.pushNotificationService.messages$.subscribe((message) => {
                            if (message) {
                                this.popupService.showToast(message);
                            }
                        })
                    );
                } else {
                    this.token = this.location.path().split('?accessToken=')[1];
                }
            }
        );
    }

    tabsChanged() {
        const tokenString = '?accessToken=';
        const path = this.location.path();
        if (!path.includes(tokenString) && this.isTokenLogin) {
            this.location.go(`${path}${tokenString}${this.token}`);
        }
    }

    unsubscribeAll() {
        for (const subscription of this.subscriptions) {
            if (subscription) {
                subscription.unsubscribe();
            }
        }

        this.subscriptions = [];
    }

    ngOnDestroy() {
        this.unsubscribeAll();

        if (this.tokenLoginSubscription) {
            this.tokenLoginSubscription.unsubscribe();
        }
    }
}
