import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TicketsService } from '../services/tickets/tickets.service';
import { map } from 'rxjs/operators';
import { Ticket } from '../models/ticket';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class TicketGuard {
    constructor(
        private alertController: AlertController,
        private ticketService: TicketsService,
        private translate: TranslateService
    ) {}

    /**
     * if ticket is marked as hidden from tenant, show alert and don't route
     * @param route
     */
    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        const ticketId = route.paramMap.get('ticketId');
        const ticket = await this.ticketService.getTicket(ticketId);
        if (ticket?.hideTicketFromTenant) {
            await this.presentAlert();
            return false;
        } else {
            return true;
        }
    }

    private async presentAlert() {
        const alert = await this.alertController.create({
            header: this.translate.instant('ticket.detail.no-permission-header'),
            message: this.translate.instant('ticket.detail.no-permission-message'),
            buttons: [this.translate.instant('login.ok')],
        });

        await alert.present();
    }
}
